import { _storeData, _retrieveData } from "../../services/assynsStorage"
import {
  getApi,
  postApi,
  putApi,
  deleteApi
} from "../../services/api/apiFunction"
import toast from "react-hot-toast"
import { json } from "react-router-dom"
import ReactGA from "react-ga4"

export const getCurrentUser = key => async dispatch => {
  console.log(key, "User")
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("user-profile/", null, null, retrievAsync)
  dispatch({ type: "FETCH_USER", payload: resp })
}

export const getAllUsers = key => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("user-profile/search", null, null, retrievAsync)
  dispatch({ type: "FETCH_ALL_USER", payload: resp })
}

export const updateProfile = (user, navigation) => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await putApi("user-profile/", user, null, retrievAsync)
  if (resp.status === 200) {
    dispatch({ type: "FETCH_USER", payload: resp.data })
    toast.success("Update successful")
    ReactGA.event({
      category: "Update_Profile_Category",
      action: "Update_Profile_Action",
      label: "Update_Profile_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  } else {
    resp.name && toast.error(resp.name[0])
    resp.bio && toast.error(resp.bio[0])
    resp.birthdate && toast.error(resp.birthdate[0])
    resp.gender && toast.error(resp.gender[0])
    resp.address && toast.error(resp.address[0])
    resp.zip_code && toast.error(resp.zip_code[0])
    resp.country && toast.error(resp.country[0])
    resp.profile_picture && toast.error(resp.profile_picture[0])
  }
}

export const signIn =
  (credentials, navigation, isRemember) => async dispatch => {
    dispatch({ type: "IS_LOADER" })
    let resp = await postApi("rest-auth/login/", credentials, true)
    if (resp.status === 200) {
      console.log(JSON.stringify(resp.data.key), "signInsignInsignIn")
      _storeData("Token", JSON.stringify(resp.data.key))
      _storeData("isRemember", isRemember)
      let retrievAsync = await _retrieveData("Token")

      let respIsSuperUser = await getApi(
        "admin-panel/user/is_admin/",
        null,
        null,
        retrievAsync
      )
      _storeData("isRole", respIsSuperUser.is_admin ? "admin" : "user")
      dispatch({
        type: "FETCH_IS_SUPER_USER",
        payload: respIsSuperUser.is_admin ? "admin" : "user"
      })
      !respIsSuperUser.is_admin && navigation("/event")
      respIsSuperUser.is_admin && navigation("/users")
      dispatch(getCurrentUser(resp.data.key))
      dispatch(getAllUsers())
      toast.success("Login successful")
      ReactGA.event({
        category: "Login_Category",
        action: "Login_Action",
        label: "Login_Lable", // optional
        // value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr" // optional, beacon/xhr/image
      })
    }
    resp.email?.length && toast.error(resp.email[0])
    resp.password?.length && toast.error(resp.password[0])
    resp.non_field_errors?.length && toast.error(resp.non_field_errors[0])
    dispatch({ type: "IS_LOADER" })
  }

export const signUp = (credentials, navigation) => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  let resp = await postApi("rest-auth/registration/", credentials, true);
  console.log(resp, "User_Token")
  dispatch({ type: "IS_LOADER" })
  // console.log(JSON.stringify(resp.data.key), "User_Token")
  resp.email?.length && toast.error(resp.email[0])
  resp.password?.length && toast.error(resp.password[0])
  _storeData("Token", JSON.stringify(resp.data.key))
  resp.status === 201 && dispatch(getCurrentUser(resp.data.key))
  resp.status === 201 && dispatch(getAllUsers())
  let retrievAsync = await _retrieveData("Token")
  if (resp.status === 201) {
    let respIsSuperUser = await getApi(
      "admin-panel/user/is_admin/",
      null,
      null,
      retrievAsync
    )
    _storeData("isRole", respIsSuperUser.is_admin ? "admin" : "user")
    dispatch({
      type: "FETCH_IS_SUPER_USER",
      payload: respIsSuperUser.is_admin ? "admin" : "user"
    })
    !respIsSuperUser.is_admin && navigation("/event")
    respIsSuperUser.is_admin && navigation("/users")
    ReactGA.event({
      category: "Signup_Category",
      action: "Signup_Action",
      label: "Signup_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  }
}

export const forgotPassword = (credentials, navigation) => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  let resp = await postApi("api/password_reset/", credentials, true)
  resp.status === 200 && navigation("/SixDigit")
  resp.status === 200 && toast.success("Password reset e-mail has been sent.")
  resp.status === 200 &&
    ReactGA.event({
      category: "Forgot_Password_Category",
      action: "Forgot_Password_Action",
      label: "Forgot_Password_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  resp.email?.length && toast.error(resp.email[0])
  dispatch({ type: "IS_LOADER" })
}

export const verifyCode = (credentials, navigation) => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  let resp = await postApi(
    "api/password_reset/validate_token/",
    credentials,
    true
  )
  resp.status === 200 &&
    navigation("/ResetPassword", { state: { token: credentials.token } })
  resp.status === 200 && toast.success("Token has been verified")
  resp.status === 200 &&
    ReactGA.event({
      category: "Verify_Code_Category",
      action: "Verify_Code_Action",
      label: "Verify_Code_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  resp.detail === "Not found." && toast.error("Token didn't match")
  dispatch({ type: "IS_LOADER" })
}

export const resetPassword = (credentials, navigation) => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  let resp = await postApi("api/password_reset/confirm/", credentials, true)
  resp.status === 200 && navigation("/Signin")
  resp.status === 200 && toast.success("Password has been reset")
  resp.status === 200 &&
    ReactGA.event({
      category: "Reset_Password_Category",
      action: "Reset_Password_Action",
      label: "Reset_Password_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  resp.password?.length && toast.error(resp.password[0])
  dispatch({ type: "IS_LOADER" })
}

// export const resetPasswordInsideApp = (credentials, navigation) => async dispatch => {
//   let retrievAsync = await _retrieveData('Token')
//   let resp = await postApi('rest-auth/password/change/', credentials, true, retrievAsync);
//   resp.status === 200 && navigation.navigate('SignIn')
//   resp.status === 200 && Toast.show({ type: 'success', text1: 'New password has been saved.', position: 'bottom' });
//   resp.new_password1?.length && Toast.show({ type: 'error', text1: resp.new_password1[0], position: 'bottom' });
//   resp.new_password2?.length && Toast.show({ type: 'error', text1: resp.new_password2[0], position: 'bottom' });
// };

export const termsAndCondition =
  (credentials, navigation) => async dispatch => {
    let resp = await getApi("modules/terms-and-conditions/", null, true, null)
    dispatch({ type: "FETCH_TERMS_AND_CONDITION", payload: resp })
  }

export const privacyPolicy = (credentials, navigation) => async dispatch => {
  let resp = await getApi("modules/privacy-policy/", null, true, null)
  dispatch({ type: "FETCH_PRIVACY_POLICY", payload: resp })
}

export const getEvents = navigation => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("events/event/", null, null, retrievAsync)
  dispatch({ type: "FETCH_EVENT", payload: resp })
}

export const getInvites = navigation => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("events/invites/", null, null, retrievAsync)
  dispatch({ type: "FETCH_INVITE", payload: resp })
}

export const getCalenderBookedDate = navigation => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("events/calendar/", null, null, retrievAsync)
  dispatch({ type: "FETCH_BOOKED_DATE", payload: resp })
}

export const createEvent =
  (
    picture,
    data,
    navigation,
    surveyAnswear,
    surveyQuestion,
    surveyChoiceType,
    addEvent,
    event
  ) =>
  async dispatch => {
    dispatch({ type: "IS_LOADER" })
    let retrievAsync = await _retrieveData("Token")
    if (!retrievAsync) {
      dispatch(logout(navigation))
      return
    }
    let resp = await postApi("events/event/", data, null, retrievAsync)
    console.log(resp, "resp_create_event")
    if (resp.itinerary_items && resp.itinerary_items.length != 0) {
      for (let index = 0; index < resp.itinerary_items.length; index++) {
        const element = resp.itinerary_items[index]
        element.location?.length &&
          toast.error(
            element.location[0] == "This field may not be blank."
              ? "Itinerary field may not be blank."
              : element.location[0]
          )
      }
    }
    let respImageUpload = await putApi(
      "events/event/" + resp.data.id + "/",
      picture,
      null,
      retrievAsync
    )
    resp.length && toast.error(resp[0])
    resp.status === 201 && dispatch(getEvents())
    resp.status === 201 && toast.success("Event created successful")
    resp.status === 201 && dispatch(getCalenderBookedDate())
    resp.status === 201 &&
      dispatch(
        createQuestion(
          resp.data.id,
          surveyAnswear,
          surveyQuestion,
          surveyChoiceType
        )
      )
    resp.status === 201 &&
      addEvent &&
      event &&
      addEvent(event, resp?.data?.itinerary_items)
    resp.status === 201 && dispatch({ type: "CLEAR_SURVEY" })
    resp.status === 201 && navigation("/event")
    resp.status === 201 &&
      ReactGA.event({
        category: "Create_Event_Category",
        action: "Create_Event_Action",
        label: "Create_Event_Lable", // optional
        // value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr" // optional, beacon/xhr/image
      })
    dispatch({ type: "IS_LOADER" })
  }

export const uploadImage = (picture, navigation, id) => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await putApi(
    "events/event/" + id + "/",
    picture,
    null,
    retrievAsync
  )
  resp.status === 200 && dispatch(getEvents())
  resp.status === 200 && navigation("/event")
  resp.status === 200 && toast.success("Images Uploded")
  resp.length && toast.error(resp[0])
}

export const addMemberInEv = (date, navigation, id) => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await putApi("events/event/" + id + "/", date, null, retrievAsync)
  // console.log(resp, date, 'resp_addMemberInEv')
  resp.status === 200 && dispatch(getEvents())
  resp.status === 200 && navigation("/event")
  resp.status === 200 && toast.success("Members Updated")
  resp.length && toast.error(resp[0])
}

export const updateEvStatus = (date, navigation, id) => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await putApi(
    "events/invites/" + id + "/",
    date,
    null,
    retrievAsync
  )
  resp.status === 200 && toast.success("Status Updated")
  resp.status === 200 && dispatch(getEvents())
  resp.status === 200 && navigation("/event")
  resp.detail && toast.error(resp.detail)
}

export const deleteEvent =
  (navigation, id, deleteGoogleEvents) => async dispatch => {
    let retrievAsync = await _retrieveData("Token")
    let resp = await deleteApi(
      "events/event/" + id + "/",
      null,
      null,
      retrievAsync
    )
    resp.status === 204 &&
      toast.error("The event has been successfully removed.")
    resp.status === 204 && dispatch(getEvents())
    resp.status === 204 && deleteGoogleEvents && deleteGoogleEvents()
    resp.status === 204 && navigation("/event")
  }

export const showError = navigation => async dispatch => {
  dispatch({ type: "IS_ERROR", payload: true })
  setTimeout(() => {
    dispatch({ type: "IS_ERROR", payload: false })
  }, 5000)
}

export const googleSignIn = (res, navigation, isRemember) => async dispatch => {
  _storeData("isRemember", isRemember)
  // let retrievAsync = await _retrieveData('Token')
  let data = {
    access_token: res.access_token
  }
  console.log(data, res, "googleSignIn")
  let resp = await postApi("users/google", data, true)
  console.log(resp, "resp_google_SignIn")
  if (resp.status === 200) {
    _storeData("Token", JSON.stringify(resp.data.key))
    navigation("/event")
    dispatch(getCurrentUser(resp.data.key))
    dispatch(getAllUsers())
    toast.success("Login successful")
  }
  resp.detail?.length && toast.error(resp.detail)
  resp.non_field_errors?.length && toast.error(resp.non_field_errors[0])
}

export const faceBookSignin =
  (accessToken, navigation, isRemember) => async dispatch => {
    _storeData("isRemember", isRemember)
    let retrievAsync = await _retrieveData("Token")
    let data = {
      access_token: accessToken
    }
    let resp = await postApi("users/facebook", data, true, retrievAsync)
    console.log(resp, "resp_facebook_SignIn")
    if (resp.status === 200) {
      _storeData("Token", JSON.stringify(resp.data.key))
      navigation("/event")
      dispatch(getCurrentUser(resp.data.key))
      dispatch(getAllUsers())
      toast.success("Login successful")
    }
    resp.detail?.length && toast.error(resp.detail)
    resp.non_field_errors?.length && toast.error(resp.non_field_errors[0])
  }

export const setChannelForSingleChat = channelId => async dispatch => {
  dispatch({ type: "FETCH_CID", payload: channelId })
}

export const sendMsg = (date, id, pvtEmail) => async dispatch => {
  console.log(date, id, "sendMsg")
  let retrievAsync = await _retrieveData("Token")
  // console.log(retrievAsync, 'retrievAsync')
  let resp = await postApi("api/chat/send-message/", date, true, retrievAsync)
  console.log(resp, "sendMsg_Response")
  dispatch({ type: "CLEAR_CID" })
  dispatch(getChats(id))
}

export const getChats = id => async dispatch => {
  console.log(id, "getChats")
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi(
    "api/chat/channel-messages/" + id + "/ ",
    null,
    true,
    retrievAsync
  )
  console.log(resp, "resp")
  dispatch({ type: "FETCH_CHAT", payload: resp.results })
}

export const getPvtChats = senderEmail => async dispatch => {
  console.log(senderEmail, "senderEmail")
  let retrievAsync = await _retrieveData("Token")
  let channelId = await getApi(
    "api/chat/private-channel/" + senderEmail + "/ ",
    null,
    true,
    retrievAsync
  )
  let resp = await getApi(
    "api/chat/channel-messages/" + channelId.pk + "/ ",
    null,
    true,
    retrievAsync
  )
  dispatch({ type: "FETCH_PVT_ID", payload: channelId.pk })
  dispatch({ type: "FETCH_CHAT", payload: resp.results })
}

export const getInboxItems = () => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  let retrievAsync = await _retrieveData("Token")
  let respSingleInbox = await getApi("api/chat/inbox", null, true, retrievAsync)
  let respGroupInbox = await getApi(
    "api/chat/group-inbox/",
    null,
    true,
    retrievAsync
  )
  dispatch({ type: "FETCH_INBOX_ITEMS_SINGLE", payload: respSingleInbox })
  dispatch({ type: "FETCH_INBOX_ITEMS_GROUP", payload: respGroupInbox })
  dispatch({ type: "IS_LOADER" })
}

// export const getContacts = () => async dispatch => {
//   Contacts.getAll().then(contacts => {
//     let propertyAddInContact = []
//     for (let index = 0; index < contacts.length; index++) {
//       const element = contacts[index];
//       element.selected = false
//       propertyAddInContact.push(element)
//     }
//     dispatch({ type: "FETCH_CONTACTS", payload: propertyAddInContact });
//   });
// };

export const feedBack = data => async dispatch => {
  // dispatch({ type: "IS_LOADER" });
  let retrievAsync = await _retrieveData("Token")
  let resp = await postApi("user-profile/feedback", data, null, retrievAsync)
  resp.status === 201 &&
    toast.success("Your feedback has been successfully submitted")
  resp.status === 201 &&
    ReactGA.event({
      category: "Send_Feedback_Category",
      action: "Send_Feedback_Action",
      label: "Send_Feedback_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  resp.email?.length && toast.error(resp.email[0])
  resp.name?.length && toast.error(resp.name[0])
  resp.message?.length && toast.error(resp.message[0])
  // dispatch({ type: "IS_LOADER" });
}

export const logout = navigation => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  _storeData("isRemember", false)
  toast.success("Successfully logged out.")
  navigation("/")
  dispatch({ type: "IS_LOADER" })
  ReactGA.event({
    category: "Logout_Category",
    action: "Logout_Action",
    label: "Logout_Lable", // optional
    // value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr" // optional, beacon/xhr/image
  })
}

export const deletAccount = navigation => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  _storeData("isRemember", false)
  let retrievAsync = await _retrieveData("Token")
  let resp = await deleteApi("user-profile/delete", null, null, retrievAsync)
  resp.status === 204 && navigation("/")
  resp.status === 204 && toast.success("Successfully deleted account.")
  resp.status === 204 &&
    ReactGA.event({
      category: "Delete_Account_Category",
      action: "Delete_Account_Action",
      label: "Delete_Account_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  dispatch({ type: "IS_LOADER" })
}

// export const setSurveyOption = (answare, question, choice, navigation) => async dispatch => {
//   dispatch({ type: "SET_SURVEY", payload: answare });
//   dispatch({ type: "SET_SURVEY_QUESTION", payload: question });
//   dispatch({ type: "SET_SURVEY_CHOICE", payload: choice });
// };

export const getSurveyQuestion = () => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("surveys/polls/", null, null, retrievAsync)
  dispatch({ type: "FETCH_SURVEY_POLLS", payload: resp })
}

export const getSurveyChoice = () => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("surveys/choices/", null, null, retrievAsync)
  dispatch({ type: "FETCH_SURVEY_CHOICES", payload: resp })
}

export const createQuestion =
  (evId, surveyAnswear, surveyQuestion, surveyChoiceType) => async dispatch => {
    let data = {
      event: evId,
      text: surveyQuestion,
      poll_type:
        surveyChoiceType != "Multiple Choice" ? "Text Poll" : "Choice Poll"
    }
    let retrievAsync = await _retrieveData("Token")
    let resp = await postApi("surveys/polls/", data, null, retrievAsync)
    resp.status === 201 &&
      dispatch(createAnswear(resp.data.id, surveyAnswear, surveyChoiceType))
  }

export const createAnswear =
  (pollId, surveyAnswear, surveyChoiceType) => async dispatch => {
    let multiAnswear = []
    for (let index = 0; index < surveyAnswear.length; index++) {
      const element = surveyAnswear[index].choice_text
      multiAnswear.push(element)
    }
    if (surveyChoiceType != "Multiple Choice") {
      let data = {
        poll: pollId,
        choice_text: surveyAnswear[0].choice_text
      }
      let retrievAsync = await _retrieveData("Token")
      let resp = await postApi("surveys/choices/", data, null, retrievAsync)
    } else {
      let data = {
        poll: pollId,
        choice_titles: multiAnswear
      }
      let retrievAsync = await _retrieveData("Token")
      let resp = await postApi(
        "surveys/choices-multiple/",
        data,
        null,
        retrievAsync
      )
    }
    dispatch(getSurveyQuestion())
    dispatch(getSurveyChoice())
  }

export const surveyVotes = (id, navigation) => async dispatch => {
  let data = { choice: id }
  let retrievAsync = await _retrieveData("Token")
  let resp = await postApi("surveys/votes/", data, null, retrievAsync)
  resp.status === 201 && toast.success("Successfully vote submited.")
  resp.status === 201 && dispatch(getSurveyQuestion())
  resp.status === 201 && dispatch(getSurveyChoice())
  resp.status === 201 && navigation("/event")
  resp.status === 201 &&
    ReactGA.event({
      category: "Survey_Vote_Category",
      action: "Survey_Vote_Action",
      label: "Survey_Vote_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  resp.error && toast.error(resp.error)
}

export const updateVote = (id, pollId, navigation) => async dispatch => {
  let data = { choice: id }
  let retrievAsync = await _retrieveData("Token")
  let resp = await putApi(
    "surveys/votes/" + pollId + "/",
    data,
    null,
    retrievAsync,
    "PATCH"
  )
  resp.status === 201 && toast.success("Successfully vote submited.")
  resp.status === 201 && dispatch(getSurveyQuestion())
  resp.status === 201 && dispatch(getSurveyChoice())
  resp.status === 201 &&
    resp.status === 201 &&
    ReactGA.event({
      category: "Survey_Vote_Update_Category",
      action: "Survey_Vote_Update_Action",
      label: "Survey_Vote_Update_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  resp.detail && toast.error(resp.detail)
}

export const getNotification = (credentials, navigation) => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("user-profile/notification", null, null, retrievAsync)
  // console.log(resp, 'resp_notification')
  dispatch({ type: "FETCH_NOTIFICATION", payload: resp })
}

export const addExpense =
  (data, navigation, setshowExpenseModal) => async dispatch => {
    dispatch({ type: "IS_LOADER" })
    let retrievAsync = await _retrieveData("Token")
    let resp = await postApi("events/expense/", data, null, retrievAsync)
    resp.status === 201 && toast.success("Successfully expense added.")
    resp.status === 201 && dispatch(getEvents())
    resp.status === 201 && setshowExpenseModal(false)
    resp.status === 201 &&
      ReactGA.event({
        category: "Add_Expense_Category",
        action: "Add_Expense_Action",
        label: "Add_Expense_Lable", // optional
        // value: 99, // optional, must be a number
        nonInteraction: true, // optional, true/false
        transport: "xhr" // optional, beacon/xhr/image
      })
    dispatch({ type: "IS_LOADER" })
  }

export const getUserListAdmin = (credentials, navigation) => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("admin-panel/user/", null, null, retrievAsync)
  resp.detail && toast.success(resp.detail)
  dispatch({ type: "FETCH_USERLIST_ADMIN", payload: resp })
}

export const delUserFromAdmin = id => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await deleteApi(
    "admin-panel/user/" + id + "/",
    null,
    null,
    retrievAsync
  )
  resp.status === 204 && toast.success("Successfully deleted user.")
  resp.status === 204 && dispatch(getUserListAdmin())
}

export const editUserFromAdmin = (data, id) => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await putApi(
    "admin-panel/user/" + id + "/",
    data,
    null,
    retrievAsync
  )
  console.log(resp, "editUserFromAdmin_resp")
  resp.detail && toast.error(resp.detail)
  resp.email?.length && toast.error(resp.email[0])
  resp.password?.length && toast.error(resp.password[0])
  resp.phone_number?.length && toast.error(resp.phone_number[0])
  resp.status === 200 && toast.success("Successfully update user.")
  resp.status === 200 && dispatch(getUserListAdmin())
}

export const createUserFromAdmin =
  (credentials, navigation, setshowModal) => async dispatch => {
    dispatch({ type: "IS_LOADER" })
    let retrievAsync = await _retrieveData("Token")
    let resp = await postApi(
      "admin-panel/user/",
      credentials,
      null,
      retrievAsync
    )
    console.log(resp, "User_Token")
    dispatch({ type: "IS_LOADER" })
    resp.detail && toast.error(resp.detail)
    resp.email?.length && toast.error(resp.email[0])
    resp.password?.length && toast.error(resp.password[0])
    resp.phone_number?.length && toast.error(resp.phone_number[0])
    resp.status === 201 && dispatch(getUserListAdmin())
    resp.status === 201 && setshowModal(false)
  }

export const getFeedback = () => async dispatch => {
  dispatch({ type: "IS_LOADER" })
  let retrievAsync = await _retrieveData("Token")
  let respFeedback = await getApi(
    "admin-panel/feedback/",
    null,
    null,
    retrievAsync
  )
  dispatch({ type: "FETCH_FEEDBACK", payload: respFeedback })
  dispatch({ type: "IS_LOADER" })
}

export const deleteFeedback = id => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await deleteApi(
    "admin-panel/feedback/" + id + "/",
    null,
    null,
    retrievAsync
  )
  console.log(resp, "deleteFeedback")
  ReactGA.event({
    category: "Admin_Delete_Feedback_Category",
    action: "Admin_Delete_Feedback_Action",
    label: "Admin_Delete_Feedback_Lable", // optional
    // value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr" // optional, beacon/xhr/image
  })
}

export const replyFeedback = (id, data) => async dispatch => {
  console.log(data, "replyFeedback")
  let retrievAsync = await _retrieveData("Token")
  let resp = await putApi(
    "admin-panel/feedback/" + id + "/",
    data,
    null,
    retrievAsync
  )
  console.log(resp, "replyFeedback")
  resp.response_message?.length && toast.error(resp.response_message[0])
  resp.response_subject?.length && toast.error(resp.response_subject[0])
  resp.status === 200 && dispatch(getFeedback())
  resp.status === 200 &&
    ReactGA.event({
      category: "Admin_Reply_Feedback_Category",
      action: "Admin_Reply_Feedback_Action",
      label: "Admin_Reply_Feedback_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
}

export const setSearchKeywords = keyword => async dispatch => {
  // console.log(keyword, 'keyword')
  dispatch({ type: "IS_SEARCH", payload: keyword })
}

export const getFeedbackWithResp = key => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await getApi("user-profile/feedback", null, null, retrievAsync)
  dispatch({ type: "FETCH_FEEDBACK", payload: resp })
  ReactGA.event({
    category: "Feedback_Support_Category",
    action: "Feedback_Support_Action",
    label: "Feedback_Support_Lable", // optional
    // value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr" // optional, beacon/xhr/image
  })
}

export const getAvailabilityDates = async() => {
  let retrievAsync = await _retrieveData("Token")
  const resp = await getApi("events/user-available-dates/", null, null, retrievAsync)
  return resp
} 

export const addAvailability = async (data, setDefaultAvailability) => {
  let retrievAsync = await _retrieveData("Token")
  let resp = await postApi("events/user-available-dates/", data, null, retrievAsync)
  resp.status === 201 &&
    toast.success("Availability dates updated!")
  resp.status === 201 && setDefaultAvailability(data.date)

}

export const getAvailabilityDateCounts = async(dates, userlist) => {
  let retrievAsync = await _retrieveData("Token")
  const resp = await getApi(`events/user-available-dates-count/?user_list=${userlist}&dates=${dates}`, null, null, retrievAsync)
  return resp
}

export const getFriendList = () => async dispatch => {
    // dispatch({ type: "IS_FRIEND_LIST_LOADER" })
    let retrievAsync = await _retrieveData("Token")
    let respFriendList = await getApi(
      `friends/get_added_friend_list/`,
      null,
      null,
      retrievAsync
    )
    dispatch({ type: "FETCH_FRIEND_LIST", payload: respFriendList })
    // dispatch({ type: "IS_FRIEND_LIST_LOADER" })
  }
export const searchFriendList = (query) => async dispatch => {
  let retrievAsync = await _retrieveData("Token")
  let respFriendList = await getApi(
    `friends/get_added_friend_list/?search=${query}`,
    null,
    null,
    retrievAsync
  )
  console.log('searchFriendList', respFriendList);
  dispatch({ type: "FETCH_FRIEND_LIST", payload: respFriendList })
}

  export const getFriendListCount = () => async dispatch => {
    let retrievAsync = await _retrieveData("Token")
    let respFriendListCount = await getApi(
      "friends/get_accepted_friend_count/",
      null,
      null,
      retrievAsync
    )
    dispatch({ type: "FETCH_FRIEND_LIST_COUNT", payload: respFriendListCount })
  }

  export const deleteSpecificFriend = id => async (dispatch) => {
    let retrievAsync = await _retrieveData("Token")
    dispatch({ type: "deleteSpecificFriend_LOADER" })
    let resp = await deleteApi(`friends/remove_friend/?friend_id=${id}`, null, null, retrievAsync)
    console.log('deleteSpecificFriend resp', resp);
    if (resp.status === 204 || resp.status === 200) {
      let respFriendList = await getApi(
        "friends/get_added_friend_list/",
        null,
        null,
        retrievAsync
      )
      dispatch({ type: "FETCH_FRIEND_LIST", payload: respFriendList })
      let respFriendListCount = await getApi(
        "friends/get_accepted_friend_count/",
        null,
        null,
        retrievAsync
      )
      dispatch({ type: "FETCH_FRIEND_LIST_COUNT", payload: respFriendListCount })
      toast.show({
        type: "success",
        text1: "Successfully deleted",
        position: "bottom"
      })
      dispatch({ type: "deleteSpecificFriend_LOADER" })
    }
  }