import { Stack } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";
import Text from "../../components/Text";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import EventCard from "../../components/EventCard";
import { colors } from "../../util/colors";
import {
  getEvents, getInvites,
  getSurveyChoice, getSurveyQuestion,
} from '../../store/actions/action'
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import ReactGA from "react-ga4";
const NoImage = require('./../../assets/NoImage.png')

export const Events = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let eventsGets = useSelector((state) => state.reducer.events);
  let currentUser = useSelector((state) => state.reducer.user);
  const [events, setevents] = useState([]);

  useEffect(() => {
    dispatch(getEvents(navigate))
    dispatch(getInvites(navigate))
    dispatch(getSurveyQuestion(navigate))
    dispatch(getSurveyChoice(navigate))
  }, [])


  useEffect(() => {
    // let soortedEvent = eventsGets.sort((a, b) => moment(b.start).format('x') - moment(a.start).format('x'))
    setevents(eventsGets)
  }, [eventsGets])

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    ReactGA.event({
      category: "Event_Category",
      action: "Event_Action",
      label: "Event_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }, []);

  return (
    <Stack sx={{ backgroundColor: colors.white, marginX: 3 }}>
      <Stack ml={3} alignItems={"flex-start"} marginTop={7}>
        <CustomButton
          backColor=""
          color="inherit"
          component="label"
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={() => navigate("/addEvent")}
        >
          <AddCircleIcon
            fontSize="small"
            sx={{ color: colors.green, marginRight: 1, marginTop: -0.2 }}
          />
          <Text>Add Event</Text>
        </CustomButton>
      </Stack>
      <Stack justifyContent={events.length < 4 ? null : "space-evenly"} direction={"row"} flexWrap="wrap">
        {
          !!events.length && events?.map((key, index) => {
            return (
              <EventCard
                event={key}
                users={key.participants}
                key={index}
                tag="Creator"
                user={currentUser.name != null ? currentUser.name : null}
                avatar={currentUser.profile_picture != null ? currentUser.profile_picture : null}
                location={key.location}
                title={key.title}
                date={key.start}
                img={key.pictures[0] ? key.pictures[0].picture : NoImage}
              />
            )
          })
        }
      </Stack>
    </Stack>
  );
};
