import { Route, Routes } from "react-router-dom";
import { AuthLayout } from "../layout/AuthLayout";
// import { LoginPage } from "../screens/authentication/NewSI";
import { Signup } from "../screens/authentication/Signup";
import { Signin } from "../screens/authentication/Signin";
import { Forget } from "../screens/authentication/ForgetPassword";
import { SixDigit } from "../screens/authentication/6digit";
import { ResetPassword } from "../screens/authentication/ResetPassword";
import { TermsAndCondition } from "../screens/authentication/TermsAndCondition";

export default function AuthRoutes() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {/* <Route path="/" element={<LoginPage />} /> */}
        <Route path="/" element={<Signin />} />
        <Route path="/Signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/Forget" element={<Forget />} />
        <Route path="/SixDigit" element={<SixDigit />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
      </Route>
    </Routes>
  );
}
