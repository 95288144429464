import React from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import MainLayout from "../layout/MainLayout";
import Calculator from "../screens/Calculator";
import Calender from "../screens/Calender";
import Chat from "../screens/Chat";
import { AddEvent } from "../screens/home/AddEvent";
import { Events } from "../screens/home/Events";
import Location from "../screens/home/Location";
import Profile from "../screens/Profile";
import ProfileSingleChat from "../screens/Profile/profileSingleChat";
import Settings from "../screens/settings";
import { Notification } from "../screens/notification/notification";
import UsersList from "../screens/usersAdmin/usersList";
import FeedBack from "../screens/usersAdmin/feedback";
import Availability from "../screens/Availability";

export const MainRoutes = () => {
  let isRole = JSON.parse(localStorage.getItem('isRole'));
  let role = useSelector((state) => state.reducer.role);
  return (
    <Routes>
      <Route element={<MainLayout />}>
        {
          (
            isRole !== 'admin'
          ) ? (
            <>
              <Route path="/event" element={<Events />} />
              <Route path="/location" element={<Location />} />
              <Route path="/addEvent" element={<AddEvent />} />
              <Route path="/calender" element={<Calender />} />
              <Route path="/calculator" element={<Calculator />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profileSingleChat" element={<ProfileSingleChat />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/availability" element={<Availability />} />
            </>
          ) : (
            <>
              <Route path="/users" element={<UsersList />} />
              <Route path="/feedback" element={<FeedBack />} />
            </>
          )
        }
      </Route>
    </Routes>
  );
};
