import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import {
  Avatar,
  AvatarGroup,
  Button,
  IconButton,
  InputAdornment,
  useMediaQuery,
  InputLabel,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  useTheme,
} from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "react-calendar/dist/Calendar.css"
import './addEvent.css'
import Image from "mui-image"
import Calendar from "react-calendar"
import InputField from "../../components/InputField"
import MainContainer from "../../components/MainContainer"
import MemberList from "../../components/MemberList"
import CustomButton from "../../components/CustomButton"
import Text from "../../components/Text"
import CustomCheckbox from "../../components/CustomCheckbox"
import { colors } from "../../util/colors"
import { AddIcon } from "../../assets"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import ClearIcon from "@mui/icons-material/Clear"
import toast from "react-hot-toast"
import moment from "moment"
import momentTimezone from "moment-timezone"
import { createEvent, showError } from "../../store/actions/action"
import ApiCalendar from "react-google-calendar-api"
import CircularProgress from "@mui/material/CircularProgress"
import { gapi, loadAuth2, loadAuth2WithProps } from "gapi-script"
import { loginGoogle } from "../authentication/NewSI"
import prevImage from "../../assets/avatars/prev.jpg"
import nextImage from "../../assets/avatars/next.jpg"
import { getAvailabilityDateCounts } from "../../store/actions/action"

const config = {
  clientId:
    "632076568651-7osjao5dflhur6m9agck7jmtvpgsj0og.apps.googleusercontent.com",
  apiKey: "AIzaSyCYqcFaLiZcBhDQQ7TbATAyg_C16JJ5Qgg",
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const apiCalendar = new ApiCalendar(config)

export const AddEvent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let wgapi = window.gapi
  let data = useSelector(state => state.reducer.allUsers)
  let currentUser = useSelector(state => state.reducer.user)
  let selectedSurvey = useSelector(state => state.reducer.selectedSurvey)
  let surveyQuestion = useSelector(state => state.reducer.surveyQuestion)
  let surveyChoiceType = useSelector(state => state.reducer.surveyChoiceType)
  let isError = useSelector(state => state.reducer.isError)
  let isLoader = useSelector(state => state.reducer.isLoader)

  const [evName, setevName] = useState("")
  const [showCalender, setShowCalender] = useState(false)
  const [evDateStr, setevDateStr] = useState("")
  const [evstartDate, setevstartDate] = useState("")
  const [evendDate, setevendDate] = useState("")
  const [itinerary_items, setitinerary_items] = useState([
    {
      strTime: null,
      endTime: null,
      strTimeFormat: null,
      endTimeFormat: null,
      location: ""
    }
  ])
  const [timeFieldQty, settimeFieldQty] = useState([1])
  const [isFlag, setisFlag] = useState(false)
  const [calendarTime, setCalendarTime] = useState([{ start: "", end: "" }])
  const [images, setImages] = useState([])
  const [imageURLS, setImageURLs] = useState([])
  const [showMemberModal, setShowMemberModal] = useState(false)
  const [allUsers, setallUsers] = useState([])
  const [selectedAllUsers, setselectedAllUsers] = useState([])
  const [selectedMembers, setselectedMembers] = useState([])
  const [location, setlocation] = useState("")
  const [budget, setbudget] = useState("")
  const [evDetails, setevDetails] = useState("")
  const [accomodation, setaccomodation] = useState("")
  const [showSurveyModal, setshowSurveyModal] = useState(false)
  const [googleAuth, setGoogleAuth] = useState(null)
  const [shortAnswear, setshortAnswear] = useState(false)
  const [selectedOption, setselectedOption] = useState("Multiple Choice")
  const [question, setquestion] = useState("")
  const [msg, setmsg] = useState([])
  const [showInputField, setshowInputField] = useState(false)
  const [newOption, setnewOption] = useState("")
  const [dummyOption, setdummyOption] = useState([])
  const [startEndValue, setStartEndValue] = useState('');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState(0);
  const [firstStartDateSelected, setFirstStartDateSelected] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [apiData, setapiData] = useState([]);
  const [selectedParticipentId, setselectedParticipentId] = useState('');

  const label = {
    fontWeight: "bold",
    fontSize: { xs: "12px", md: "18px" },
    color: "black"
  }
  const space = { xs: 1, md: 2 }
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("md"))
  const size = {
    fontSize: { xs: "10.5px", sm: "15px" },
    color: "black"
  }

  //Images
  useEffect(() => {
    initClient()
    if (images.length < 1) return
    const newImageUrls = []
    images.forEach(image => newImageUrls.push(URL.createObjectURL(image)))
    setImageURLs(newImageUrls)
  }, [images])

  function onImageChange(e) {
    setImages([...e.target.files])
  }

  const deleteUrl = url => {
    let a = imageURLS.filter(item => item != url)
    setImageURLs(a)
  }

  //itinerary_items
  const addTimeQty = () => {
    let cloneqty = timeFieldQty.slice(0)
    let cloneItem = itinerary_items.slice(0)
    let filteredEmtpyItem = cloneItem.filter(key => {
      return key.location == ""
    })
    if (filteredEmtpyItem.length == 0) {
      cloneqty.push(1)
      cloneItem.push({
        strTime: new Date(),
        endTime: new Date(),
        location: ""
      })
      setitinerary_items(cloneItem)
      settimeFieldQty(cloneqty)
      setisFlag(!isFlag)
      setCalendarTime([...calendarTime, { start: "", end: "" }])
    }
  }

  const setTime = (key, index, e, point, formatTime) => {
    let cloneItem = itinerary_items.slice(0)
    if (point === "itsStartingPoint") {
      calendarTime[index]["start"] = e
      setCalendarTime([...calendarTime])
      cloneItem[index].strTime = e
      cloneItem[index].strTimeFormat = formatTime
    } else {
      calendarTime[index]["end"] = e
      setCalendarTime([...calendarTime])
      cloneItem[index].endTime = e
      cloneItem[index].endTimeFormat = formatTime
    }
    setitinerary_items(cloneItem)
    setisFlag(!isFlag)
  }

  const setitineraryLocation = (key, index, e) => {
    let cloneItem = itinerary_items.slice(0)
    cloneItem[index].location = e
    setitinerary_items(cloneItem)
    setisFlag(!isFlag)
  }

  const delTimeQty = index => {
    if (index !== 0) {
      let cloneqty = timeFieldQty.slice(0)
      cloneqty.splice(index, 1)
      settimeFieldQty(cloneqty)
      let calendarTimeqty = calendarTime.slice(0)
      calendarTimeqty.splice(index, 1)
      setCalendarTime(calendarTimeqty)

      let cloneItem = itinerary_items.slice(0)
      cloneItem.splice(index, 1)
      setitinerary_items(cloneItem)
      setisFlag(!isFlag)
    }
  }

  //add members
  useEffect(() => {
    makeOptions(data)
  }, [data])

  const makeOptions = data => {
    let K_OPTIONS = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      if (currentUser.id != element.id) {
        element.item = element.name != null ? element.name : element.email
        element.id = element.id
        K_OPTIONS.push(element)
      }
    }
    setallUsers(K_OPTIONS)
  }

  function CustomDayCell({ date, data }) {
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const dayData = data.find(item => item.date == formattedDate);

    let content = ''
    if (dayData) {
      content += ` ${dayData.number_available_users}`;
    }
    return <span className="availableUsers">{content}</span>;
  }

  let selectedParticipantsWithDetails = []

  const addMember = participants => {
    let selectedParticipants = []
    for (let index = 0; index < participants.length; index++) {
      const element = participants[index]
      if (element.isSelected) {
        selectedParticipants.push({ participant: element.email })
        selectedParticipantsWithDetails.push(element)
      }
    }
    setselectedMembers(selectedParticipants)
    setselectedAllUsers(selectedParticipantsWithDetails)
  }

  function getCurrentDateFormat() {
    const date = new Date();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${month}-${year}`;
  }

  const formattedDate = getCurrentDateFormat();

  const [usersLoading, setUsersLoading]=  useState(false)

  async function handleActiveStartDateChange(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const DateFormat = `${month}-${year}`;
    setSelectedMonth(DateFormat);
    if (selectedParticipentId) {
      const apidata = await getAvailabilityDateCounts(DateFormat, selectedParticipentId, setUsersLoading);
      setapiData(apidata);
    } else {
      setapiData([]);
      setSelectedMonth('');
    }
  };

  const defaultDate = new Date(Math.min(...apiData?.map(data => {
    const [year, month, day] = data.date.split('-').map(Number);
    return new Date(year, month - 1, day);
  })));

  async function addUser() {
    const selectMonth = selectedMonth ? selectedMonth : formattedDate;
    const formattedIds = selectedParticipantsWithDetails.map(item => item.id).join(',');
    setselectedParticipentId(formattedIds)
    if (formattedIds === '') {
      setFirstStartDateSelected('');
      setStartDate('')
      setEndDate('');
      setSelectedMonth('');
      setapiData([]);
      setStartEndValue('');
      setShowCalender(false);
    } else {
      const apidata = await getAvailabilityDateCounts(selectMonth, formattedIds,setUsersLoading);
      setapiData(apidata);
    }
  }

  async function createCalendarEvent() {
    console.log("Creating calendar event")
    const event = {
      summary: "eventName",
      description: "eventDescription",
      start: {
        dateTime: "2023-05-28T09:00:00-07:00", // Date.toISOString() ->
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // America/Los_Angeles
      },
      end: {
        dateTime: "2023-05-28T09:00:00-08:00", // Date.toISOString() ->
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // America/Los_Angeles
      }
    }
    await fetch(
      "https://www.googleapis.com/calendar/v3/calendars/primary/events",
      {
        method: "POST",
        headers: {
          // 'Authorization': 'Bearer ' + session.provider_token // Access token for google
        },
        body: JSON.stringify(event)
      }
    )
      .then(data => {
        return data.json()
      })
      .then(data => {
        console.log(data)
        alert("Event created, check your Google Calendar!")
      })
  }

  function initClient(callback) {
    wgapi.load("client:auth2", () => {
      try {
        wgapi.client
          .init({
            apiKey: config.apiKey,
            clientId: config.clientId,
            discoveryDocs: config.discoveryDocs,
            scope: config.scope
          })
          .then(
            function () {
              const res = wgapi.auth2.getAuthInstance().isSignedIn.get()
              console.log("res", res)
            },
            function (error) {
              const res = wgapi?.auth?.getToken()
              setGoogleAuth(res?.access_token)
              console.log("res", res?.access_token, wgapi)
              console.log("errorerror", error)
            }
          )
      } catch (error) {
        console.log("catch", error)
      }
    })
  }

  const addEvent = async event => {
    // const auth = await loginGoogle();
    // console.warn("auth", auth, event, calendarTime);
    if (calendarTime.length > 0) {
      for (let index = 0; index < calendarTime.length; index++) {
        const element = calendarTime[index]
        const obj = {
          ...event,
          start: {
            dateTime: moment(
              moment(event?.start?.dateTime).format("YYYY-MM-DD") +
              " " +
              element?.start
            ).format(),
            timeZone: momentTimezone.tz.guess()
          },
          end: {
            dateTime: moment(
              moment(event?.end?.dateTime).format("YYYY-MM-DD") +
              " " +
              element?.end
            ).format(),
            timeZone: momentTimezone.tz.guess()
          }
        }
        try {
          wgapi.client.load("calendar", "v3", () => {
            var request = wgapi.client.calendar.events.insert({
              calendarId: "primary",
              resource: obj
            })

            request.execute(function (event) {
              console.log("Event created: " + event.htmlLink)
            })
          })
        } catch (error) {
          console.log(error)
        }
      }
    } else {
      try {
        wgapi.client.load("calendar", "v3", () => {
          var request = wgapi.client.calendar.events.insert({
            calendarId: "primary",
            resource: event
          })

          request.execute(function (event) {
            console.log("Event created: " + event.htmlLink)
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  function ParticipantCheck() {
    if (selectedParticipentId === '') {
      toast.error('Please Select Participents First')
    } else if (usersLoading) {
      toast.error('Loading users, please wait...')
    } else {
      setShowCalender(!showCalender)
      setFirstStartDateSelected('')
    }
  }

  const submit = async () => {
    // let gapi = window.gapi;
    // gapi.load("client:auth2", () => {
    //   console.log("loaded client");
    //   gapi.client.init({
    //     clientId:
    //       "632076568651-7osjao5dflhur6m9agck7jmtvpgsj0og.apps.googleusercontent.com",
    //     apiKey: "AIzaSyCYqcFaLiZcBhDQQ7TbATAyg_C16JJ5Qgg",
    //     discoveryDocs: [
    //       "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    //     ],
    //     // discoveryDocs:
    //     //   "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    //     scope: "https://www.googleapis.com/auth/calendar.events",
    //   });
    //   gapi.client.load("calendar", "v3", () => console.log("bam!"));
    //   gapi.auth2
    //     .getAuthInstance()
    //     .signIn()
    //     .then(() => {
    //       console.log("signIn!");
    //       var event = {
    //         summary: "Niivana Event",
    //         location: "800 Howard St., San Francisco, CA 94103",
    //         description: "test",
    //         start: {
    //           dateTime: "2023-05-28T09:00:00-07:00",
    //           timeZone: "America/Los_Angeles",
    //         },
    //         end: {
    //           dateTime: "2023-05-28T09:00:00-08:00",
    //           timeZone: "America/Los_Angeles",
    //         },
    //       };
    //       console.log("EVENT");
    //       console.log(event);
    //       var request = gapi.client.calendar.events.insert({
    //         calendarId: "primary",
    //         conferenceDataVersion: 1,
    //         resource: event,
    //       });
    //       request.execute((event) => {
    //         console.log(event);
    //         window.open(event.htmlLink);
    //       });
    //     });
    // });

    if (
      evName != "" &&
      evDetails != "" &&
      location != "" &&
      evstartDate != "" &&
      evendDate != "" &&
      accomodation != "" &&
      budget != ""
    ) {
      let itinerary_itemsMakeFormat = []
      for (let index = 0; index < itinerary_items.length; index++) {
        const element = itinerary_items[index]
        let obj = {
          start: moment(
            moment(evstartDate).format("YYYY-MM-DD") + " " + element.strTime
          ).format("YYYY-MM-DDTHH:mm"),
          end: moment(
            moment(evendDate).format("YYYY-MM-DD") + " " + element.endTime
          ).format("YYYY-MM-DDTHH:mm"),
          location: element.location
        }
        itinerary_itemsMakeFormat.push(obj)
      }

      let data = {
        title: evName,
        additional_info: evDetails,
        location: location,
        start: moment(evstartDate).format("YYYY-MM-DDThh:mm"),
        end: moment(evendDate).format("YYYY-MM-DDThh:mm"),
        accommodation: accomodation,
        budget: budget,
        itinerary_items: itinerary_itemsMakeFormat,
        participants: selectedMembers
      }

      var event = {
        summary: evName,
        location: location,
        description: evDetails,
        start: {
          dateTime: moment(evstartDate).format(),
          timeZone: momentTimezone.tz.guess()
        },
        end: {
          dateTime: moment(evendDate).add("5", "minutes").format(),
          timeZone: momentTimezone.tz.guess()
        }
      }
      //for upload image we need to call api again with form data
      let form_data = new FormData()
      form_data.append("title", evName)
      form_data.append("location", location)
      form_data.append("start", moment(evstartDate).format("YYYY-MM-DDThh:mm"))
      form_data.append("end", moment(evendDate).format("YYYY-MM-DDThh:mm"))
      form_data.append("accommodation", accomodation)
      form_data.append("budget", budget)
      if (images.length) {
        for (let index = 0; index < images.length; index++) {
          const element = images[index]
          form_data.append(`new_pictures`, element)
        }
      }
      dispatch(
        createEvent(
          form_data,
          data,
          navigate,
          msg,
          question,
          selectedOption,
          addEvent,
          event
        )
      )
      setevName("")
      setevDateStr("")
      setlocation("")
      setbudget("")
      setevDetails("")
      setaccomodation("")
      setselectedMembers([])
      setselectedAllUsers([])
    } else {
      dispatch(showError())
      accomodation == "" && toast.error("Please type accomodation details")
      evDetails == "" && toast.error("Please type event details")
      budget == "" && toast.error("Please type budget")
      location == "" && toast.error("Please type location name")
      evstartDate || evendDate == "" && toast.error("Please select event date")
      evName == "" && toast.error("Please type event name")
    }
  }
  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    const currentDate = moment(startDate);
    const end = moment(endDate);
    while (currentDate <= end) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, 'days');
    }
    return dates;
  };

  let arr = [];
  if (count % 2 !== 0) {
    arr = [];
  } else {
    arr = generateDateRange(startDate, endDate);
  }
  const tileClassName = ({ date, view }) => {
    const selectDate = moment(date).format("YYYY-MM-DD")?.toString()
    const APIDate = apiData?.map((item) => item?.date)
    if (apiData) {
      if (view === "month" && !(APIDate.includes(selectDate))) {
        return 'hideExtraDates';
      }
    }
    if (apiData) {
      const dateObj = apiData?.find((item) => item?.date === selectDate);
      if (!dateObj) {
        return '';
      }
      const selectColorDate = moment(firstStartDateSelected).format("YYYY-MM-DD")?.toString();

      const percentage = dateObj.percentage;
      if (percentage >= 70 && percentage < 100) {
        if (selectColorDate === dateObj.date && moment(firstStartDateSelected).format('YYYY-MM-DD') > moment(new Date).format('YYYY-MM-DD')) {
          return 'yellowBackgroundColor';
        }
        if (arr.includes(dateObj.date) && moment(firstStartDateSelected).format('YYYY-MM-DD') > moment(new Date).format('YYYY-MM-DD')) {
          return 'yellowBackgroundColor';
        }
        return 'backgroundcolor-yellow';
      } else if (percentage >= 100) {
        if (selectColorDate === dateObj.date && moment(firstStartDateSelected).format('YYYY-MM-DD') > moment(new Date).format('YYYY-MM-DD')) {
          return 'hideBackgroundColor';
        }
        if (arr.includes(dateObj.date) && moment(firstStartDateSelected).format('YYYY-MM-DD') > moment(new Date).format('YYYY-MM-DD')) {
          return 'hideBackgroundColor';
        }
        return 'backgroundcolor-green';
      } else {
        if (selectColorDate === dateObj.date && moment(firstStartDateSelected).format('YYYY-MM-DD') > moment(new Date).format('YYYY-MM-DD')) {
          return 'redBackgroundColor';
        }
        if (arr.includes(dateObj.date) && moment(firstStartDateSelected).format('YYYY-MM-DD') > moment(new Date).format('YYYY-MM-DD')) {
          return 'redBackgroundColor';
        }
        return 'backgroundcolor-red';
      }
    }
    return '';
  };

  function firstClick(date) {
    setFirstStartDateSelected(date)
    if (moment(date).isSameOrBefore(moment(), 'day')) {
      arr = [];
      setStartDate('');
      setEndDate('');
      setStartEndValue('');
    }
  }

  const handleDateChange = (value) => {
    setCount(count + 1)

    if (value.length > 2) {
      value.shift();
    }
    let currentDate = new Date();
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    if (startDate > currentDate && endDate > currentDate) {
      setevstartDate(startDate);
      setevendDate(endDate);
    } else {
      arr = [];
      setStartDate('');
      setEndDate('');
      setStartEndValue('');
      toast.error("Cannot select past dates");
    }
    const day = startDate.getDate();
    const month = startDate.getMonth() + 1;
    const year = startDate.getFullYear();

    const endDay = endDate.getDate();
    const endMonth = endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    if (startDate > currentDate && endDate > currentDate) {
      setStartEndValue(`${day}/${month}/${year} to ${endDay}/${endMonth}/${endYear}`)
      setStartDate(`${year}-${month}-${day}`)
      setEndDate(`${endYear}-${endMonth}-${endDay}`)
    }
    if (moment(firstStartDateSelected).format('YYYY-MM-DD') < moment(endDate).format('YYYY-MM-DD')) {
      setevstartDate(startDate);
      setevendDate(endDate);
    } else {
      arr = [];
      setFirstStartDateSelected('');
      setStartDate('')
      setEndDate('');
      setStartEndValue('');
      toast.error("Please select Future dates");
    }
  };

  const surveyDdownChange = key => {
    setselectedOption(key)
    setshortAnswear(false)
    setshowInputField(false)
    setmsg([])
    setdummyOption([])
    setquestion("")
  }

  const handlerAddOption = key => {
    if (key === "Save" && newOption != "") {
      let cloneOptions = dummyOption.slice(0)
      let makeOption = {
        choice_text: newOption,
        selected: true
      }
      cloneOptions.push(makeOption)
      setdummyOption(cloneOptions)
      setmsg(cloneOptions)
      setnewOption("")
    }
    setshowInputField(false)
  }

  const handler = () => {
    if (question == "" || msg.length == 0) {
      dispatch(showError())
    } else {
      setshowSurveyModal(false)
    }
  }

  const handleItemClick = async name => {
    if (name === "sign-in") {
      const res = await loginGoogle()
      console.log("sign-in", res?.access_token)
      setGoogleAuth(res?.access_token)
      // getAllEvents()
    } else if (name === "sign-out") {
      apiCalendar.handleSignoutClick()
    }
  }

  return (
    <MainContainer
      showBoxTwo={true}
      variant={1}
      Box1={
        <Stack mt={4} ml={{ xs: 3, md: 10 }} paddingBottom={5}>
          <Stack width={{ xs: "90%", md: "70%" }} spacing={2}>
            <Stack spacing={space}>
              <InputLabel style={{ display: "flex", flexDirection: "row" }}>
                <Text sx={label}>Event Name</Text>
                {isError && evName == "" && (
                  <Text style={{ top: 3, color: "red" }}>*</Text>
                )}
              </InputLabel>

              <Stack paddingLeft={2}>
                <InputField
                  sx={{ width: "100%" }}
                  value={evName}
                  placeholder="Event Name"
                  onChange={e => setevName(e.target.value)}
                  size="small"
                />
              </Stack>
            </Stack>
            <Stack spacing={space}>
              <InputLabel sx={label}>Add Participants</InputLabel>
              <Stack
                direction={"row"}
                alignItems="center"
                width="90%"
                flexWrap="wrap"
                spacing={{ xs: 0, sm: 1, md: 0 }}
              >
                <Stack onClick={() => setShowMemberModal(true)}>
                  <IconButton component="label">
                    <Avatar src={AddIcon} />
                  </IconButton>
                </Stack>

                <Stack direction={"row"} alignItems="center" paddingRight={2}>
                  <AvatarGroup max={6}>
                    {selectedAllUsers.map((item, index) => {
                      return <Avatar key={index} src={item.profile_picture} />
                    })}
                  </AvatarGroup>
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={space}>
              <InputLabel style={{ display: "flex", flexDirection: "row" }}>
                <Text sx={label}>Date</Text>
                {isError && evDateStr == "" && (
                  <Text style={{ top: 3, color: "red" }}>*</Text>
                )}
              </InputLabel>
              <Stack paddingLeft={2}>
                <InputField
                  className='datedisabled'
                  value={startEndValue ? startEndValue : 'Select Date'}
                  type={'submit'}
                  onChange={(e) => setStartEndValue(e.target.value)}
                  onClick={ParticipantCheck}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ cursor: 'pointer' }}>

                        <KeyboardArrowDownIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {showCalender &&
                  <div className="calendar-wrapper disableddatecalender">
                    <Calendar
                      className={"muiClender"}
                      selectRange
                      goToRangeStartOnSelect={false}
                      defaultActiveStartDate={defaultDate}
                      onActiveStartDateChange={({ activeStartDate }) => {
                        handleActiveStartDateChange(activeStartDate)
                      }}

                      tileContent={({ date, view }) => <CustomDayCell date={date} data={apiData} />}
                      onChange={handleDateChange}
                      onClickDay={(dd) => {
                        setCount(count + 1)
                        setFirstStartDateSelected(dd)
                        firstClick(dd)
                      }}
                      tileClassName={tileClassName}
                      prevLabel={
                        <div className="lable-container">
                          <div className="custom-navigation-label">
                            <img src={prevImage} alt="Previous" />
                          </div>
                        </div>
                      }
                      nextLabel={
                        <div className="lable-container">
                          <div className="custom-navigation-label">
                            <img src={nextImage} alt="Next" />
                          </div>
                        </div>
                      }
                      prev2Label=""
                      next2Label=""
                    />
                  </div>}
              </Stack>
            </Stack>

            <Stack spacing={space}>
              <Stack direction={"row"} justifyContent="space-between">
                <InputLabel sx={label}>Event Time</InputLabel>
                {itinerary_items[0]?.strTime &&
                  itinerary_items[0]?.endTime &&
                  itinerary_items[0]?.location != "" && (
                    <Button
                      sx={{
                        color: colors.green,
                        textTransform: "none"
                      }}
                      component="label"
                    >
                      <Stack
                        direction={"row"}
                        onClick={() => {
                          addTimeQty()
                        }}
                      >
                        <AddCircleOutlineOutlinedIcon
                          sx={{ fontSize: "15px", marginRight: 1 }}
                        />
                        <Text fontWeight="bold" fontSize="12px">
                          Time
                        </Text>
                      </Stack>
                    </Button>
                  )}
              </Stack>

              {timeFieldQty.map((key, index) => {
                return (
                  <Stack key={index}>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      spacing={{ xs: 0, sm: 2 }}
                      paddingLeft={2}
                    >
                      <InputField
                        value={itinerary_items[index]?.strTime}
                        sx={{ width: "100%" }}
                        type="time"
                        size="small"
                        // onChange={(e) => setStartTime(e.target.value)}
                        onChange={e =>
                          setTime(
                            key,
                            index,
                            e.target.value,
                            "itsStartingPoint",
                            e.timeStamp
                          )
                        }
                      />
                      <InputField
                        value={itinerary_items[index]?.endTime}
                        sx={{ width: "100%" }}
                        type="time"
                        size="small"
                        // onChange={(e) => setEndTime(e.target.value)}
                        onChange={e =>
                          setTime(
                            key,
                            index,
                            e.target.value,
                            "itsClosingPoint",
                            e.timeStamp
                          )
                        }
                      />
                      {index != 0 && (
                        <ClearIcon
                          onClick={() => {
                            delTimeQty(index)
                          }}
                          sx={{
                            fontSize: "15px",
                            marginRight: 1,
                            color: "red",
                            cursor: "pointer"
                          }}
                        />
                      )}
                    </Stack>

                    <Stack paddingLeft={2} marginTop={1}>
                      <InputField
                        sx={{ width: "100%" }}
                        value={itinerary_items[index]?.location}
                        placeholder="Itinerary Location"
                        // onChange={(e) => setName(e.target.value)}
                        onChange={e =>
                          setitineraryLocation(key, index, e.target.value)
                        }
                        size="small"
                      />
                    </Stack>
                  </Stack>
                )
              })}
            </Stack>

            <Stack spacing={space}>
              <Stack direction={"row"} justifyContent="space-between">
                <InputLabel sx={label}>Photos</InputLabel>
                <Button
                  sx={{
                    color: colors.green,
                    textTransform: "none"
                  }}
                  component="label"
                >
                  <AddCircleOutlineOutlinedIcon
                    sx={{ fontSize: "15px", marginRight: 1 }}
                  />
                  <Text fontWeight="bold" fontSize="12px">
                    Photo
                  </Text>
                  <input
                    hidden
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={onImageChange}
                  />
                </Button>
              </Stack>
              {imageURLS.length > 0 ? (
                <Stack direction={"row"} flexWrap="wrap">
                  {imageURLS.map((url, index) => (
                    <Stack
                      width={80}
                      height={80}
                      key={index}
                      position="relative"
                      marginRight={1}
                      marginBottom={1}
                    >
                      <Image
                        width={80}
                        height={80}
                        style={{ borderRadius: 5 }}
                        src={url}
                      />
                      <Stack
                        sx={{
                          background: "white",
                          borderRadius: 16,
                          cursor: "pointer"
                        }}
                        position={"absolute"}
                        right={0}
                        onClick={() => deleteUrl(url)}
                        justifyContent="center"
                        alignItems="center"
                        width={20}
                        height={20}
                      >
                        ✖
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              ) : null}

              <Dialog
                open={showMemberModal}
                onClose={() => setShowMemberModal(false)}
                maxWidth={{ xs: "20vw", sm: "30vw" }}
              >
                <DialogContent>
                  <Stack spacing={2}>
                    {allUsers.map((key, index) => {
                      return (
                        <MemberList
                          check={key?.isSelected}
                          onChange={e => {
                            const tempArray = [...allUsers]
                            tempArray[index] = {
                              ...tempArray[index],
                              isSelected: e.target.checked
                            }
                            setallUsers(tempArray)
                          }}
                          index={index}
                          name={key.item}
                          image={key.profile_picture}
                        />
                      )
                    })}
                  </Stack>
                </DialogContent>

                <DialogActions>
                  <CustomButton
                    sx={{ color: "white" }}
                    onClick={() => {
                      setShowMemberModal(false)
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    sx={{ color: "white" }}
                    onClick={() => {
                      addMember(allUsers)
                      addUser()
                      setShowMemberModal(false)
                    }}
                  >
                    Save
                  </CustomButton>
                </DialogActions>
              </Dialog>

              <Stack spacing={space}>
                <InputLabel style={{ display: "flex", flexDirection: "row" }}>
                  <Text sx={label}>Location</Text>
                  {isError && location == "" && (
                    <Text style={{ top: 3, color: "red" }}>*</Text>
                  )}
                </InputLabel>
                <Stack paddingLeft={2}>
                  <InputField
                    value={location}
                    onChange={e => setlocation(e.target.value)}
                    size="small"
                    placeholder={"Location"}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      }
      Box2={
        <Stack mt={{ xs: 0, md: 4 }} ml={{ xs: 3, md: 10 }} paddingBottom={2}>
          <Stack width={{ xs: "90%", md: "60%" }} spacing={space}>
            <Stack spacing={space}>
              <InputLabel style={{ display: "flex", flexDirection: "row" }}>
                <Text sx={label}>Budget</Text>
                {isError && budget == "" && (
                  <Text style={{ top: 3, color: "red" }}>*</Text>
                )}
              </InputLabel>
              <Stack paddingLeft={2}>
                <InputField
                  value={budget}
                  size="small"
                  onChange={e => setbudget(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
              </Stack>
            </Stack>
            <Stack spacing={space}>
              <InputLabel style={{ display: "flex", flexDirection: "row" }}>
                <Text sx={label}>Event Details</Text>
                {isError && evDetails == "" && (
                  <Text style={{ top: 3, color: "red" }}>*</Text>
                )}
              </InputLabel>
              <Stack paddingLeft={2}>
                <InputField
                  value={evDetails}
                  placeholder="Additional info"
                  onChange={e => setevDetails(e.target.value)}
                  multiline
                  rows={3}
                />
              </Stack>
            </Stack>
            <Stack spacing={space}>
              <InputLabel style={{ display: "flex", flexDirection: "row" }}>
                <Text sx={label}>Accomodation</Text>
                {isError && accomodation == "" && (
                  <Text style={{ top: 3, color: "red" }}>*</Text>
                )}
              </InputLabel>
              <Stack paddingLeft={2}>
                <InputField
                  value={accomodation}
                  onChange={e => setaccomodation(e.target.value)}
                  size="small"
                  placeholder={"Accomodation name"}
                />
              </Stack>
            </Stack>

            <Stack spacing={space}>
              <Stack direction={"row"} justifyContent="space-between">
                <InputLabel sx={label}>Survey</InputLabel>
                <Button
                  sx={{
                    color: colors.green,
                    textTransform: "none"
                  }}
                  component="label"
                >
                  <Stack
                    direction={"row"}
                    onClick={() => setshowSurveyModal(true)}
                  >
                    <AddCircleOutlineOutlinedIcon
                      sx={{ fontSize: "15px", marginRight: 1 }}
                    />
                    <Text fontWeight="bold" fontSize="12px">
                      Survey
                    </Text>
                  </Stack>
                </Button>
              </Stack>

              <Dialog
                open={showSurveyModal}
                onClose={() => setshowSurveyModal(false)}
                fullWidth
                maxWidth="sm"
              >
                <DialogContent>
                  <Stack spacing={2}>
                    <Stack spacing={space}>
                      <InputField
                        value={selectedOption}
                        // onChange={(e) => { setselectedOption(e.target.value); setshortAnswear(false); setshowInputField(false); setmsg([]); }}
                        onChange={e => {
                          surveyDdownChange(e.target.value)
                        }}
                        size="small"
                        select
                        defaultValue={selectedOption}
                      >
                        {[
                          {
                            value: "Multiple Choice",
                            label: "Multiple Choice"
                          },
                          { value: "Short Answer", label: "Short Answer" }
                        ].map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </Stack>

                    <Stack>
                      <InputLabel
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Text color="#000000" fontWeight="bold" fontSize="15px">
                          Question
                        </Text>
                        {isError && question == "" && (
                          <Text style={{ top: 3, color: "red" }}>*</Text>
                        )}
                      </InputLabel>
                      <InputField
                        value={question}
                        onChange={e => setquestion(e.target.value)}
                        size="small"
                        placeholder={"Question"}
                      />
                    </Stack>

                    {selectedOption != "Multiple Choice" && (
                      <Stack marginTop={1}>
                        <InputLabel
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <Text
                            color="#000000"
                            fontWeight="bold"
                            fontSize="15px"
                          >
                            Short Answer
                          </Text>
                          {isError && msg.length == 0 && (
                            <Text style={{ top: 3, color: "red" }}>*</Text>
                          )}
                        </InputLabel>
                        <InputField
                          value={msg[0]?.choice_text}
                          placeholder="Short Answer"
                          // onChange={(e) => setevDetails(e.target.value)}
                          onChange={e => {
                            setmsg([
                              { choice_text: e.target.value, selected: true }
                            ])
                          }}
                          multiline
                          rows={8}
                        />
                      </Stack>
                    )}

                    {selectedOption == "Multiple Choice" && (
                      <Stack marginTop={1}>
                        {dummyOption.map((key, index) => {
                          return (
                            <Stack direction={"row"} alignItems={"center"}>
                              <CustomCheckbox
                                size={!small ? "medium" : "small"}
                                checked={key.selected}
                              />
                              <InputLabel sx={size}>
                                {key.choice_text}
                              </InputLabel>
                              {isError && msg.length == 0 && (
                                <Text style={{ top: 3, color: "red" }}>*</Text>
                              )}
                            </Stack>
                          )
                        })}
                      </Stack>
                    )}

                    {showInputField && (
                      <Stack>
                        <InputField
                          value={newOption}
                          onChange={e => setnewOption(e.target.value)}
                          size="small"
                          placeholder={"Type a new option"}
                        />
                      </Stack>
                    )}

                    {selectedOption == "Multiple Choice" && (
                      <Stack>
                        <CustomButton
                          variant="contained"
                          sx={{
                            height: "40px",
                            width: { xs: "100%", sm: "70%", md: "100%" },
                            alignSelf: "center"
                          }}
                          onClick={() => {
                            !showInputField
                              ? setshowInputField(true)
                              : handlerAddOption(
                                !showInputField ? "Add Option" : "Save"
                              )
                          }}
                        >
                          <Text color="white" fontWeight="bold" fontSize="15px">
                            {!showInputField
                              ? "Add Option"
                              : newOption != ""
                                ? "Save"
                                : "Cancel"}
                          </Text>
                          {!showInputField && isError && msg.length == 0 && (
                            <Text style={{ top: 3, color: "red" }}>*</Text>
                          )}
                        </CustomButton>
                      </Stack>
                    )}
                  </Stack>
                </DialogContent>

                <DialogActions>
                  <CustomButton
                    sx={{ color: "white" }}
                    onClick={() => {
                      setshowSurveyModal(false)
                      setmsg([])
                      setdummyOption([])
                      setquestion("")
                      setnewOption("")
                      setselectedOption("Multiple Choice")
                    }}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    sx={{ color: "white" }}
                    onClick={() => {
                      handler()
                      // addMember(allUsers);
                    }}
                  >
                    Save
                  </CustomButton>
                </DialogActions>
              </Dialog>
            </Stack>

            <Stack spacing={space}>
              {question != "" && (
                <InputLabel
                  style={{
                    "white-space": "pre-wrap",
                    "overflow-wrap": "break-word"
                  }}
                >
                  <Text sx={label}>Question:</Text>
                  <Text color="gray">{question}</Text>
                </InputLabel>
              )}
            </Stack>

            <Stack spacing={space}>
              {msg.length != 0 && (
                <InputLabel
                  style={{
                    "white-space": "pre-wrap",
                    "overflow-wrap": "break-word"
                  }}
                >
                  <Text sx={label}>Answers:</Text>
                  {msg.map((key, index) => {
                    return (
                      <Text key={index} color="gray">
                        {key.choice_text}
                      </Text>
                    )
                  })}
                </InputLabel>
              )}
            </Stack>

            <Stack paddingTop={7}>
              {!googleAuth && (
                <Button
                  sx={{
                    color: colors.green,
                    textTransform: "none",
                    mb: 2
                  }}
                  onClick={() => handleItemClick("sign-in")}
                  component="label"
                >
                  <Text fontWeight="bold" fontSize="12px">
                    Sync with Google Calendar
                  </Text>
                </Button>
              )}
              <CustomButton
                variant="contained"
                sx={{
                  height: "60px",
                  width: { xs: "100%", sm: "70%", md: "100%" },
                  alignSelf: "center"
                }}
                // onClick={() => navigate("/location")}
                onClick={() => submit()}
              >
                {isLoader && (
                  <CircularProgress style={{ color: "white" }} size="1rem" />
                )}
                {!isLoader && (
                  <Text color="white" fontWeight="bold" fontSize="15px">
                    Create Event
                  </Text>
                )}
              </CustomButton>
            </Stack>
          </Stack>
        </Stack>
      }
    />
  )
}
