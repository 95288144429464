import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Text from "../../components/Text";
import { colors } from "../../util/colors";
import { getNotification } from '../../store/actions/action'
import moment from "moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const Notification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    let notification = useSelector((state) => state.reducer.notification);
    const [tdnoti, settdnoti] = useState([]);
    const [ernoti, seternoti] = useState([]);

    useEffect(() => {
        dispatch(getNotification())
    }, [])

    useEffect(() => {
        let todayNotification = [];
        let earlierNotification = [];
        for (let index = 0; index < notification.length; index++) {
            const element = notification[index];
            const time = notification[index].timestamp;
            if (moment(time).format('L') === moment().format('L')) {
                todayNotification.push(element)
            }
            else {
                earlierNotification.push(element)
            }
        }
        settdnoti(todayNotification)
        seternoti(earlierNotification)
    }, [notification])

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Stack sx={{ backgroundColor: colors.white, marginX: 3 }}>
            <Stack ml={3} alignItems={"flex-start"} marginTop={7}>

                <Stack>
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => { navigate("/event") }}

                    // sx={{ mr: 2, display: { md: "none" } }}
                    >
                        <ArrowBackIcon sx={{ color: "black", fontWeight: "bold", }} />
                        <Text
                            sx={{
                                fontWeight: "bold",
                                fontSize: { xs: "12px", md: "18px" },
                                color: "black",
                                marginLeft: 10
                            }}
                            style={{ marginLeft: 10 }}
                        >
                            Notification
                        </Text>
                    </IconButton>
                </Stack>

                {
                    tdnoti.length != 0 &&
                    <Stack>
                        <Text
                            sx={{
                                fontWeight: "bold",
                                fontSize: { xs: "12px", md: "18px" },
                                color: "black",
                            }}
                        >
                            Today
                        </Text>
                    </Stack>
                }
                {
                    tdnoti.length != 0 &&
                    tdnoti.map((key, index) => {
                        return (
                            <Stack key={index} marginTop={2} style={{ width: '95%' }} sx={{ borderBottom: "1px solid #F1F0F0", }}>
                                <Stack direction={'row'}>
                                    <Text sx={{ fontWeight: "bold" }}>{key.title}</Text>
                                    <FiberManualRecordIcon name="dot-single" style={{ color: '#000000', fontSize: 8, marginTop: 10, marginLeft: 10, }} />
                                    <Text sx={{ marginLeft: 1, }}>{moment(key.timestamp).format('L')}</Text>
                                </Stack>
                                <Text >{key.sub_title}</Text>
                            </Stack>
                        )
                    })
                }

                {
                    ernoti.length != 0 &&
                    <Stack>
                        <Text
                            sx={{
                                fontWeight: "bold",
                                fontSize: { xs: "12px", md: "18px" },
                                color: "black",
                            }}
                        >
                            Earlier
                        </Text>
                    </Stack>
                }

                {
                    ernoti.length != 0 &&
                    ernoti.map((key, index) => {
                        return (
                            <Stack key={index} marginTop={2} style={{ width: '95%' }} sx={{ borderBottom: "1px solid #F1F0F0", }}>
                                <Stack direction={'row'}>
                                    <Text sx={{ fontWeight: "bold" }}>{key.title}</Text>
                                    <FiberManualRecordIcon name="dot-single" style={{ color: '#000000', fontSize: 8, marginTop: 10, marginLeft: 10, }} />
                                    <Text sx={{ marginLeft: 1, }}>{moment(key.timestamp).format('L')}</Text>
                                </Stack>
                                <Text >{key.sub_title}</Text>
                            </Stack>
                        )
                    })
                }

            </Stack>
        </Stack>
    );
};
