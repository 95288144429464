import { useDispatch, useSelector } from "react-redux"
import {
  Avatar,
  Button,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Card,
  Box
} from "@mui/material"
import LinearProgress from "@mui/joy/LinearProgress"
import "./index.css"
import React, { useEffect, useRef, useState } from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import Text from "../../components/Text"
import MainContainer from "../../components/MainContainer"
import { colors } from "../../util/colors"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import { useNavigate } from "react-router"
import moment from "moment"
import CustomButton from "../../components/CustomButton"
import Image from "mui-image"
import { _weekGet } from "../../services/dateFunctions"
import { makeStyles } from "@mui/styles"
import {
  updateEvStatus,
  addMemberInEv,
  deleteEvent,
  uploadImage,
  surveyVotes,
  updateVote,
  showError
} from "../../store/actions/action"
import MemberList from "../../components/MemberList"
import { BarIcon } from "../../assets"
import CustomCheckbox from "../../components/CustomCheckbox"
import ReactGA from "react-ga4"


const useStyles = makeStyles({
  icon: {
    fill: "white"
  },
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#EBEBEB",
        borderRadius: 5
      },
      "&:hover fieldset": {
        borderColor: "#EBEBEB",
        borderRadius: 5
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
        borderRadius: 5
      }
    }
  }
})

const NoImage = require("../../assets/NoImage1.png")

export default function Calender() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let eventsGets = useSelector(state => state.reducer.events)
  const calendarRef = useRef(null)
  const [events, setevents] = useState([])
  const [selEve, setselEve] = useState(null)
  const [month, setMonth] = useState(new Date())
  console.log(selEve, "selEves")

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    ReactGA.event({
      category: "Calender_Category",
      action: "Calender_Action",
      label: "Calender_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  }, [])

  useEffect(() => {
    return () => {
      setselEve(null)
    }
  }, [])

  const getCalenderApi = () => {
    const { current: calendarDom } = calendarRef
    return calendarDom ? calendarDom.getApi() : null
  }

  useEffect(() => {
    let updatedArr = []
    for (let index = 0; index < eventsGets.length; index++) {
      const element = eventsGets[index]
      let data = {
        title: element.title,
        date: moment(element.start).format("YYYY-MM-DD"),
        display: "background",
        backgroundColor: element.color,
        data: element
      }
      updatedArr.push(data)
    }
    setevents(updatedArr)
  }, [eventsGets])

  function renderEventContent(eventInfo) {
    return (
      <Stack
        style={{ cursor: "pointer" }}
        direction={"column"}
        justifyContent="flex-end"
        width={"100%"}
        height={"100%"}
        // onClick={() => { navigate("/location", { state: { event: eventInfo.event._def.extendedProps.data } }), setselEve(eventInfo.event._def.extendedProps.data) }}
        onClick={() => {
          setselEve(eventInfo.event._def.extendedProps.data)
        }}
      >
        <Text fontSize="12px" color="#Fff" textAlign="center">
          {eventInfo.event._def.title}
        </Text>
      </Stack>
    )
  }

  let currentUser = useSelector(state => state.reducer.user)
  let invites = useSelector(state => state.reducer.invites)
  let data = useSelector(state => state.reducer.allUsers)
  // let week = _weekGet(new Date(selEve.start));
  const [ddValue, setddValue] = useState("")
  const [poll, setpoll] = useState([])
  const [question, setquestion] = useState("")
  const [answear, setAnswear] = useState([])
  const [showSurveyModal, setshowSurveyModal] = useState(false)
  const [update, setupdate] = useState(false)
  const [isFlag, setisFlag] = useState(false)
  let surveyPolls = useSelector(state => state.reducer.surveyPolls)
  let surveyChoices = useSelector(state => state.reducer.surveyChoices)

  useEffect(() => {
    if (selEve) {
      let ddValue = selEve.participants.filter(key => {
        return key.participant == currentUser.email
      })
      setddValue(ddValue[0]?.status)
      let filteredPolls = surveyPolls.filter(key => {
        return key.event == selEve.id
      })
      let filteredAns = surveyChoices.filter(key => {
        return key.poll == filteredPolls[0]?.id
      })
      setpoll(filteredPolls)
      setquestion(filteredPolls[0]?.text)
      setAnswear(filteredAns)
      setisFlag(!isFlag)
    }
  }, [surveyChoices, surveyPolls, selEve])

  const space = { xs: 1, md: 2 }
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("md"))
  const size = {
    fontSize: { xs: "10.5px", sm: "15px" },
    color: "black"
  }
  const classes = useStyles()

  const drop = [
    {
      value: "Pending",
      label: "Pending"
    },
    {
      value: "Going",
      label: "Going"
    },
    {
      value: "Not Going",
      label: "Not Going"
    },
    {
      value: "Maybe",
      label: "Maybe"
    }
  ]

  const [images, setImages] = useState([])
  const [imageURLS, setImageURLs] = useState([])

  // useEffect(() => {
  //   if (images.length < 1) return;
  //   const newImageUrls = [];
  //   images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
  //   setImageURLs(newImageUrls);
  // }, [images]);

  // function onImageChange(e) {
  //   setImages([...e.target.files]);
  // }

  // const deleteUrl = (url) => {
  //   let a = imageURLS.filter((item) => item != url);
  //   setImageURLs(a);
  // };

  const [allUsers, setallUsers] = useState([])

  useEffect(() => {
    makeOptions(data)
  }, [data])

  const makeOptions = data => {
    let K_OPTIONS = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      if (currentUser.email != element.email) {
        element.item = element.name != null ? element.name : element.email
        element.id = element.email
        K_OPTIONS.push(element)
      }
    }
    setallUsers(K_OPTIONS)
  }

  const addMember = participants => {
    let selectedParticipants = []
    for (let index = 0; index < participants.length; index++) {
      const element = participants[index]
      element.isSelected &&
        selectedParticipants.push({ participant: element.email })
    }
    let data = {
      accommodation: selEve.accommodation,
      budget: selEve.budget,
      end: selEve.end,
      location: selEve.location,
      start: selEve.start,
      title: selEve.title,
      participants: selectedParticipants
    }
    dispatch(addMemberInEv(data, navigate, selEve.id))
  }

  useEffect(() => {
    if (selEve?.pictures.length < 1) return
    const newImageUrls = []
    for (let index = 0; index < selEve?.pictures.length; index++) {
      const element = selEve.pictures[index]
      newImageUrls.push(element.picture)
    }
    setImageURLs(newImageUrls)
  }, [selEve?.pictures, selEve])

  function onImageChange(e) {
    addImages([...e.target.files])
  }

  const addImages = images => {
    let form_data = new FormData()
    form_data.append("title", selEve.title)
    form_data.append("location", selEve.location)
    form_data.append("start", moment(selEve.start).format("YYYY-MM-DDThh:mm"))
    form_data.append("end", moment(selEve.start).format("YYYY-MM-DDThh:mm"))
    form_data.append("accommodation", selEve.accommodation)
    form_data.append("budget", selEve.budget)
    if (images.length) {
      for (let index = 0; index < images.length; index++) {
        const element = images[index]
        form_data.append(`new_pictures`, element)
      }
    }
    dispatch(uploadImage(form_data, navigate, selEve.id))
  }

  const [showMemberModal, setShowMemberModal] = useState(false)

  const dropDownHandler = item => {
    let inviteFilter = invites.filter(key => {
      return key.event == selEve.id
    })
    let status = {
      status: item
    }
    dispatch(updateEvStatus(status, navigate, inviteFilter[0].id))
  }

  const handlerCheckBox = (key, selindex) => {
    let updtedArr = []
    for (let index = 0; index < answear.length; index++) {
      const element = answear[index]
      console.log(element, "element")
      if (index === selindex) {
        element.selected = true
      } else {
        element.selected = false
      }
      updtedArr.push(element)
    }
    setAnswear(updtedArr)
  }

  const handler = () => {
    let filteredAns = answear.filter(key => {
      return key.selected == true
    })
    if (filteredAns.length != 0) {
      dispatch(surveyVotes(filteredAns[0].id, navigate))
    } else {
      dispatch(showError())
    }
  }

  const getAllEvents = () => {
    // console.log("apiCalendar.tokenClient", apiCalendar.tokenClient)
    // apiCalendar
    //   .listEvents({
    //     timeMin: new Date().toISOString(),
    //     timeMax: moment().add(10, "days").toISOString(),
    //     showDeleted: true,
    //     maxResults: 10,
    //     orderBy: "updated"
    //   })
    //   .then(({ result }) => {
    //     console.log("result.items", result.items)
    //   })
  }

  return (
    <Stack>
      <MainContainer
        showBoxTwo={true}
        variant={3}
        Box1={
          selEve && (
            <Stack paddingBottom={2}>
              <Stack
                id="back"
                position={"relative"}
                height={250}
                width="100%"
                sx={{
                  // backgroundImage: `url(https://cdn.britannica.com/16/99616-050-72CD201A/Colosseum-Rome.jpg)`,
                  backgroundImage: `url(${
                    selEve.pictures[0] ? selEve.pictures[0].picture : NoImage
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  color: "white"
                }}
              >
                <Text
                  fontSize="25px"
                  fontWeight="bold"
                  marginLeft={2}
                  marginTop={2}
                >
                  {selEve.title}
                </Text>
              </Stack>
              <Stack marginX={2}>
                <Stack marginTop={3}>
                  <Text fontWeight="bold" fontSize="17px">
                    {selEve.title}
                  </Text>
                  <Text>{selEve.additional_info}</Text>
                </Stack>
                {/* <CustomButton
                sx={{
                  color: "white",
                  alignSelf: "start",
                  borderRadius: 16,
                  width: 90,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  marginY: 3,
                }}
              >
                Yes
                <ArrowDropDownIcon />
              </CustomButton> */}

                <Stack>
                  <FormControl
                    sx={{
                      m: 1,
                      width: 130,
                      alignSelf: "start"
                    }}
                    size="small"
                  >
                    <Select
                      onChange={e => {
                        dropDownHandler(e.target.value)
                      }}
                      sx={{
                        borderRadius: 16,
                        background: colors.green,
                        color: "white",
                        paddingLeft: 0.2,
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0
                        }
                      }}
                      inputProps={{
                        classes: {
                          icon: classes.icon
                        }
                      }}
                      value={ddValue}
                    >
                      {drop.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack>
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                    marginX={2}
                  >
                    <Text fontSize="18px" fontWeight="bold">
                      Members
                    </Text>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      spacing={1}
                      sx={{
                        color: colors.green,
                        cursor: "pointer"
                      }}
                      onClick={() => setShowMemberModal(true)}
                    >
                      <AddCircleOutlineIcon fontSize="small" />
                      <Text fontSize="14px" fontWeight="bold">
                        Add Member
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>

                <Dialog
                  open={showMemberModal}
                  onClose={() => setShowMemberModal(false)}
                  // maxWidth={{ xs: "20vw", sm: "30vw" }}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogContent>
                    <Stack spacing={2}>
                      {allUsers.map((key, index) => {
                        return (
                          <MemberList
                            check={key?.isSelected}
                            onChange={e => {
                              const tempArray = [...allUsers]
                              tempArray[index] = {
                                ...tempArray[index],
                                isSelected: e.target.checked
                              }
                              setallUsers(tempArray)
                            }}
                            index={index}
                            name={key.item}
                            image={key?.profile_picture}
                          />
                        )
                      })}
                    </Stack>
                  </DialogContent>

                  <DialogActions>
                    <CustomButton
                      sx={{ color: "white" }}
                      onClick={() => {
                        setShowMemberModal(false)
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      sx={{ color: "white" }}
                      onClick={() => {
                        setShowMemberModal(false)
                        addMember(allUsers)
                      }}
                    >
                      Save
                    </CustomButton>
                  </DialogActions>
                </Dialog>

                <Stack marginTop={2}>
                  <List sx={{ marginLeft: -2, marginRight: -2.1 }}>
                    {selEve.participants.length != 0 &&
                      selEve.participants.map((key, index) => {
                        return (
                          <ListItem
                          // onChange={() => { navigation.navigate('ProfileFromEvent', { participants: key, channel: event.channel }) }}
                          >
                            <ListItemIcon>
                              <Avatar
                                alt="Remy Sharp"
                                src={
                                  key.participant_info?.profile_picture != null
                                    ? key.participant_info?.profile_picture
                                    : null
                                }
                                sx={{
                                  height: { xs: 30, md: 40 },
                                  width: { xs: 30, md: 40 },
                                  marginRight: 2
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText sx={{ flexGrow: 1 }}>
                              <Text fontWeight="bold">
                                {key.participant_info.name != null
                                  ? key.participant_info.name
                                  : key.participant}
                              </Text>
                            </ListItemText>
                            {key.participant == currentUser.email && (
                              <ListItemText>
                                <Stack alignItems={"end"}>
                                  <Text
                                    textAlign="center"
                                    backgroundColor={colors.green}
                                    borderRadius={1}
                                    fontSize="13px"
                                    color={colors.white}
                                    paddingX={1}
                                  >
                                    Creator
                                  </Text>
                                </Stack>
                              </ListItemText>
                            )}
                          </ListItem>
                        )
                      })}
                  </List>
                  {/* <List>
                {names.map((item, index) => (
                  <ListItem>
                    <ListItemIcon>
                      <Avatar
                        alt="Remy Sharp"
                        src={`https://material-ui.com/static/images/avatar/${index + 1
                          }.jpg`}
                        sx={{
                          height: { xs: 30, md: 40 },
                          width: { xs: 30, md: 40 },
                          marginRight: 2,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText sx={{ flexGrow: 1 }}>
                      <Text fontWeight="bold">{item.title}</Text>
                    </ListItemText>
                    {index === 0 && (
                      <ListItemText>
                        <Stack alignItems={"end"}>
                          <Text
                            textAlign="center"
                            backgroundColor={colors.green}
                            borderRadius={1}
                            fontSize="12px"
                            color={colors.white}
                            paddingX={1}
                          >
                            Creator
                          </Text>
                        </Stack>
                      </ListItemText>
                    )}
                  </ListItem>
                ))}
              </List> */}
                  <Stack
                    sx={{ color: "red", cursor: "pointer" }}
                    direction="row"
                    marginY={3}
                    marginLeft={2}
                    alignItems="center"
                    spacing={2}
                    onClick={() => {
                      dispatch(deleteEvent(navigate, selEve.id))
                    }}
                  >
                    <BackspaceOutlinedIcon fontSize="small" />
                    <Text fontSize="14px" fontWeight="800">
                      Leave Group
                    </Text>
                  </Stack>
                </Stack>
                <Stack marginX={2} marginTop={2} spacing={2}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <InputLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "12px", md: "18px" },
                        color: "black"
                      }}
                    >
                      Photos
                    </InputLabel>
                    <Button
                      sx={{
                        color: colors.green,
                        textTransform: "none"
                      }}
                      component="label"
                    >
                      <AddCircleOutlineOutlinedIcon
                        fontSize="small"
                        sx={{ marginRight: 1 }}
                      />
                      <Text fontWeight="bold" fontSize="14px">
                        Photo
                      </Text>
                      <input
                        hidden
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={onImageChange}
                      />
                    </Button>
                  </Stack>

                  {imageURLS.length > 0 ? (
                    <Stack direction={"row"} flexWrap="wrap">
                      {imageURLS.map((url, index) => (
                        <Stack
                          width={{ xs: 70, sm: 80, md: 160 }}
                          height={{ xs: 70, sm: 80, md: 160 }}
                          key={index}
                          position="relative"
                          // marginRight={{ xs: 2, sm: 1.8 }}
                          // marginBottom={2}
                          // display={index > 3 ? "none" : "flex"}
                        >
                          <Image
                            width={"80%"}
                            height={"80%"}
                            style={{ borderRadius: 8 }}
                            src={url}
                          />
                          {/* <Stack
                        sx={{
                          background: "white",
                          borderRadius: 16,
                          cursor: "pointer",
                        }}
                        display={index === 3 ? "none" : "flex"}
                        position={"absolute"}
                        right={0}
                        onClick={() => deleteUrl(url)}
                        justifyContent="center"
                        alignItems="center"
                        width={20}
                        height={20}
                      >
                        ✖
                      </Stack> */}
                        </Stack>
                      ))}
                    </Stack>
                  ) : null}

                  <Stack marginTop={4} marginLeft={2}>
                    <Text marginBottom={2} fontSize="20px" fontWeight="bold">
                      Itinerary
                    </Text>
                    <Card
                      elevation={5}
                      sx={{
                        height: { xs: 50, sm: 60 },
                        width: { xs: "90%", sm: "100%" },
                        marginLeft: 1,
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row"
                      }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={{ sm: "center" }}
                        justifyContent={"space-evenly"}
                        width="100%"
                        height={{ xs: "90%", sm: "92%" }}
                        // marginTop={{ xs: 0.3, sm: 0 }}
                      >
                        {_weekGet(new Date(selEve.start)).length != 0 &&
                          _weekGet(new Date(selEve.start)).map((key, index) => {
                            return (
                              <Stack
                                height={"100%"}
                                width={{ xs: "45px", sm: "55px" }}
                                alignItems="center"
                                sx={
                                  moment(key).format("DD") ===
                                  moment(selEve.start).format("DD")
                                    ? { background: "#24A59E" }
                                    : { background: "#FFFFFF" }
                                }
                              >
                                <Text
                                  fontSize={{ xs: "16px", sm: "20px" }}
                                  fontWeight="bold"
                                  style={{
                                    color:
                                      moment(key).format("DD") ===
                                      moment(selEve.start).format("DD")
                                        ? "#FFFFFF"
                                        : "#000000"
                                  }}
                                >
                                  {moment(key).format("dd")}
                                </Text>
                                <Text
                                  fontSize={{ xs: "14px", sm: "18px" }}
                                  style={{
                                    color:
                                      moment(key).format("DD") ===
                                      moment(selEve.start).format("DD")
                                        ? "#FFFFFF"
                                        : "#000000"
                                  }}
                                >
                                  {moment(key).format("DD")}
                                </Text>
                              </Stack>
                            )
                          })}
                      </Stack>
                    </Card>

                    {selEve.itinerary_items.length != 0 &&
                      selEve.itinerary_items.map((key, index) => {
                        return (
                          <Stack direction={"row"} marginTop={2} height={90}>
                            <Image
                              src={BarIcon}
                              fit="contain"
                              width={"30px"}
                              height={"100%"}
                            />
                            <Stack height={90}>
                              <Stack
                                key={index}
                                alignSelf={"start"}
                                position="relative"
                                height={90}
                              >
                                <ListItem sx={{ height: "100%" }}>
                                  <Stack>
                                    <ListItemText>
                                      <Text fontSize="20px" fontWeight="bold">
                                        {moment(key.start).format("LT")}
                                        {" TO "}
                                        {moment(key.end).format("LT")}
                                      </Text>
                                    </ListItemText>
                                    <ListItemText>
                                      <Text>{key.location}</Text>
                                    </ListItemText>
                                  </Stack>
                                </ListItem>
                              </Stack>
                            </Stack>
                          </Stack>
                        )
                      })}

                    <Stack width={{ xs: "95%", md: "100%" }}>
                      <Stack spacing={3} marginTop={2}>
                        <Text fontSize="20px" fontWeight="bold">
                          Location
                        </Text>
                        <div
                          style={{
                            height: 50,
                            display: "flex",
                            alignItems: "center",
                            border: `2px solid #EBEBEB`,
                            borderRadius: 5
                          }}
                        >
                          <Text fontSize="20px" color={"gray"} marginLeft={2}>
                            {selEve.location}
                          </Text>
                        </div>
                      </Stack>
                      <Stack spacing={3} marginTop={2}>
                        <Text fontSize="20px" fontWeight="bold">
                          Budget
                        </Text>
                        <div
                          style={{
                            height: 50,
                            display: "flex",
                            alignItems: "center",
                            border: `2px solid #EBEBEB`,
                            borderRadius: 5
                          }}
                        >
                          <Text fontSize="20px" color={"gray"} marginLeft={2}>
                            ${Math.round(selEve.budget)}
                          </Text>
                        </div>
                      </Stack>
                      <Stack spacing={3} marginTop={2}>
                        <Text fontSize="20px" fontWeight="bold">
                          Accomodation
                        </Text>
                        <div
                          style={{
                            height: 50,
                            display: "flex",
                            alignItems: "center",
                            border: `2px solid #EBEBEB`,
                            borderRadius: 5
                          }}
                        >
                          <Text fontSize="20px" color={"gray"} marginLeft={2}>
                            {selEve.accommodation}
                          </Text>
                        </div>
                      </Stack>
                    </Stack>

                    <Stack marginTop={2}>
                      <Dialog
                        open={showSurveyModal}
                        onClose={() => setshowSurveyModal(false)}
                        fullWidth
                        maxWidth="sm"
                      >
                        <DialogContent>
                          <Stack spacing={2}>
                            <Stack spacing={1}>
                              <Text fontSize="20px" fontWeight="bold">
                                Question
                              </Text>
                              <div
                                style={{
                                  height: 50,
                                  display: "flex",
                                  alignItems: "center",
                                  border: `2px solid #EBEBEB`,
                                  borderRadius: 5
                                }}
                              >
                                <Text
                                  fontSize="20px"
                                  color={"gray"}
                                  marginLeft={2}
                                >
                                  {question}
                                </Text>
                              </div>
                            </Stack>

                            <Stack marginTop={1}>
                              <Text fontSize="20px" fontWeight="bold">
                                Answer
                              </Text>
                              {answear.map((key, index) => {
                                return (
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                  >
                                    <CustomCheckbox
                                      size={!small ? "medium" : "small"}
                                      checked={
                                        key.selected ? key.selected : false
                                      }
                                      onChange={() => {
                                        handlerCheckBox(key, index)
                                      }}
                                    />
                                    <InputLabel sx={size}>
                                      {key.choice_text}
                                    </InputLabel>
                                  </Stack>
                                )
                              })}
                            </Stack>
                          </Stack>
                        </DialogContent>

                        <DialogActions>
                          <CustomButton
                            sx={{ color: "white" }}
                            onClick={() => {
                              setshowSurveyModal(false)
                              setupdate(false)
                            }}
                          >
                            Cancel
                          </CustomButton>
                          <CustomButton
                            sx={{ color: "white" }}
                            onClick={() => {
                              handler()
                              setupdate(false)
                            }}
                          >
                            Save
                          </CustomButton>
                        </DialogActions>
                      </Dialog>
                    </Stack>

                    <Stack marginTop={2}>
                      <Stack direction={"row"} justifyContent="space-between">
                        <Text fontSize="20px" fontWeight="bold">
                          Survey
                        </Text>
                        <Button
                          sx={{
                            color: colors.green,
                            textTransform: "none"
                          }}
                          component="label"
                        >
                          <Stack
                            direction={"row"}
                            onClick={() => {
                              setshowSurveyModal(true)
                              setupdate(false)
                            }}
                          >
                            <AddCircleOutlineOutlinedIcon
                              sx={{ fontSize: "15px", marginRight: 1 }}
                            />
                            <Text fontWeight="bold" fontSize="12px">
                              Take Suvey
                            </Text>
                          </Stack>
                        </Button>
                      </Stack>
                      <div
                        style={{
                          "white-space": "pre-wrap",
                          "overflow-wrap": "break-word"
                          // height: 50,
                          // display: "flex",
                          // alignItems: "center",
                        }}
                        // style={{ 'white-space': 'pre-wrap', 'overflow-wrap': 'break-word' }}
                      >
                        <Text
                          fontSize="20px"
                          color={"#24A59E"}
                          fontWeight="bold"
                          marginLeft={2}
                        >
                          {poll[0]?.text}
                        </Text>
                      </div>
                    </Stack>

                    {answear.length != 0 &&
                      answear.map((key, index) => {
                        return (
                          <Stack key={index} marginTop={1} style={{}}>
                            <Box
                              sx={{
                                width: "100%",
                                color: "#E5E2E2",
                                border: `2px solid #EBEBEB`,
                                borderRadius: 2
                              }}
                            >
                              <LinearProgress
                                determinate
                                variant="outlined"
                                color="inherit"
                                size="sm"
                                thickness={32}
                                value={key.percentage_of_votes}
                                sx={{
                                  "--LinearProgress-radius": "5px",
                                  "--LinearProgress-progressThickness": "100%",
                                  borderColor: "danger.plainColor"
                                }}
                              >
                                <div
                                  style={{
                                    width: "80%",
                                    zIndex: 1,
                                    justifyContent: "center"
                                  }}
                                >
                                  <Text
                                    fontSize="20px"
                                    color={"#000000"}
                                    fontWeight="bold"
                                    marginLeft={2}
                                    style={{
                                      "white-space": "pre-wrap",
                                      "overflow-wrap": "break-word"
                                    }}
                                  >
                                    {key.choice_text}
                                  </Text>
                                </div>
                                <div
                                  style={{
                                    width: "20%",
                                    zIndex: 1,
                                    justifyContent: "center"
                                  }}
                                >
                                  <Text
                                    fontSize="20px"
                                    color={"#000000"}
                                    fontWeight="bold"
                                  >
                                    {key.percentage_of_votes + "%"}
                                  </Text>
                                </div>
                              </LinearProgress>
                            </Box>
                          </Stack>
                        )
                      })}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          )
        }
        Box2={
          <Stack>
            <Stack direction={"row"} padding={3} justifyContent="center">
              {/* <Stack
                display={{
                  xs: "none",
                  sm: "flex",
                }}
              >
                <Select
                  size="small"
                  value={calenderType}
                  sx={{
                    width: 105,
                    marginTop: 1,
                    marginRight: 2,
                    fontSize: "12px",
                  }}
                  onChange={(e) => setCalenderType(e.target.value)}
                >
                  <MenuItem value={"dayGridMonth"}>Monthly</MenuItem>
                  <MenuItem value={"timeGridWeek"}>Weekly</MenuItem>
                  <MenuItem value={"timeGridDay"}>Daily</MenuItem>
                </Select>
              </Stack> */}

              <Stack
                paddingX={1}
                alignItems={"center"}
                direction={"row"}
                sx={{
                  border: 1,
                  borderColor: "grey.500",
                  borderRadius: 1,
                  height: 40
                }}
              >
                <IconButton
                  onClick={() => getCalenderApi().prev()}
                  size="small"
                  sx={{ background: colors.green, width: 20, height: 20 }}
                >
                  <KeyboardArrowLeftIcon sx={{ color: "#FFF" }} />
                </IconButton>
                <Text paddingX={2}>{moment(month).format("MMMM")}</Text>
                <IconButton
                  onClick={() => getCalenderApi().next()}
                  size="small"
                  sx={{ background: colors.green, width: 20, height: 20 }}
                >
                  <KeyboardArrowRightIcon sx={{ color: "#FFF" }} />
                </IconButton>
              </Stack>
            </Stack>
            <Stack sx={{ padding: 1 }}>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin]}
                headerToolbar={{
                  left: "",
                  center: "",
                  right: ""
                }}
                initialView={"dayGridMonth"}
                datesSet={arg => {
                  setMonth(arg.view.currentStart)
                }}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                eventContent={renderEventContent}
                // events={[
                //   {
                //     title: "event 1",
                //     date: "2023-04-08",
                //     display: "background",
                //     backgroundColor: colors.green,
                //   },
                // ]}
                events={events}
                ref={calendarRef}
              />
            </Stack>
          </Stack>
        }
      />
    </Stack>
  )
}
