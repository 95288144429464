import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import { MainRoutes } from "./MainRoutes";
import { getCurrentUser, getAllUsers, getInvites, getEvents, getSurveyQuestion, getSurveyChoice, termsAndCondition, privacyPolicy } from '../../src/store/actions/action';
import { _retrieveData } from '../../src/services/assynsStorage';
import ReactGA from "react-ga4";

// ReactGA.initialize("272076954");
ReactGA.initialize("G-XYZQEWXB6B");

export const Routing = () => {
  const dispatch = useDispatch();
  const items = JSON.parse(localStorage.getItem('isRemember'));

  useEffect(() => {
    dispatch(getCurrentUser())
    dispatch(getAllUsers())
    dispatch(getEvents())
    dispatch(getInvites())
    dispatch(getSurveyQuestion())
    dispatch(getSurveyChoice())
    dispatch(termsAndCondition())
    dispatch(privacyPolicy())
  }, [])

  return (
    <BrowserRouter>
      <AuthRoutes />
      <MainRoutes />
    </BrowserRouter>
  );
};
