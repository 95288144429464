import {
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Stack,
  useMediaQuery,
  useTheme
} from "@mui/material"
import { useState, useEffect } from "react"
import CustomCarousel from "../../components/CarouselComponent"
import AppleIcon from "@mui/icons-material/Apple"
import GoogleIcon from "@mui/icons-material/Google"
import InputField from "../../components/InputField"
import CustomButton from "../../components/CustomButton"
import Text from "../../components/Text"
import Image from "mui-image"
import { Logo } from "../../assets"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  signIn,
  showError,
  faceBookSignin,
  googleSignIn
} from "../../store/actions/action"
import CircularProgress from "@mui/material/CircularProgress"
import CustomCheckbox from "../../components/CustomCheckbox"
import { loginGoogle } from "./NewSI"
import AdsComponent from "../../services/addsens"
import { Adsense } from "@ctrl/react-adsense"

// import FacebookLogin from 'react-facebook-login';
// import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login'

export const Signin = () => {
  const dispatch = useDispatch()
  let isError = useSelector(state => state.reducer.isError)
  let isLoader = useSelector(state => state.reducer.isLoader)
  // let role = useSelector((state) => state.reducer.role);
  // console.log(role, 'isRoleisRoleisRole')

  const navigate = useNavigate()
  const [email, setEmail] = useState("testing@gmail.com")
  const [password, setPassword] = useState("123456789")
  const [isRemember, setRememberMe] = useState(false)
  const items = JSON.parse(localStorage.getItem("isRemember"))
  const isRole = JSON.parse(localStorage.getItem("isRole"))

  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("md"))

  const size = {
    fontSize: { xs: "10.5px", sm: "15px" },
    color: "black"
  }

  const input = {
    height: small && "10px",
    fontSize: small && "11px"
  }

  useEffect(() => {
    document.cookie =
      "__Secure-3PSIDCC=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
  }, [])

  useEffect(() => {
    window.sessionStorage.clear()
    window.localStorage.clear()
    if (items) {
      if (isRole != "admin") {
        navigate("/event")
      } else {
        navigate("/users")
      }
    }
  }, [])

  const submit = () => {
    let credentials = {
      email: email,
      password: password
    }
    dispatch(signIn(credentials, navigate, isRemember))
    dispatch(showError())
  }

  const googleSIgnup = async () => {
    let resp = await loginGoogle()
    console.log(resp, "resp")
    dispatch(googleSignIn(resp, navigate, isRemember))
  }

  // const responseFacebook = (response) => {
  //   console.log(response);
  // }

  const facebookLoginTest = response => {
    window.FB.login(function (response) {
      console.log(response, "facebookLoginTest")
      if (response.authResponse) {
        console.log("Welcome!  Fetching your information.... ")
        window.FB.api("/me", function (res) {
          console.log("Good to see you, " + res.name + ".")
          console.log("res", res)
          console.log("response", response)
          dispatch(
            faceBookSignin(
              response?.authResponse?.accessToken,
              navigate,
              isRemember
            )
          )
        })
      } else {
        console.log("User cancelled login or did not fully authorize.")
      }
    })
  }

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      width="100vw"
      alignItems={"center"}
      justifyContent="center"
      height={"100vh"}
    >
      {items ? (
        <CircularProgress style={{ color: "#24A59E" }} size="10rem" />
      ) : (
        <>
          <Stack
            justifyContent="center"
            display={{ xs: "none", md: "flex" }}
            position="relative"
            width={{ xs: "100vw", md: "65vw" }}
          >
            <CustomCarousel />
            <Stack
              position="absolute"
              zIndex={10}
              alignSelf="center"
              top={"15%"}
            >
              <Image src={Logo} fit="contain" />
            </Stack>
            {/* <Text
          position="absolute"
          zIndex={10}
          top={"50%"}
          textAlign="center"
          fontSize="20px"
          width={400}
          sx={{ alignSelf: "center" }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque,
          officiis.
          Ready for your next Journey? Let's go! Click here to find out more details and start planning your next group adventure.
        </Text> */}
          </Stack>

          <Stack
            sx={{
              width: {
                xs: "80vw",
                md: "35vw"
              },
              paddingLeft: { xs: 0, md: 8 }
            }}
            marginRight={{ xs: 0, md: 14 }}
            marginTop={{ xs: 0, md: 8 }}
          >
            <Stack
              display={{ xs: "flex", md: "none" }}
              marginBottom={7}
              marginTop={-7}
            >
              <Image src={Logo} style={{ width: "50vw" }} />
            </Stack>

            <Stack width="100%">
              {/* <Stack style={{ background: 'red' }}>
                  <AdsComponent dataAdSlot='1797613779' />
                  <Adsense
                    client="ca-pub-6951250899483400"
                    slot="1797613779"
                  />
                </Stack> */}

              <Text fontWeight={"bold"} variant={"h6"}>
                Welcome Back!
              </Text>
              <Text variant={"h6"}>Input your Email Address & Password</Text>
              <Stack mt={{ xs: 4, md: 7 }} spacing={{ xs: 2, md: 1 }}>
                <Stack spacing={1}>
                  <InputLabel style={{ display: "flex", flexDirection: "row" }}>
                    <Text fontSize={{ xs: "12px", md: "16px" }}>
                      Email Address
                    </Text>
                    {isError && email == "" && (
                      <Text style={{ top: 3, color: "red" }}>*</Text>
                    )}
                  </InputLabel>
                  <InputField
                    required
                    error={isError && email == ""}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    size={"small"}
                    placeholder="E-mail"
                    inputProps={{
                      style: {
                        ...input
                      }
                    }}
                  />
                </Stack>
                <Stack>
                  <InputLabel style={{ display: "flex", flexDirection: "row" }}>
                    <Text fontSize={{ xs: "12px", md: "16px" }}>Password</Text>
                    {isError && password == "" && (
                      <Text style={{ top: 3, color: "red" }}>*</Text>
                    )}
                  </InputLabel>
                  <InputField
                    required
                    error={isError && password == ""}
                    type={"password"}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    size="small"
                    placeholder="Password"
                    inputProps={{
                      style: {
                        ...input
                      }
                    }}
                  />
                </Stack>
              </Stack>

              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <CustomCheckbox
                    checked={isRemember}
                    onChange={e => setRememberMe(e.target.checked)}
                    size={!small ? "medium" : "small"}
                  />
                  <Stack
                    marginTop={1.2}
                    direction="row"
                    spacing={0.7}
                    flexWrap={"wrap"}
                    sx={size}
                  >
                    {/* <Text style={{ letterSpacing: -0.5, fontSize: 14, }}>Remember me</Text> */}
                    <Text
                      fontSize={{ xs: "12px", md: "16px", cursor: "pointer" }}
                    >
                      Remember me
                    </Text>
                  </Stack>
                </Stack>

                <Stack marginTop={1.2} onClick={() => navigate("/Forget")}>
                  <Text
                    fontSize={{ xs: "12px", md: "16px", cursor: "pointer" }}
                  >
                    Forgot Password?
                  </Text>
                </Stack>

                {/* <div style={{ display: 'flex', flexDirection: 'row-reverse', }}
              onClick={() => navigate("/Forget")}
            >
              <Text fontSize={{ xs: "12px", md: "16px", cursor: 'pointer' }}>Forgot Password?</Text>
            </div> */}
              </Stack>

              <Stack>
                <CustomButton
                  variant="contained"
                  sx={{
                    height: { xs: "40px", md: "60px" },
                    marginTop: {
                      xs: 2,
                      sm: 3
                    }
                  }}
                  onClick={() => submit()}
                >
                  {isLoader && (
                    <CircularProgress style={{ color: "white" }} size="1rem" />
                  )}
                  {!isLoader && (
                    <Text color="white" fontWeight="bold" fontSize="20px">
                      Log in
                    </Text>
                  )}
                </CustomButton>
              </Stack>
              <Stack
                display={{ xs: "flex", md: "flex" }}
                marginBottom={3}
                marginTop={5}
              >
                <Divider>
                  <u
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      cursor: "pointer",
                      color: "grey"
                    }}
                  >
                    or login with
                  </u>
                </Divider>
              </Stack>
              <Stack display={{ xs: "flex", md: "flex" }} alignItems={"center"}>
                <Stack direction={"row"} alignItems="center" spacing={4}>
                  {/* <IconButton
                      sx={{
                        backgroundColor: "beige",
                        "&:hover": {
                          backgroundColor: "antiquewhite",

                        },
                      }}
                    >
                      <AppleIcon fontSize="large" sx={{ color: "black" }} />
                    </IconButton> */}

                  {/* <FacebookLogin
                      appId="1328911057905149"
                      autoLoad
                      callback={responseFacebook}
                      render={renderProps => (
                        <button onClick={renderProps.onClick}>This is my custom FB button</button>
                      )}
                    /> */}

                  {/* 
                    <FacebookLogin
                      appId="1328911057905149"
                      autoLoad={true}
                      fields="name,email,picture"
                      // onClick={componentClicked}
                      callback={responseFacebook} > */}

                  <IconButton
                    onClick={() => {
                      facebookLoginTest()
                    }}
                    sx={{
                      backgroundColor: "darkblue",
                      "&:hover": {
                        backgroundColor: "blue"
                      }
                    }}
                  >
                    <Text
                      height={"35px"}
                      width="35px"
                      fontSize={"22px"}
                      fontWeight="bold"
                      color="primary"
                      sx={{ color: "white" }}
                      fontFamily="cursive"
                    >
                      f
                    </Text>
                  </IconButton>

                  {/* </FacebookLogin> */}

                  {/* <LoginSocialFacebook
                      appId="1328911057905149"
                      clientSecret='ee606c180ef885be5c8c8b5274cfd80e'
                      onResolve={(res) => {
                        // console.log(res, "onResolve")
                        dispatch(faceBookSignin(res, navigate, isRemember))
                      }}
                      onReject={(rej) => {
                        console.log(rej, "onReject")
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: "darkblue",
                          "&:hover": {
                            backgroundColor: "blue",
                          },
                        }}
                      >
                        <Text
                          height={"35px"}
                          width="35px"
                          fontSize={"22px"}
                          fontWeight="bold"
                          color="primary"
                          sx={{ color: "white" }}
                          fontFamily="cursive"
                        >
                          f
                        </Text>
                      </IconButton>
                    </LoginSocialFacebook> */}

                  {/* <LoginSocialGoogle
                      client_id="57141244672-47244t5nl13vic15v23iocp1njkmv2uh.apps.googleusercontent.com"
                      onResolve={(res) => {
                        // console.log(res, "onResolve")
                        dispatch(googleSignIn(res, navigate, isRemember))
                      }}
                      onReject={(rej) => {
                        console.log(rej, "onReject")
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: "cadetblue",
                          "&:hover": {
                            backgroundColor: "skyblue",
                          },
                          width: 50,
                          height: 50,
                        }}
                      >
                        <GoogleIcon sx={{ color: "white" }} />
                      </IconButton>
                    </LoginSocialGoogle> */}

                  <IconButton
                    onClick={() => {
                      googleSIgnup()
                    }}
                    sx={{
                      backgroundColor: "cadetblue",
                      "&:hover": {
                        backgroundColor: "skyblue"
                      },
                      width: 50,
                      height: 50
                    }}
                  >
                    <GoogleIcon sx={{ color: "white" }} />
                  </IconButton>
                </Stack>

                <Text
                  fontSize={{
                    xs: "12px",
                    md: "16px",
                    cursor: "pointer",
                    marginTop: 20
                  }}
                  onClick={() => navigate("/signup")}
                >
                  New to Journey Ann Hurlay? Join Now
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  )
}
