export const globalOptions = {
  name: "twilight_shadow_36966",
  url: "https://twilight-shadow-36966.botics.co/",
  api: "https://twilight-shadow-36966.botics.co/api/v1/"
}
export const modulesOptions = {
  "@modules/app-menu": {
    "copy": "Routes available!"
  }
}
