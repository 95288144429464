import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Card, CardMedia, Stack, Dialog, DialogActions, DialogContent, InputLabel, } from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CustomButton from "./CustomButton";
import InputField from "./InputField";
import Text from "./Text";
import { colors } from "../util/colors";
import moment from "moment";
import toast from 'react-hot-toast';
import { addExpense, showError } from './../store/actions/action'
import CircularProgress from '@mui/material/CircularProgress';
const NoImage = require('./../assets/NoImage1.png')

export default function CalculatorTab({ mobile, selEve }) {
  console.log(selEve, 'selEve')
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let isError = useSelector((state) => state.reducer.isError);
  let isLoader = useSelector((state) => state.reducer.isLoader);
  const [showExpenseModal, setshowExpenseModal] = useState(false);
  const [title, settitle] = useState('');
  const [amount, setamount] = useState('');

  const submit = async () => {
    let data = {
      title: title,
      amount: amount,
      event: selEve.id,
    }
    title != '' && amount != '' && dispatch(addExpense(data, navigate, setshowExpenseModal))
    title == '' && toast.error('Please type title')
    amount == '' && toast.error('Please type amount')
    dispatch(showError())

  }

  return (
    <Stack paddingX={5}>

      {mobile ? null : (
        <Card
          sx={{
            display: mobile ? "none" : "flex",
            flexDirection: "row",
            height: 80,
            alignItems: "center",
            justifyContent: "space-evenly",
            // width:'80%'
          }}
        >
          {/* <Stack direction={'row'} alignItems={'center'} > */}
          <CardMedia
            component="img"
            sx={{ width: 60, height: 60, borderRadius: 3, marginLeft: 2 }}
            image={selEve?.pictures[0]?.picture ? selEve?.pictures[0]?.picture : NoImage}
          // alt="Paella dish"
          />
          <Stack >
            <Text fontWeight="bold" fontSize="18px">
              {selEve?.title}
            </Text>
            <Stack direction="row" alignItems={"center"} spacing={1} mb={0.5}>
              <FmdGoodIcon sx={{ color: colors.green }} fontSize="9px" />
              <Text fontSize="11px" color="#A29EB6">
                {selEve?.location}
              </Text>
            </Stack>

          </Stack>
          <Stack>
            <Text
              textAlign="center"
              backgroundColor={colors.green}
              borderRadius={1}
              fontSize="12px"
              color={colors.white}
              paddingX={1}
              alignSelf="start"
            >
              Creator
            </Text>
          </Stack>
          {/* </Stack> */}

          <Stack
            alignItems={"center"}
            paddingY={0.7}
            paddingX={"13px"}
            sx={{
              backgroundColor: colors.green,
              color: "white",
              borderRadius: 16,
            }}
            mr={4}
          >
            <Text fontWeight={"300"} fontSize={"12px"}>
              {moment(selEve?.start).format("DD")}
            </Text>
            <Text fontWeight={"bold"}>{moment(selEve?.start).format("MMM")}</Text>
          </Stack>
        </Card>
      )}
      <Stack marginY={2} alignItems="center">
        <Stack direction={"row"} spacing={{ xs: 1, sm: 3 }}>
          <Text fontWeight="bold">1 Day</Text>
          <Text fontWeight="bold">丨</Text>
          <Text fontWeight="bold">{selEve?.participants.length} People</Text>
          <Text fontWeight="bold">丨</Text>
          <Text fontWeight="bold">${Math.round(selEve?.budget)}</Text>
        </Stack>
      </Stack>
      {
        selEve?.expenses.length != 0 &&
        <>
          {
            selEve?.owed_amounts.length != 0 && selEve?.owed_amounts.map((key, index) => {
              return (
                <Stack key={index}>

                  <Text fontSize={{ xs: "13px", sm: "14px" }}>
                    {key.name ? key.name : 'N/A'} owes you<b style={{ color: colors.green }}>{' $' + Math.round(key.amount)}</b>
                  </Text>

                  {/* <Text
                    color={colors.green}
                    fontWeight="bold"
                    fontSize={{ xs: "15px", sm: "17px" }}
                  >
                    You are owed $225.99 overall
                  </Text>
                  <Text fontSize={{ xs: "13px", sm: "14px" }}>
                    Kate owes you <b style={{ color: colors.green }}>$55.99</b>
                  </Text>
                  <Text fontSize={{ xs: "13px", sm: "14px" }}>
                    John owes you <b style={{ color: colors.green }}>$120.99</b>
                  </Text>
                  <Text fontSize={{ xs: "13px", sm: "14px" }}>Plus 3 other business</Text> */}
                </Stack>

              )
            })
          }



          <Stack marginTop={2}>
            <Text fontSize="15px" fontWeight="bold">{moment(selEve?.start).format('MMMM YYYY')}</Text>
            <Stack>
              {
                selEve?.expenses.length != 0 && selEve?.expenses.map((key, index) => {
                  return (
                    <Stack direction={"row"} marginY={1} key={index}>
                      <Stack alignItems={"center"} mr={0.5}>
                        <Text fontWeight={"300"} fontSize={"12px"}>{moment(selEve?.start).format("DD")}</Text>
                        <Text fontWeight={"bold"}>{moment(selEve?.start).format("MMM")}</Text>
                      </Stack>
                      <Stack sx={{ borderLeft: "1px solid black", }} />

                      <Stack flexGrow={1} ml={2}>
                        <Text fontWeight="bold" fontSize="15px">{key.title}</Text>
                        <Text fontSize="13px">{key.creator_name} paid ${Math.round(key.amount)}</Text>
                      </Stack>
                      {
                        (key.you_lent) ? (
                          <>
                            <Stack alignItems={"flex-end"}>
                              <Text fontSize="15px">{key.creator_name.substring(0, 5)} Ient</Text>
                              <Text fontSize="13px" fontWeight="bold" color={key.you_lent ? '#24A59E' : '#FF5757'}>${Math.round(key.amount) - Math.round(key.your_owed_amount)}</Text>
                            </Stack>
                          </>
                        ) : (
                          <>
                            <Stack alignItems={"flex-end"}>
                              <Text fontSize="15px">{key.creator_name.substring(0, 5)} borrowed</Text>
                              <Text fontSize="13px" fontWeight="bold" color={key.you_lent ? '#24A59E' : '#FF5757'}>${Math.round(key.amount) - Math.round(key.your_owed_amount)}</Text>
                            </Stack>
                          </>
                        )
                      }
                    </Stack>
                  )
                })
              }

              {/* {ticket.map((item, index) => (
                <Stack direction={"row"} key={index} marginY={1}>

                  <Stack alignItems={"center"} mr={0.5}>
                    <Text fontWeight={"300"} fontSize={"12px"}>{moment(new Date()).format("DD")}</Text>
                    <Text fontWeight={"bold"}>{moment(new Date()).format("MMM")}</Text>
                  </Stack>
                  <Stack sx={{ borderLeft: "1px solid black", }} />
                  <Stack flexGrow={1} ml={2}>
                    <Text fontWeight="bold" fontSize="15px">
                      {item}
                    </Text>
                    <Text fontSize="13px">You paid $1200</Text>
                  </Stack>


                  <Stack alignItems={"flex-end"}>
                    <Text fontSize="15px">
                      {index === 0 || index === 3 ? "You lent" : "You borrowed"}
                    </Text>
                    <Text
                      fontSize="13px"
                      fontWeight="bold"
                      color={index === 0 || index === 3 ? colors.green : "red"}
                    >
                      $1200
                    </Text>
                  </Stack>

                </Stack>
              ))} */}
            </Stack>
          </Stack>
        </>
      }


      <Stack spacing={1}>
        <Dialog
          open={showExpenseModal}
          onClose={() => setshowExpenseModal(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Stack spacing={2}>
              <Stack>
                <InputLabel style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text color="#000000" fontWeight="bold" fontSize="15px">Title</Text>
                  {
                    isError && title == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                  }
                </InputLabel>
                <InputField
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                  size="small"
                  placeholder={"Expense title"}
                />
              </Stack>
              <Stack>
                <InputLabel style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text color="#000000" fontWeight="bold" fontSize="15px">Amount</Text>
                  {
                    isError && title == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                  }
                </InputLabel>
                <InputField
                  type="number"
                  value={amount}
                  onChange={(e) => setamount(e.target.value)}
                  size="small"
                  placeholder={"Amount"}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <CustomButton
              sx={{ color: "white" }}
              onClick={() => { setshowExpenseModal(false) }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              sx={{ color: "white" }}
              onClick={() => { submit() }}
            >
              Save
            </CustomButton>
          </DialogActions>
        </Dialog>
      </Stack>





      <Stack alignItems={"center"} py={5}>
        <CustomButton sx={{ color: "white", width: 300, height: 50, fontSize: "12px" }}
          onClick={() => { setshowExpenseModal(true) }}
        >
          {
            isLoader && <CircularProgress style={{ 'color': 'white' }} size="1rem" />
          }
          {
            !isLoader && 'Add Expense'
          }
        </CustomButton>
      </Stack>
    </Stack >
  );
}
