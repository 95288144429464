import { useState, useEffect, useRef } from "react";
import React from "react";
import { Stack, Grid, Dialog, DialogContent, InputLabel, useTheme, useMediaQuery } from "@mui/material";

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { colors } from "../../util/colors";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Text from "../../components/Text";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import Image from "mui-image";
import { Edit, Delete, Flag } from "../../assets";
import InputField from "../../components/InputField";
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import { getUserListAdmin, createUserFromAdmin, delUserFromAdmin, editUserFromAdmin, showError } from '../../store/actions/action';
import Box from '@mui/material/Box';
import NativeSelect from '@mui/material/NativeSelect';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactGA from "react-ga4";

export default function UsersList() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let isError = useSelector((state) => state.reducer.isError);
  let isLoader = useSelector((state) => state.reducer.isLoader);
  let userListAdmin = useSelector((state) => state.reducer.userListAdmin);
  let isSearch = useSelector((state) => state.reducer.isSearch);
  const [showModal, setshowModal] = useState(false);
  const [fullName, setfullName] = useState('');
  const [role, setrole] = useState('User');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');

  const [users, setusers] = useState([]);
  const [editIndex, seteditIndex] = useState(null);
  const [editID, seteditID] = useState(null);
  const [editMode, seteditMode] = useState(false);


  useEffect(() => {
    dispatch(getUserListAdmin(navigate))
  }, [])

  useEffect(() => {
    setusers(userListAdmin)
  }, [userListAdmin])


  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const input = {
    height: small && "10px",
    fontSize: small && "11px",
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    ReactGA.event({
      category: "Admin_User_List_Category",
      action: "Admin_User_List_Action",
      label: "Admin_User_List_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }, []);

  const editUser = (key, keyIndex) => {
    console.log(key, 'key')
    seteditIndex(keyIndex)
    seteditID(key.id)
    seteditMode(true)
    setfullName(key.name)
    setrole(key.is_superuser === false ? 'User' : 'Admin')
    setEmail(key.email)
    setphoneNumber(key.phone_number.substring(1))
  };

  const flagUpdate = (key, keyIndex) => {
    console.log(key, 'flagUpdate')
    let data = {
      email: key.email,
      flagged: key.flagged ? false : true,
    }
    dispatch(editUserFromAdmin(data, key.id))
  };

  const saveUser = () => {
    console.log('+' + phoneNumber, 'saveUser')
    let data = {
      email: email,
      name: fullName,
      is_superuser: role === 'User' ? false : true,
      // flagged:true,
      phone_number: '+' + phoneNumber,
      // password:,
    }
    dispatch(editUserFromAdmin(data, editID))
    seteditMode(false)
    setfullName('')
    setrole(role)
    setEmail('')
    setphoneNumber('')
  };

  const deleteUser = (key) => {
    dispatch(delUserFromAdmin(key.id))
  };

  const createUser = () => {
    let credentials = {
      name: fullName,
      email: email,
      phone_number: '+' + phoneNumber,
      password: password,
      is_superuser: true
    }
    dispatch(createUserFromAdmin(credentials, navigate, setshowModal))
    dispatch(showError())
    setfullName('')
    setrole('User')
    setEmail('')
    setphoneNumber('')
  }

  useEffect(() => {
    searchFunction(isSearch)
  }, [isSearch])

  const searchFunction = (search) => {
    let filteredItems = [];
    if (search && search.length) {
      const searchPattern = new RegExp(search.map(term => `(?=.*${term})`).join(''), 'i');
      filteredItems = users.filter(e => {
        return e.email.match(searchPattern)
      });
    } else {
      filteredItems = userListAdmin;
    }
    if (search[0] == '') {
      dispatch(getUserListAdmin(navigate))
    }
    else {
      setusers(filteredItems)
    }
  }

  return (
    <Stack sx={{ marginX: 3, }}>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <Stack ml={3} marginTop={7}>
          <CustomButton
            backColor=""
            color="inherit"
            component="label"
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            onClick={() => setshowModal(true)}
          >
            <AddCircleIcon
              fontSize="small"
              sx={{ color: colors.green, marginRight: 1, marginTop: -0.2 }}
            />
            <Text>Add user</Text>
          </CustomButton>
        </Stack>

        {
          editMode &&
          <Stack mr={3} marginTop={7}>
            <CustomButton
              backColor=""
              color="inherit"
              component="label"
              sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              onClick={() => saveUser()}
            >
              {/* <AddCircleIcon
                fontSize="small"
                sx={{ color: colors.green, marginRight: 1, marginTop: -0.2 }}
              /> */}
              <Text sx={{ color: colors.green, }}>Save</Text>
            </CustomButton>
          </Stack>
        }


      </Stack>

      <Stack direction={"row"} flexWrap="wrap" style={{ marginTop: 10 }}>

        <Grid container justifyContent={'center'} alignItems={'center'} paddingLeft={2} marginLeft={2} marginRight={1} marginTop={1} style={{ height: 40, backgroundColor: colors.white, borderRadius: 5 }} >
          <Grid item xs          >
            <Text fontWeight='bold'>Name</Text>
          </Grid>
          <Grid item xs          >
            <Text fontWeight='bold'>Role</Text>
          </Grid>
          <Grid item xs          >
            <Text fontWeight='bold'>Email</Text>
          </Grid>
          <Grid item xs          >
            <Text fontWeight='bold'>Phone</Text>
          </Grid>
          <Grid item
            style={{ background: 'blue', width: "50px" }}
          >
          </Grid>

        </Grid>

        {
          users.length != 0 &&
          users.map((key, index) => {
            // console.log(key, "users")
            return (
              <Grid key={index} container justifyContent={'center'} alignItems={'center'} paddingLeft={2} marginLeft={2} marginRight={1} marginTop={1} style={{ height: 40, backgroundColor: colors.white, borderRadius: 5 }} >
                <Grid item xs>
                  {
                    (editMode && index === editIndex) ? (
                      <Stack spacing={1}>
                        <Stack paddingRight={3}>
                          <InputField
                            sx={{ width: "100%" }}
                            value={fullName}
                            placeholder=" Name"
                            onChange={(e) => setfullName(e.target.value)}
                            size="small"
                          />
                        </Stack>
                      </Stack>
                    ) : (
                      <Text style={{ color: key.flagged ? 'red' : 'black' }}>{key.name && key.name}</Text>
                    )
                  }

                </Grid>
                <Grid item xs>
                  {
                    (editMode && index === editIndex) ? (
                      <Stack spacing={1}>
                        <Stack paddingRight={3}>
                          <InputField
                            value={role}
                            onChange={(e) => { setrole(e.target.value) }}
                            size="small"
                            select
                            defaultValue={role}
                          >
                            {
                              [{ value: "User", label: "User", }, { value: "Admin", label: "Admin", }].map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                          </InputField>
                        </Stack>
                      </Stack>
                    ) : (
                      <Text style={{ color: key.flagged ? 'red' : 'black' }}>{key.is_superuser === false ? 'User' : 'Admin'}</Text>
                    )
                  }
                </Grid>
                <Grid item xs >
                  {/* {
                    (editMode && index === editIndex) ? (
                      <Stack spacing={1}>
                        <Stack paddingRight={3}>
                          <InputField
                            sx={{ width: "100%" }}
                            value={email}
                            placeholder=" Name"
                            onChange={(e) => setEmail(e.target.value)}
                            size="small"
                          />
                        </Stack>
                      </Stack>
                    ) : (
                      <Text>{key.email && key.email.substring(0, 30)}</Text>
                    )
                  } */}
                  <Text style={{ color: key.flagged ? 'red' : 'black' }}>{key.email && key.email.substring(0, 30)}</Text>
                </Grid>

                <Grid item xs>
                  {
                    (editMode && index === editIndex) ? (
                      <Stack spacing={1}>
                        <Stack paddingRight={3}>
                          <PhoneInput
                            country={'us'}
                            value={phoneNumber}
                            onChange={phone => setphoneNumber(phone)}
                            inputStyle={{ width: '100%', }}
                          />
                          {/* <InputField
                            sx={{ width: "100%" }}
                            value={phoneNumber}
                            placeholder="Phone number"
                            onChange={(e) => setphoneNumber(e.target.value)}
                            size="small"
                          /> */}
                        </Stack>
                      </Stack>
                    ) : (
                      <Text style={{ color: key.flagged ? 'red' : 'black' }}>{key.phone_number && key.phone_number}</Text>
                    )
                  }
                </Grid>

                <Grid item
                  style={{ width: "50px" }}
                >
                  <Stack direction="row" spacing={2}>
                    <div>
                      <div className="dropdown">
                        <div data-bs-toggle="dropdown" aria-expanded="false">
                          <MoreVertIcon />
                        </div>
                        <ul className="dropdown-menu" style={{}}>
                          <li style={{ width: '60%', cursor: 'pointer' }}>
                            <Stack
                              onClick={() => editUser(key, index)}
                              justifyContent={'center'} alignItems={'center'} direction={'row'}>
                              <Image src={Edit} fit="contain" width={17} height={17} />
                              <Text marginLeft={1}>Edit</Text>
                            </Stack>
                          </li>
                          <li style={{ width: '60%', cursor: 'pointer', marginTop: 10 }}>
                            <Stack
                              style={{ marginLeft: key.flagged ? 15 : 0 }}
                              onClick={() => flagUpdate(key, index)}
                              justifyContent={'center'} alignItems={'center'} direction={'row'}>
                              <Image src={Flag} fit="contain" width={17} height={17} />
                              <Text marginLeft={1}>{key.flagged ? 'Unflag' : 'Flag'}</Text>
                            </Stack>
                          </li>

                          <li style={{ width: '70%', cursor: 'pointer', marginTop: 10 }}>
                            <Stack
                              onClick={() => deleteUser(key)}
                              justifyContent={'center'} alignItems={'center'} direction={'row'}>
                              <Image src={Delete} fit="contain" width={17} height={17} />
                              <Text marginLeft={1}  >Delete</Text>
                            </Stack>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </Stack>
                </Grid>

              </Grid>
            )
          })
        }

        <Dialog
          open={showModal}
          onClose={() => setshowModal(false)}
          fullScreen
        >
          <DialogContent>
            <Stack spacing={2} flexDirection={"row-reverse"}>


              <Stack
                sx={{
                  width: {
                    xs: "80vw",
                    md: "35vw",
                  },
                  paddingLeft: { xs: 0, md: 8 },
                }}
                marginRight={{ xs: 0, md: 14 }}
                marginTop={{ xs: 0, md: 8 }}
              >

                <Stack width="100%" style={{ paddingBottom: 100, }}>

                  <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'} style={{}}>
                    <Text fontWeight={"bold"} variant={"h6"}>
                      Add user
                    </Text>
                    <ClearIcon fontSize={'large'} style={{ cursor: 'pointer' }} onClick={() => { setshowModal(false) }} />
                  </Stack>

                  <Stack mt={{ xs: 4, md: 7 }} spacing={{ xs: 2, md: 1 }}>
                    <Stack spacing={1}>
                      <InputLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        <Text fontSize={{ xs: "12px", md: "16px" }}>Full Name</Text>
                        {
                          isError && fullName == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                        }
                      </InputLabel>
                      <InputField
                        required
                        error={isError && fullName == ''}
                        value={fullName}
                        onChange={(e) => setfullName(e.target.value)}
                        size={"small"}
                        placeholder="Full Name"
                        inputProps={{
                          style: {
                            ...input,
                          },
                        }}
                      />
                    </Stack>
                    <Stack spacing={1}>
                      <InputField
                        value={role}
                        onChange={(e) => { setrole(e.target.value) }}
                        size="small"
                        select
                        defaultValue={role}
                      >
                        {
                          [{ value: "User", label: "User", }, { value: "Admin", label: "Admin", }].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </InputField>
                    </Stack>
                    <Stack spacing={1}>
                      <InputLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        <Text fontSize={{ xs: "12px", md: "16px" }}>Email Address</Text>
                        {
                          isError && email == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                        }
                      </InputLabel>
                      <InputField
                        required
                        error={isError && email == ''}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        size={"small"}
                        placeholder="E-mail"
                        inputProps={{
                          style: {
                            ...input,
                          },
                        }}
                      />
                    </Stack>
                    <Stack>
                      <InputLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        <Text fontSize={{ xs: "12px", md: "16px" }}>Password</Text>
                        {
                          isError && password == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                        }
                      </InputLabel>
                      <InputField
                        required
                        error={isError && password == ''}
                        type={"password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        size="small"
                        placeholder="Password"
                        inputProps={{
                          style: {
                            ...input,
                          },
                        }}
                      />
                    </Stack>
                    <Stack>
                      <InputLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        <Text fontSize={{ xs: "12px", md: "16px" }}>
                          Phone Number
                        </Text>
                        {
                          isError && phoneNumber == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                        }
                      </InputLabel>

                      <PhoneInput
                        country={'us'}
                        value={phoneNumber}
                        onChange={phone => setphoneNumber(phone)}
                        inputStyle={{ width: '100%', }}
                      />
                      {/* <InputField
                        placeholder="Phone Number"
                        required
                        error={isError && phoneNumber == ''}
                        value={phoneNumber}
                        onChange={(e) => setphoneNumber(e.target.value)}
                        size="small"
                        inputProps={{
                          style: {
                            ...input,
                          },
                        }}
                      /> */}
                    </Stack>
                  </Stack>
                  <Stack>
                    <CustomButton
                      variant="contained"
                      sx={{
                        height: { xs: "40px", md: "60px" },
                        marginTop: {
                          xs: 2,
                          sm: 3,
                        },
                      }}
                      onClick={() => createUser()}
                    >
                      {
                        isLoader && <CircularProgress style={{ 'color': 'white' }} size="1rem" />
                      }
                      {
                        !isLoader &&
                        <Text color="white" fontWeight="bold" fontSize="20px">
                          Add user
                        </Text>
                      }
                    </CustomButton>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

      </Stack>
    </Stack>
  );
};
