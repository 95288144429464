// googleOauth.js

// const id = "57141244672-47244t5nl13vic15v23iocp1njkmv2uh.apps.googleusercontent.com";
const id = "632076568651-7osjao5dflhur6m9agck7jmtvpgsj0og.apps.googleusercontent.com";
//generate this id(web oauth) from google console

const createScript = () => {
    // load the sdk
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.onload = initGoogleGSI;
    document.body.appendChild(script);
};

const initGoogleGSI = () => {
    console.log("initGoogleGSI SDK initialized");
};

createScript();

export const loginUser = async () => {
    const client = window.google.accounts.oauth2.initTokenClient({
        client_id: id,
        scope: `profile email`,
        callback: "", // defined at request time
    });
    const tokenResponse = await new Promise((resolve, reject) => {
        try {
            // Settle this promise in the response callback for requestAccessToken()
            client.callback = (resp) => {
                if (resp.error !== undefined) {
                    reject(resp);
                }

                // console.log("client resp",resp);
                resolve(resp);
            };
            // console.log("client",client);
            client.requestAccessToken({ prompt: "consent" });
        } catch (err) {
            console.log(err);
        }
    });
    return tokenResponse;
};
export const SignUpUser = async () => {
    const SCOPES = ["https://www.googleapis.com/auth/user.birthday.read",
        "https://www.googleapis.com/auth/profile.agerange.read",
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/user.gender.read",
    ].join(" ");
    const client = window.google.accounts.oauth2.initTokenClient({
        client_id: id,
        scope: SCOPES,
        callback: "", // defined at request time
    });
    const tokenResponse = await new Promise((resolve, reject) => {
        try {
            // Settle this promise in the response callback for requestAccessToken()
            client.callback = (resp) => {
                if (resp.error !== undefined) {
                    reject(resp);
                }

                // console.log("client resp",resp);
                resolve(resp);
            };
            // console.log("client",client);
            client.requestAccessToken({ prompt: "consent" });
        } catch (err) {
            console.log(err);
        }
    });
    return tokenResponse;
};

export const loginUser2 = async () => {
    const tokenResponse = await new Promise((resolve, reject) => {
        try {
            const goog = window.google.accounts.id;
            const client = goog.initialize({
                client_id: id,
                scope: `profile email`,
                callback: handleCredentialResponse, // defined at request time
            });
            // Settle this promise in the response callback for requestAccessToken()
            function handleCredentialResponse(resp) {
                if (resp.error !== undefined) {
                    reject(resp);
                }

                // console.log("client resp",resp);
                resolve(resp);
            }
            // console.log("client",client);
            window.google.accounts.id.prompt((notification) => {
                if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    window.google.accounts.id.prompt();
                    console.log("Prompt cancelled by user");
                    resolve(loginUser());
                }
            });
        } catch (err) {
            console.log("loginUser2 err", err);
        }
    });
    return tokenResponse;
};