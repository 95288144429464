import { Stack } from "@mui/material";
import Image from "mui-image";
import Text from "./Text";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { colors } from "../util/colors";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CustomCheckbox from "./CustomCheckbox";
const NoImage = require('./../assets/NoImage1.png')

export default function CalculatorEvent({
  img,
  title,
  location,
  date,
  tag,
  onChange,
  setShowMenu,
  check,
  showMenu,
}) {
  return (
    <Stack direction="row" alignItems={"center"}>
      <Stack display={{ xs: check ? "flex" : "flex", md: "none" }}>
        <CustomCheckbox
          checked={check}
          onChange={() => {
            // onChange();
            setShowMenu(false);
          }}
        />
      </Stack>
      <Stack width={{ xs: 50, sm: 60 }} height={{ xs: 50, sm: 60 }}>
        <Image
          src={img ? img : NoImage}
          width="100%"
          height="100%"
          style={{ borderRadius: 8 }}
        />
      </Stack>
      <Stack marginLeft={1.5} flexGrow={1}>
        <Text
          fontWeight="bold"
          fontSize={{ xs: "12px", sm: "18px" }}
          whiteSpace="nowrap"
        >
          {title.substring(0, 15) + '...'}
        </Text>
        <Stack direction="row" alignItems={"center"}>
          <FmdGoodIcon sx={{ color: colors.green }} fontSize="9px" />
          <Text fontSize={{ xs: "9px", sm: "10px" }} color="grey">
            {location}
          </Text>
        </Stack>
        <Text
          textAlign="center"
          backgroundColor={colors.green}
          borderRadius={1}
          fontSize="9px"
          color={colors.white}
          paddingX={1}
          alignSelf="start"
          paddingTop="1px"
        >
          {tag}
        </Text>
      </Stack>
      <Stack
        alignItems={"center"}
        paddingY={{ xs: 0.5, sm: 0.7 }}
        spacing={{ xs: -0.5, sm: 0 }}
        paddingX={{ xs: "7px", sm: "13px" }}
        sx={{ backgroundColor: colors.green, color: "white", borderRadius: 16 }}
      >
        <Text fontWeight={"300"} fontSize={{ xs: "10px", sm: "12px" }}>
          {moment(date).format("DD")}
        </Text>
        <Text fontSize={{ xs: "14px", sm: "16px" }} fontWeight={"500"}>
          {moment(date).format("MMM")}
        </Text>
      </Stack>
      <Stack display={{ xs: showMenu ? "none" : "flex", md: "none" }}>
        <ArrowDropDownIcon />
      </Stack>
    </Stack>
  );
}
