import { Card, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState, useRef } from "react"
import MainContainer from "../../components/MainContainer"
import { colors } from "../../util/colors"
import { connectAdvanced, useDispatch, useSelector } from "react-redux"
import AdSense from "react-adsense"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import "./availability.css"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import prevImage from "../../assets/avatars/prev.jpg"
import nextImage from "../../assets/avatars/next.jpg"
import {
  addAvailability,
  getAvailabilityDates
} from "../../store/actions/action"
import moment from "moment"
import toast from "react-hot-toast"

export default function Availability() {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("sm"))
  const med = useMediaQuery(theme.breakpoints.down("md"))
  const [index, setIndex] = useState(0)
  const [showContainer, setShowContainer] = useState(true)
  const [events, setevents] = useState([])
  const [date, setDate] = React.useState([new Date()])

  const [availabilityDates, setAvailabilityDates] = useState([])
  const [defaultAvailability, setDefaultAvailability] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [])

  const handleBack = () => {
    const a = events?.map(item => {
      item.selected = false
      return item
    })
    setevents(a)
    setIndex(0)
    small ? setShowContainer(false) : setShowContainer(true)
  }

  useEffect(() => {
    if (med) {
      setShowContainer(true)
    }
  }, [med])

  useEffect(() => {
    const load = async () => {
      const data = await getAvailabilityDates()
      setDate(data)
      setAvailabilityDates(data)
      setDefaultAvailability(data)
    }
    load()
  }, [])

  const CustomHeader = ({ date, onChange }) => {
    // Format the date as desired
    const formattedDate = date?.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric"
    })

    const handlePrevMonth = () => {
      const newDate = new Date(date?.getFullYear(), date?.getMonth() - 1, 1)
      onChange(newDate)
    }

    const handleNextMonth = () => {
      const newDate = new Date(date?.getFullYear(), date?.getMonth() + 1, 1)
      onChange(newDate)
    }

    return (
      <div className="calendar-header">
        <ArrowBackIosIcon onClick={handlePrevMonth} />
        <h2>{formattedDate}</h2>
        <ArrowForwardIosIcon onClick={handleNextMonth} />
      </div>
    )
  }

  const handleActiveStartDateChange = (date) => {
    setSelectedMonth(moment(date).month())
  };

  const handleSelectMonth = () => {
    const currentMonth = moment().month();
    const currentDate = moment().date();
    const selectMonth = selectedMonth ? selectedMonth : currentMonth;
    const con = selectMonth === currentMonth ? currentDate + 1 : 1
    const dates = [];
    if (selectMonth >= currentMonth) {
      const daysInMonth = moment().set('month', selectMonth).daysInMonth();
  
      for (let i = con; i <= daysInMonth; i++) {
        const date = moment().set({
          month: selectMonth,
          date: i
        });
        if (date >= moment()) {
          dates.push(date.format("YYYY-MM-DD"));
        }
      }
  
      setAvailabilityDates(dates);
    } else {
      toast.error("Cannot select past dates");
    }
  };

  const handleSelect = val => {
    const selectedDate = moment(val).format("YYYY-MM-DD")?.toString()
    const selectedDate1 = new Date(val)
    const currentDate = new Date()
    if (availabilityDates.includes(selectedDate)) {
      let tempArray = [...availabilityDates]
      const newArr = tempArray.filter(item => item !== selectedDate)
      setAvailabilityDates(newArr)
    } else {
      if (selectedDate1 >= currentDate) {
        setDate(selectedDate)
        setAvailabilityDates(prevDates => [...prevDates, selectedDate])
      } else {
        toast.error("Cannot select past dates")
      }
    }
  }

  function formatDateArray(input) {
    if (typeof input === "string") {
      return input
    } else if (input instanceof Date) {
      const year = input.getFullYear()
      const month = String(input.getMonth() + 1).padStart(2, "0")
      const day = String(input.getDate()).padStart(2, "0")
      return `${year}-${month}-${day}`
    } else {
      return input.toString()
    }
  }
  const save = () => {
    const dynamicFormatArray = availabilityDates.map(item =>
      formatDateArray(item)
    )
    addAvailability({ date: dynamicFormatArray }, setDefaultAvailability)
  }

  function tileClassName({ date, view }) {
    const selectedDate = moment(date).format("YYYY-MM-DD")?.toString()
    if (view === "month" && availabilityDates.includes(selectedDate)) {
      return "highlight"
    }
  }

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false
      }
    }
    return true
  }
  const isSaveDisabled = arraysAreEqual(availabilityDates, defaultAvailability)

  return (
    <Stack sx={{ background: colors.background_color }} height="100vh">
      <MainContainer
        showBoxTwo={true}
        handleBack={handleBack}
        variant={4}
        showContainer={showContainer}
        Box1={
          <Stack mt={5} spacing={5}>
            <div
              style={{
                marginLeft: 80,
                marginRight: 80,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  color: "#868893",
                  textAlign: "left",
                  width: 368
                }}
              >
                Please select dates or whole month on the calendar to mark your
                availability for trips. This will help when planning events with
                your friends.
              </Typography>
            </div>
            <button
              className={"select-month-btn"}
              onClick={handleSelectMonth}
            >
              Select Month
            </button>
            <div className="calendar-wrapper availabilityCalender">
              <Calendar
                onChange={handleSelect}
                tileClassName={tileClassName}
                onActiveStartDateChange={({ activeStartDate }) => handleActiveStartDateChange(activeStartDate)}
                prevLabel={
                  <div className="lable-container">
                    <div className="custom-navigation-label">
                      <img src={prevImage} alt="Previous" />
                    </div>
                  </div>
                }
                nextLabel={
                  <div className="lable-container">
                    <div className="custom-navigation-label">
                      <img src={nextImage} alt="Next" />
                    </div>
                  </div>
                }
                prev2Label=""
                next2Label=""
              />
            </div>
            <div className="save-btn">
              <button
                className={isSaveDisabled ? "btn-1-disabled" : "btn-1"}
                onClick={save}
                disabled={isSaveDisabled ? true : false}
              >
                Save My Availability
              </button>
              <button
                className={isSaveDisabled ? "btn-2-disabled" : "btn-2"}
                onClick={() => setAvailabilityDates(defaultAvailability)}
                disabled={isSaveDisabled ? true : false}
              >
                Cancel
              </button>
            </div>
          </Stack>
        }
        Box2={
          <Stack
            display={{ xs: "none", sm: "flex" }}
            marginX={{ xs: 2, md: 0 }}
            marginTop={{ xs: 2, md: 5 }}
          ></Stack>
        }
      />
      <AdSense.Google
        client="ca-pub-6951250899483400"
        slot="1797613779"
        style={{ display: "block" }}
        format="auto"
        responsive="true"
        layoutKey="-gw-1+2a-9x+5c"
      />
    </Stack>
  )
}
