import {
  Stack,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tabs,
  Tab,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import MainContainer from "../../components/MainContainer";
import Text from "../../components/Text";
import SearchIcon from "@mui/icons-material/Search";
import TabPanel from "../../components/TabPanel";
import { colors } from "../../util/colors";
import { useNavigate, useLocation } from "react-router-dom";
import { sendMsg, getChats, getPvtChats, getInboxItems } from '../../store/actions/action'
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import Image from "mui-image";
import ReactGA from "react-ga4";

export default function Chat() {
  const [showContainer, setShowContainer] = useState(true);
  const [index, setIndex] = useState(0);
  const [tabs, setTabs] = useState(0);
  const dispatch = useDispatch()
  const { state } = useLocation();
  // console.log(state?.channel, state?.title, state?.email, state?.private, 'Hello_test')
  let pvtChannelId = useSelector((state) => state.reducer.pvtChannelId);
  const [selChannelId, setselChannelId] = useState('');
  const bottomRef = useRef(null);
  const messageEl = useRef(null);



  // useEffect(() => {
  //   if (state?.private) {
  //     dispatch(getPvtChats(state?.email))
  //   } else {
  //     dispatch(getChats(state?.channel))
  //   }
  // }, [state?.email, state?.channel])


  let isLoader = useSelector((state) => state.reducer.isLoader);
  let inboxItems1to1 = useSelector((state) => state.reducer.inboxItems1to1);
  let inboxItemsGroup = useSelector((state) => state.reducer.inboxItemsGroup);
  const [inboxChat1to1, setinboxChat1to1] = useState([]);
  const [inboxChatGroup, setinboxChatGroup] = useState([]);
  // console.log(inboxChatGroup, "inboxChatGroup")
  let chats = useSelector((state) => state.reducer.chats);
  let currentUser = useSelector((state) => state.reducer.user);
  const [soortedChat, setsoortedChat] = useState([]);

  useEffect(() => {
    dispatch(getInboxItems())
    ReactGA.event({
      category: "Chat_Category",
      action: "Chat_Action",
      label: "Chat_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }, [])

  useEffect(() => {
    let propertyAddIn1to1C = []
    for (let index = 0; index < inboxItems1to1.length; index++) {
      const element = inboxItems1to1[index];
      element.selected = false
      element.showId = index
      propertyAddIn1to1C.push(element)
    }

    let propertyAddInGC = []
    for (let index = 0; index < inboxItemsGroup.length; index++) {
      const element = inboxItemsGroup[index];
      element.selected = false
      element.showId = index
      propertyAddInGC.push(element)
    }

    setinboxChat1to1(propertyAddIn1to1C)
    setinboxChatGroup(inboxItemsGroup)
  }, [inboxItems1to1, inboxItemsGroup])

  const submit = (msg, img) => {
    console.log(img, 'msgmsgmsg')
    let form_data = new FormData();
    let data = {
      content: msg,
      channel: selChannelId ? selChannelId : pvtChannelId
    }
    form_data.append('content', msg);
    form_data.append('channel', selChannelId ? selChannelId : pvtChannelId);
    img && form_data.append('image', img);
    dispatch(sendMsg(form_data, data.channel,))
  }

  const handleChange = (event, newValue) => {
    setTabs(newValue);
  };

  const [menu, setmenu] = useState([]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chats, menu]);



  useEffect(() => {
    let propertyAddInChat = []
    let arrMenu = []
    let soortedChat = chats?.sort((a, b) => moment(a.timestamp).format('x') - moment(b.timestamp).format('x'))
    // bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    for (let index = 0; index < chats.length; index++) {
      const element = chats[index];
      element.selected = false
      element.showId = index
      propertyAddInChat.push(element)
      let obj = {
        value:
          <Grid
            item
            xs={10}
            paddingTop={{ xs: 1, sm: 0 }}
            marginTop={{ xs: 5, md: 10 }}
          >
            <Grid marginX={4} >

              {
                soortedChat.map((item, index) => {
                  console.log(item, 'messages')
                  return (
                    <List
                    // sx={{
                    //   marginTop: index === 2 || index === 4 ? 4 : -3,
                    // }}
                    >
                      <Grid container>
                        <Grid item md={1} />
                        <Grid item xs={12}>
                          <ListItem>
                            <Grid container justifyContent={item.email !== currentUser.email ? 'flex-start' : 'flex-end'}>
                              <Grid item>
                                <ListItemIcon>
                                  <Stack
                                    position={"relative"}
                                    display={
                                      item.email !== currentUser.email ? "flex" : "none"
                                    }
                                  >
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={item?.profile_picture}
                                      sx={{
                                        height: { xs: 35, md: 50 },
                                        width: { xs: 35, md: 50 },
                                        marginRight: 2,
                                      }}
                                    />
                                    <Stack
                                      sx={{
                                        background: colors.green,
                                        borderRadius: 16,
                                      }}
                                      position="absolute"
                                      width={10}
                                      height={10}
                                      top={0}
                                      right={20}
                                    />
                                  </Stack>
                                </ListItemIcon>
                              </Grid>

                              <Grid item>
                                <Stack>
                                  <ListItemText>
                                    {
                                      item.image == null &&
                                      <Text
                                        sx={{
                                          backgroundColor: item.email !== currentUser.email ? colors.background_color : colors.green,
                                          color: item.email !== currentUser.email ? 'black' : 'white',
                                          borderTopLeftRadius: item.email !== currentUser.email ? 0 : 50,
                                          borderTopRightRadius: item.email !== currentUser.email ? 50 : 0,
                                          borderBottomRightRadius: 50,
                                          borderBottomLeftRadius: 50,
                                          // alignSelf: "end",
                                          paddingLeft: 3,
                                          paddingTop: 1,
                                          display: "flex",
                                          alignItems: "center",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={10}
                                            sx={{ fontWeight: "bold" }}
                                          >
                                            {item.content}
                                          </Grid>


                                          <Grid
                                            item
                                            xs={12}
                                            sx={{ color: item.email !== currentUser.email ? 'gray' : '#A1D9D6', }}
                                          >
                                            <ListItemText secondary  >

                                              <Text style={{ fontSize: 10 }}>{moment(item.timestamp).subtract(6, 'days').calendar()}</Text>

                                            </ListItemText>
                                          </Grid>




                                        </Grid>
                                      </Text>
                                    }
                                  </ListItemText>
                                </Stack>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </Grid>
                      </Grid>
                      {
                        item.image &&
                        <Stack alignItems={'flex-end'} paddingBottom={2} style={{}}>
                          <Image
                            width={"70%"}
                            height={"70%"}
                            style={{ borderRadius: 8 }}
                            src={item.image}
                          />
                        </Stack>
                      }
                      <Grid item md={1} />

                    </List>
                  )
                })
              }

            </Grid>
          </Grid>

      }
      arrMenu.push(obj)
    }
    setmenu(arrMenu)
    setsoortedChat(soortedChat)
    dispatch(getInboxItems())
  }, [chats])

  // console.log(menu, 'menu_console')

  const handleClick = (id, showId, indexArr, item) => {
    console.log()
    setselChannelId(id)
    console.log(item, item.users[0].email === currentUser.email, 'item')
    if (tabs === 0) {
      if (item.users[0].email === currentUser.email) {
        dispatch(getPvtChats(item.users[1]?.email))
      }
      else {
        dispatch(getPvtChats(item.users[0]?.email))
      }

    } else {
      dispatch(getChats(item.id))
    }
    setShowContainer(true);
    // let updated = []
    // for (let index = 0; index < soortedChat.length; index++) {
    //   const element = soortedChat[index];
    //   if (indexArr === index) {
    //     element.selected = true
    //   }
    //   else {
    //     element.selected = false;
    //   }
    //   updated.push(element)
    // }
    // setsoortedChat(updated)
    if (tabs === 0) {
      let updated = []
      for (let index = 0; index < inboxItems1to1.length; index++) {
        const element = inboxItems1to1[index];
        if (indexArr === index) {
          element.selected = true
        }
        else {
          element.selected = false;
        }
        updated.push(element)
      }
      setinboxChat1to1(updated)
    }
    else {
      let updated = []
      for (let index = 0; index < inboxChatGroup.length; index++) {
        const element = inboxChatGroup[index];
        if (indexArr === index) {
          element.selected = true
        }
        else {
          element.selected = false;
        }
        updated.push(element)
      }
      setinboxChatGroup(updated)
    }
    setIndex(indexArr);
  };

  const handleBack = () => {
    // const a = chats.map((item) => {
    //   item.isSelected = false;
    //   return item;
    // });
    // setChats(a);
    setIndex(0);
    setShowContainer(true);
  };


  const searchFunction = (search) => {
    let filteredItems = [];
    if (inboxItems1to1.length && tabs === 0) {
      if (search && search.length) {
        const searchPattern = new RegExp(search.map(term => `(?=.*${term})`).join(''), 'i');
        filteredItems = inboxItems1to1.filter(e => {
          return e.users[1]?.email.match(searchPattern)
        });
      } else {
        filteredItems = inboxItems1to1;
      }
      if (search[0] == '') {
        dispatch(getInboxItems())
      }
      else {
        setinboxChat1to1(filteredItems)
      }

    } else {
      if (search && search.length) {
        const searchPattern = new RegExp(search.map(term => `(?=.*${term})`).join(''), 'i');
        filteredItems = inboxItemsGroup.filter(e => {
          return e.event_title.match(searchPattern)
        });
      } else {
        filteredItems = inboxItemsGroup;
      }
      if (search[0] == '') {
        dispatch(getInboxItems())
      }
      else {
        setinboxChatGroup(filteredItems)
      }
    }
  }

  return (
    <Stack>
      <MainContainer
        submit={submit}
        soortedChat={soortedChat}
        handleBack={handleBack}
        variant={2}
        showContainer={showContainer}
        Box1={
          <Stack width="90%" marginTop={4} alignSelf={"center"}>
            <Grid>
              <Tabs
                value={tabs}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: colors.green,
                  },
                }}
              >
                <Tab
                  label={
                    <span
                      style={{ color: colors.green, textTransform: "none" }}
                    >
                      Messages
                    </span>
                  }
                />
                <Tab
                  label={
                    <span
                      style={{ color: colors.green, textTransform: "none" }}
                    >
                      Group Chat
                    </span>
                  }
                />
              </Tabs>

              <Grid style={{ paddingTop: "30px", marginBottom: 5 }}>
                <TextField
                  placeholder="Search Messages"
                  variant="outlined"
                  fullWidth
                  sx={{
                    boxShadow: "0px 0px 5px grey",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}

                  onChange={(e) => { searchFunction(e.target.value.split(' ')) }}


                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon fontSize="large" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <TabPanel value={tabs} index={0}>
                <Stack
                  maxHeight={`calc(78vh - 100px)`}
                  overflow="scroll"
                  sx={{
                    "::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  <List sx={{ marginX: { xs: 0, lg: -1 } }}>
                    {inboxChat1to1.map((item, index) => (
                      <Stack key={index}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: {
                            sm: item.selected && colors.background_color,
                          },
                          boxShadow: { md: "none" },
                        }}
                      >
                        {
                          item.last_message &&
                          <ListItem


                            onClick={() => handleClick(item.id, item.showId, index, item)}
                          >

                            <ListItemIcon>
                              <Stack position={"relative"}>
                                <Avatar
                                  // alt="Img"
                                  src={
                                    (item.users[0].email === currentUser.email) ? (
                                      item.users[1]?.profile_picture
                                    ) : (
                                      item.users[0]?.profile_picture
                                    )
                                  }
                                  sx={{
                                    height: { xs: 40, md: 60 },
                                    width: { xs: 40, md: 60 },
                                    marginRight: 2,
                                  }}
                                />
                                {/* <Stack
                              display={index === 1 ? "flex" : "none"}
                              sx={{
                                background: colors.green,
                                borderRadius: 16,
                                border: "0.1px solid white",
                              }}
                              position="absolute"
                              width={12}
                              height={12}
                              top={0}
                              right={20}
                            /> */}
                              </Stack>
                            </ListItemIcon>
                            <Stack
                              direction={"row"}
                              width="inherit"
                              position={"relative"}
                            >
                              <Stack marginTop={{ xs: -2.2, md: -2 }}>
                                <ListItemText>
                                  <Text
                                    fontWeight="bold"
                                    fontSize={{
                                      xs: "12px",
                                      md: "18px",
                                      lg: "15px",
                                      xl: "18px",
                                    }}
                                  >
                                    {
                                      (item.users[0].email === currentUser.email) ? (
                                        item.users[1].name || item.users[1].email.substring(0, 20) + '...'
                                      ) : (

                                        item.users[0].name || item.users[0].email.substring(0, 20) + '...'
                                      )
                                    }
                                  </Text>
                                </ListItemText>
                                <ListItemText
                                  style={{
                                    marginTop: -4,
                                    color: "grey",
                                    position: "relative",
                                    width: "inherit",
                                  }}
                                >
                                  <Text
                                    width="100%"
                                    position="absolute"
                                    overflow="hidden"
                                    textWrap="nowrap"
                                    whiteSpace="nowrap"
                                    fontSize={{ xs: "12px", md: "14px" }}
                                  >
                                    {item.last_message != null && item.last_message.substring(0, 20) + '...'}
                                  </Text>
                                </ListItemText>
                              </Stack>
                              <Stack
                                position={"absolute"}
                                right={{ xs: index === 0 ? -13 : -27, md: 0 }}
                                marginTop={-1.7}
                              >
                                <ListItemText
                                  style={{
                                    // color: index === 0 ? colors.green : "grey",
                                    // width: index === 1 ? 50 : index > 1 && 61,
                                    color: "grey",
                                    alignSelf: "end",
                                  }}
                                >
                                  <Text fontSize={{ xs: "10px", md: "14px" }}>
                                    {item.timestamp ? moment(item.timestamp).format('LT') : ''}
                                  </Text>
                                </ListItemText>
                                {/* <Text
                              sx={{
                                fontSize: "16px",
                                width: 23,
                                height: 23,
                                background: "red",
                                borderRadius: 16,
                                color: "white",
                                display: index === 0 ? "flex" : "none",
                                justifyContent: "center",
                                alignSelf: "end",
                              }}
                            >
                              5
                            </Text> */}
                              </Stack>
                            </Stack>
                          </ListItem>

                        }
                      </Stack>

                    ))}
                  </List>
                </Stack>
              </TabPanel>

              <TabPanel value={tabs} index={1}>
                <Stack
                  maxHeight={`calc(78vh - 100px)`}
                  overflow="scroll"
                  sx={{
                    "::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  <List sx={{ marginX: { xs: 0, lg: -1 } }}>
                    {inboxChatGroup.map((item, index) => (
                      <Stack key={index}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: {
                            sm: item.selected && colors.background_color,
                          },
                          boxShadow: { md: "none" },
                        }}
                      >
                        {
                          item.last_message && <ListItem
                            sx={{
                              cursor: "pointer",
                              backgroundColor: {
                                sm: item.selected && colors.background_color,
                              },
                              boxShadow: { md: "none" },
                            }}
                            key={index}
                            onClick={() => handleClick(item.id, item.showId, index, item)}
                          >
                            <ListItemIcon>
                              <Stack position={"relative"}>
                                <Avatar
                                  // alt="Remy Sharp"
                                  src={item.users[1].profile_picture}
                                  sx={{
                                    height: { xs: 40, md: 60 },
                                    width: { xs: 40, md: 60 },
                                    marginRight: 2,
                                  }}
                                />
                                {/* <Stack
                              display={index === 1 ? "flex" : "none"}
                              sx={{
                                background: colors.green,
                                borderRadius: 16,
                                border: "0.1px solid white",
                              }}
                              position="absolute"
                              width={12}
                              height={12}
                              top={0}
                              right={20}
                            /> */}
                              </Stack>
                            </ListItemIcon>
                            <Stack
                              direction={"row"}
                              width="inherit"
                              position={"relative"}
                            >
                              <Stack marginTop={{ xs: -2.2, md: -2 }}>
                                <ListItemText>
                                  <Text
                                    fontWeight="bold"
                                    fontSize={{
                                      xs: "12px",
                                      md: "18px",
                                      lg: "15px",
                                      xl: "18px",
                                    }}
                                  >
                                    {item.event_title.substring(0, 20) + '...'}
                                  </Text>
                                </ListItemText>
                                <ListItemText
                                  style={{
                                    marginTop: -4,
                                    color: "grey",
                                    position: "relative",
                                    width: "inherit",
                                  }}
                                >
                                  <Text
                                    width="100%"
                                    position="absolute"
                                    overflow="hidden"
                                    textWrap="nowrap"
                                    whiteSpace="nowrap"
                                    fontSize={{ xs: "12px", md: "14px" }}
                                  >
                                    {item.last_message != null && item.last_message.substring(0, 20) + '...'}
                                  </Text>
                                </ListItemText>
                              </Stack>
                              <Stack
                                position={"absolute"}
                                right={{ xs: index === 0 ? -13 : -27, md: 0 }}
                                marginTop={-1.7}
                              >
                                <ListItemText
                                  style={{
                                    color: index === 0 ? colors.green : "grey",
                                    width: index === 1 ? 50 : index > 1 && 61,
                                    alignSelf: "end",
                                  }}
                                >
                                  <Text fontSize={{ xs: "10px", md: "14px" }}>
                                    {item.timestamp ? moment(item.timestamp).format('LT') : ''}
                                  </Text>
                                </ListItemText>
                                {/* <Text
                              sx={{
                                fontSize: "16px",
                                width: 23,
                                height: 23,
                                background: "red",
                                borderRadius: 16,
                                color: "white",
                                display: index === 0 ? "flex" : "none",
                                justifyContent: "center",
                                alignSelf: "end",
                              }}
                            >
                              5
                            </Text> */}
                              </Stack>
                            </Stack>
                          </ListItem>
                        }
                      </Stack>



                    ))}
                  </List>
                </Stack>
              </TabPanel>

            </Grid>
          </Stack>
        }
        Box2={
          < Stack style={{}}>
            {menu && menu.length != 0 && menu[index]?.value}
            < div ref={bottomRef} />
          </Stack >
        }
      />
    </Stack >
  );
}
