import { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import Text from "./Text";
import ReactGA from "react-ga4";

export default function TermsAndPrivecy({ text, data, isdieApp }) {
    // console.log(data, "TERMS AND POLICY")

    useEffect(() => {
        if (text != 'Terms and Conditions') {
            ReactGA.event({
                category: "Privacy_Policy_Category",
                action: "Privacy_Policy_Action",
                label: "Privacy_Policy_Lable", // optional
                // value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
            });
        } else {
            ReactGA.event({
                category: "Terms_and_Conditions_Category",
                action: "Terms_and_Conditions_Action",
                label: "Terms_and_Conditions_Lable", // optional
                // value: 99, // optional, must be a number
                nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
            });
        }
    }, [])

    return (
        <Stack
            sx={{
                width: {
                    xs: "80vw",
                    md: "35vw",
                },
                paddingLeft: { xs: 0, md: isdieApp ? 0 : 8 },
            }}
            marginRight={{ xs: 0, md: 14 }}
            marginTop={{ xs: 0, md: isdieApp ? -7 : 0 }}
        >
            <Stack width="100%" >
                <Stack mt={{ xs: 4, md: 7 }} spacing={{ xs: 2, md: 2 }}
                    sx={{
                        "::-webkit-scrollbar": { display: "none" },
                    }}
                    md={{
                        "::-webkit-scrollbar": { display: "none" },
                    }}
                    style={{ height: window.innerHeight - 100, overflow: 'scroll', }}
                >
                    <Text variant={"h5"} style={{ fontWaight: 'bold', textDecoration: 'underline' }} >{text}</Text>
                    {
                        text != 'Terms and Conditions' &&
                        <>
                            <Text marginTop={1}>
                                Protecting your private information is our priority. This Statement of Privacy applies to
                                www.journeytogethertravel.com, and Journey Together Travel and governs data collection and
                                usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Journey
                                Together Travel include www.journeytogethertravel.com and Journey. The Journey website is a
                                group travel planning site. By using the Journey website, you consent to the data practices
                                described in this statement.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Collection of your Personal Information</Text>

                            <Text marginTop={1}>
                                In order to better provide you with products and services offered, Journey may collect personally
                                identifiable information, such as your:
                            </Text>

                            <Text marginTop={1}>
                                - First and Last Name<br />
                                - E-mail Address<br />
                                - Phone Number<br />
                            </Text>

                            <Text marginTop={1}>
                                Please keep in mind that if you directly disclose personally identifiable information or personally
                                sensitive data through Journey's public message boards, this information may be collected and
                                used by others.
                            </Text>

                            <Text marginTop={2}>
                                We do not collect any personal information about you unless you voluntarily provide it to us.
                                However, you may be required to provide certain personal information to us when you elect to use
                                certain products or services. These may include: (a) registering for an account; (b) entering a
                                sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers
                                from selected third parties; (d) sending us an email message; (e) submitting your credit card or
                                other payment information when ordering and purchasing products and services. To wit, we will
                                use your information for, but not limited to, communicating with you in relation to services and/or
                                products you have requested from us. We also may gather additional personal or non-personal
                                information in the future.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Use of your Personal Information</Text>

                            <Text marginTop={1}>
                                Journey collects and uses your personal information to operate and deliver the services you have
                                requested.
                            </Text>

                            <Text marginTop={1}>
                                Journey may also use your personally identifiable information to inform you of other products or
                                services available from Journey and its affiliates.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Sharing Information with Third Parties</Text>

                            <Text marginTop={1}>
                                Journey does not sell, rent or lease its customer lists to third parties.
                            </Text>

                            <Text marginTop={1}>
                                Journey may share data with trusted partners to help perform statistical analysis, send you email or
                                postal mail, provide customer support, or arrange for deliveries. All such third parties are
                                prohibited from using your personal information except to provide these services to Journey, and
                                they are required to maintain the confidentiality of your information.
                            </Text>


                            <Text marginTop={1}>
                                Journey may disclose your personal information, without notice, if required to do so by law or in
                                the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply
                                with legal process served on Journey or the site; (b) protect and defend the rights or property of
                                Journey; and/or (c) act under exigent circumstances to protect the personal safety of users of
                                Journey, or the public.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Tracking User Behavior</Text>

                            <Text marginTop={1}>
                                Journey may keep track of the websites and pages our users visit within Journey, in order to
                                determine what Journey services are the most popular. This data is used to deliver customized
                                content and advertising within Journey to customers whose behavior indicates that they are
                                interested in a particular subject area.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Automatically Collected Information</Text>

                            <Text marginTop={1}>
                                Information about your computer hardware and software may be automatically collected by
                                Journey. This information can include: your IP address, browser type, domain names, access times
                                and referring website addresses. This information is used for the operation of the service, to
                                maintain quality of the service, and to provide general statistics regarding use of the Journey
                                website.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Links</Text>

                            <Text marginTop={1}>
                                This website contains links to other sites. Please be aware that we are not responsible for the
                                content or privacy practices of such other sites. We encourage our users to be aware when they
                                leave our site and to read the privacy statements of any other site that collects personally
                                identifiable information.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Right to Deletion </Text>

                            <Text marginTop={1}>
                                Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
                                it is necessary to:<br />
                                • Delete your personal information from our records; and<br />
                                • Direct any service providers to delete your personal information from their records.
                            </Text>

                            <Text marginTop={1}>
                                Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:<br />
                                Complete the transaction for which the personal information was collected, fulfill the
                                terms of a written warranty or product recall conducted in accordance with federal
                                law, provide a good or service requested by you, or reasonably anticipated within the
                                context of our ongoing business relationship with you, or otherwise perform a contract
                                between you and us;<br />
                                • Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
                                activity; or prosecute those responsible for that activity;<br />
                                • Debug to identify and repair errors that impair existing intended functionality;<br />
                                • Exercise free speech, ensure the right of another consumer to exercise his or her right
                                of free speech, or exercise another right provided for by law;<br />

                                • Comply with the California Electronic Communications Privacy Act;<br />
                                • Engage in public or peer-reviewed scientific, historical, or statistical research in the
                                public interest that adheres to all other applicable ethics and privacy laws, when our
                                deletion of the information is likely to render impossible or seriously impair the
                                achievement of such research, provided we have obtained your informed consent;<br />
                                • Enable solely internal uses that are reasonably aligned with your expectations based on
                                your relationship with us;<br />
                                • Comply with an existing legal obligation; or<br />
                                • Otherwise use your personal information, internally, in a lawful manner that is
                                compatible with the context in which you provided the information.

                            </Text>



                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Children Under Thirteen</Text>
                            <Text marginTop={1}>
                                Journey does not knowingly collect personally identifiable information from children under the age
                                of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for
                                permission to use this website.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>E-mail Communications</Text>

                            <Text marginTop={1}>
                                From time to time, Journey may contact you via email for the purpose of providing
                                announcements, promotional offers, alerts, confirmations, surveys, and/or other general
                                communication. In order to improve our Services, we may receive a notification when you open an
                                email from Journey or click on a link therein.
                            </Text>

                            <Text marginTop={1}>
                                If you would like to stop receiving marketing or promotional communications via email from
                                Journey, you may opt out of such communications by Customers may unsubscribe from emails by
                                "replying STOP" or "clicking on the UNSUBSCRIBE button.".
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Changes to this Statement</Text>

                            <Text marginTop={1}>
                                Journey reserves the right to change this Privacy Policy from time to time. We will notify you about
                                significant changes in the way we treat personal information by sending a notice to the primary
                                email address specified in your account, by placing a prominent notice on our website, and/or by
                                updating any privacy information. Your continued use of the website and/or Services available
                                after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy;
                                and (b) agreement to abide and be bound by that Policy.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Contact Information </Text>

                            <Text marginTop={1}>
                                Journey welcomes your questions or comments regarding this Statement of Privacy. If you believe
                                that Journey has not adhered to this Statement, please contact Journey at:
                            </Text>

                            <Text marginTop={1}>
                                Journey Together Travel<br />
                                6434 Orchid Lane<br />
                                Dallas, Texas 75230<br />
                                Email Address:<br />
                                _________________<br />
                                Telephone number:<br />
                                214-499-8777<br />
                                Effective as of May 31, 2023
                            </Text>
                        </>
                    }
                    {
                        text == 'Terms and Conditions' &&
                        <>
                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Agreement between User and www.journeytogethertravel.com </Text>

                            <Text marginTop={1}>
                                Welcome to www.journeytogethertravel.com. The www.journeytogethertravel.com website (the
                                "Site") is comprised of various web pages operated by Journey Together Travel ("Journey").
                                www.journeytogethertravel.com is offered to you conditioned on your acceptance without
                                modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of
                                www.journeytogethertravel.com constitutes your agreement to all such Terms. Please read these
                                terms carefully, and keep a copy of them for your reference.
                            </Text>
                            <Text marginTop={1}>
                                www.journeytogethertravel.com is an E-Commerce Site.
                            </Text>
                            <Text marginTop={1}>
                                Journey provides group travel planning services making it easy for clients to coordinate travel
                                dates, locations, accommodations, splitting expenses, sharing photos and much much more.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Privacy</Text>

                            <Text marginTop={1}>
                                Your use of www.journeytogethertravel.com is subject to Journey's Privacy Policy. Please review
                                our Privacy Policy, which also governs the Site and informs users of our data collection practices.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Electronic Communications</Text>

                            <Text marginTop={1}>
                                Visiting www.journeytogethertravel.com or sending emails to Journey constitutes electronic
                                communications. You consent to receive electronic communications and you agree that all
                                agreements, notices, disclosures and other communications that we provide to you electronically,
                                via email and on the Site, satisfy any legal requirement that such communications be in writing.
                            </Text>
                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Your Account</Text>

                            <Text marginTop={1}>
                                If you use this site, you are responsible for maintaining the confidentiality of your account and
                                password and for restricting access to your computer, and you agree to accept responsibility for
                                all activities that occur under your account or password. You may not assign or otherwise transfer
                                your account to any other person or entity. You acknowledge that Journey is not responsible for
                                third party access to your account that results from theft or misappropriation of your account.
                                Journey and its associates reserve the right to refuse or cancel service, terminate accounts, or
                                remove or edit content in our sole discretion.
                            </Text>
                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Children Under Thirteen</Text>

                            <Text marginTop={1}>
                                Journey does not knowingly collect, either online or offline, personal information from persons
                                under the age of thirteen. If you are under 18, you may use www.journeytogethertravel.com only
                                with permission of a parent or guardian.
                            </Text>
                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Links to Third Party Sites/Third Party Services</Text>

                            <Text marginTop={1}>
                                www.journeytogethertravel.com may contain links to other websites ("Linked Sites"). The Linked
                                Sites are not under the control of Journey and Journey is not responsible for the contents of any
                                Linked Site, including without limitation any link contained in a Linked Site, or any changes or
                                updates to a Linked Site. Journey is providing these links to you only as a convenience, and the
                                inclusion of any link does not imply endorsement by Journey of the site or any association with its
                                operators.
                            </Text>
                            <Text marginTop={1}>
                                Certain services made available via www.journeytogethertravel.com are delivered by third party
                                sites and organizations. By using any product, service or functionality originating from the
                                www.journeytogethertravel.com domain, you hereby acknowledge and consent that Journey may
                                share such information and data with any third party with whom Journey has a contractual
                                relationship to provide the requested product, service or functionality on behalf of
                                www.journeytogethertravel.com users and customers.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>No Unlawful or Prohibited Use/Intellectual Property </Text>

                            <Text marginTop={1}>
                                You are granted a non-exclusive, non-transferable, revocable license to access and use
                                www.journeytogethertravel.com strictly in accordance with these terms of use. As a condition of
                                your use of the Site, you warrant to Journey that you will not use the Site for any purpose that is
                                unlawful or prohibited by these Terms. You may not use the Site in any manner which could
                                damage, disable, overburden, or impair the Site or interfere with any other party's use and
                                enjoyment of the Site. You may not obtain or attempt to obtain any materials or information
                                through any means not intentionally made available or provided for through the Site.
                            </Text>
                            <Text marginTop={1}>
                                All content included as part of the Service, such as text, graphics, logos, images, as well as the
                                compilation thereof, and any software used on the Site, is the property of Journey or its suppliers
                                and protected by copyright and other laws that protect intellectual property and proprietary rights.
                                You agree to observe and abide by all copyright and other proprietary notices, legends or other
                                restrictions contained in any such content and will not make any changes thereto.
                            </Text>
                            <Text marginTop={1}>
                                You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
                                derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
                                Journey content is not for resale. Your use of the Site does not entitle you to make any
                                unauthorized use of any protected content, and in particular you will not delete or alter any
                                proprietary rights or attribution notices in any content. You will use protected content solely for
                                your personal use, and will make no other use of the content without the express written
                                permission of Journey and the copyright owner. You agree that you do not acquire any ownership
                                rights in any protected content. We do not grant you any licenses, express or implied, to the
                                intellectual property of Journey or our licensors except as expressly authorized by these Terms.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Use of Communication Services </Text>

                            <Text marginTop={1}>
                                The Site may contain bulletin board services, chat areas, news groups, forums, communities,
                                personal web pages, calendars, and/or other message or communication facilities designed to
                                enable you to communicate with the public at large or with a group (collectively, "Communication
                                Services"). You agree to use the Communication Services only to post, send and receive messages
                                and material that are proper and related to the particular Communication Service.
                            </Text>
                            <Text marginTop={1}>
                                By way of example, and not as a limitation, you agree that when using a Communication Service,
                                you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as
                                rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material
                                or information; upload files that contain software or other material protected by intellectual
                                property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or
                                have received all necessary consents; upload files that contain viruses, corrupted files, or any other
                                similar software or programs that may damage the operation of another's computer; advertise or
                                offer to sell or buy any goods or services for any business purpose, unless such Communication
                                Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes
                                or chain letters; download any file posted by another user of a Communication Service that you
                                know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete
                                any author attributions, legal or other proper notices or proprietary designations or labels of the
                                origin or source of software or other material contained in a file that is uploaded; restrict or inhibit
                                any other user from using and enjoying the Communication Services; violate any code of conduct
                                or other guidelines which may be applicable for any particular Communication Service; harvest or
                                otherwise collect information about others, including e-mail addresses, without their consent;
                                violate any applicable laws or regulations.
                            </Text>
                            <Text marginTop={1}>
                                Journey has no obligation to monitor the Communication Services. However, Journey reserves the
                                right to review materials posted to a Communication Service and to remove any materials in its
                                sole discretion. Journey reserves the right to terminate your access to any or all of the
                                Communication Services at any time without notice for any reason whatsoever.
                            </Text>
                            <Text marginTop={1}>
                                Journey reserves the right at all times to disclose any information as necessary to satisfy any
                                applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to
                                remove any information or materials, in whole or in part, in Journey's sole discretion.
                            </Text>
                            <Text marginTop={1}>
                                Always use caution when giving out any personally identifying information about yourself or your
                                children in any Communication Service. Journey does not control or endorse the content,
                                messages or information found in any Communication Service and, therefore, Journey specifically
                                disclaims any liability with regard to the Communication Services and any actions resulting from
                                your participation in any Communication Service. Managers and hosts are not authorized Journey
                                spokespersons, and their views do not necessarily reflect those of Journey.
                            </Text>
                            <Text marginTop={1}>
                                Materials uploaded to a Communication Service may be subject to posted limitations on usage,
                                reproduction and/or dissemination. You are responsible for adhering to such limitations if you
                                upload the materials.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Materials Provided to www.journeytogethertravel.com or Posted on Any Journey Web
                                Page </Text>

                            <Text marginTop={1}>
                                Journey does not claim ownership of the materials you provide to www.journeytogethertravel.com
                                (including feedback and suggestions) or post, upload, input or submit to any Journey Site or our
                                associated services (collectively "Submissions"). However, by posting, uploading, inputting,
                                providing or submitting your Submission you are granting Journey, our affiliated companies and
                                necessary sublicensees permission to use your Submission in connection with the operation of their
                                Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly
                                display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish
                                your name in connection with your Submission.
                            </Text>
                            <Text marginTop={1}>
                                No compensation will be paid with respect to the use of your Submission, as provided herein.
                                Journey is under no obligation to post or use any Submission you may provide and may remove
                                any Submission at any time in Journey's sole discretion.
                            </Text>
                            <Text marginTop={1}>
                                By posting, uploading, inputting, providing or submitting your Submission you warrant and
                                represent that you own or otherwise control all of the rights to your Submission as described in this
                                section including, without limitation, all the rights necessary for you to provide, post, upload, input
                                or submit the Submissions.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Third Party Accounts</Text>

                            <Text marginTop={1}>
                                You will be able to connect your Journey account to third party accounts. By connecting your
                                Journey account to your third party account, you acknowledge and agree that you are consenting
                                to the continuous release of information about you to others (in accordance with your privacy
                                settings on those third party sites). If you do not want information about you to be shared in this
                                manner, do not use this feature.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>International Users </Text>

                            <Text marginTop={1}>
                                The Service is controlled, operated and administered by Journey from our offices within the USA.
                                If you access the Service from a location outside the USA, you are responsible for compliance
                                with all local laws. You agree that you will not use the Journey Content accessed through
                                www.journeytogethertravel.com in any country or in any manner prohibited by any applicable
                                laws, restrictions or regulations.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Indemnification</Text>

                            <Text marginTop={1}>
                                You agree to indemnify, defend and hold harmless Journey, its officers, directors, employees,
                                agents and third parties, for any losses, costs, liabilities and expenses (including reasonable
                                attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any
                                user postings made by you, your violation of any terms of this Agreement or your violation of any
                                rights of a third party, or your violation of any applicable laws, rules or regulations. Journey
                                reserves the right, at its own cost, to assume the exclusive defense and control of any matter
                                otherwise subject to indemnification by you, in which event you will fully cooperate with Journey in
                                asserting any available defenses.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Class Action Waiver</Text>

                            <Text marginTop={1}>
                                Any arbitration under these Terms and Conditions will take place on an individual basis; class
                                arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE
                                THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S
                                INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                                PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH
                                AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE
                                OTHER. Further, unless both you and Journey agree otherwise, the arbitrator may not consolidate
                                more than one person's claims, and may not otherwise preside over any form of a representative
                                or class proceeding.
                            </Text>


                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Liability Disclaimer </Text>

                            <Text marginTop={1}>
                                THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
                                AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
                                TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
                                INFORMATION HEREIN. JOURNEY TOGETHER TRAVEL AND/OR ITS SUPPLIERS
                                MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
                            </Text>

                            <Text marginTop={1}>
                                JOURNEY TOGETHER TRAVEL AND/OR ITS SUPPLIERS MAKE NO
                                REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
                                TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS,
                                SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY
                                PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL
                                SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                                GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF
                                ANY KIND. JOURNEY TOGETHER TRAVEL AND/OR ITS SUPPLIERS HEREBY
                                DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
                                INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
                                INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
                                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT.
                            </Text>

                            <Text marginTop={1}>
                                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                                SHALL JOURNEY TOGETHER TRAVEL AND/OR ITS SUPPLIERS BE LIABLE FOR
                                ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
                                DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
                                LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF
                                OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE,
                                WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
                                PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
                                INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
                                OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF
                                THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
                                LIABILITY OR OTHERWISE, EVEN IF JOURNEY TOGETHER TRAVEL OR ANY OF
                                ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
                                BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                                LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
                                THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
                                WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE,
                                YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Termination/Access Restriction </Text>

                            <Text marginTop={1}>
                                Journey reserves the right, in its sole discretion, to terminate your access to the Site and the related
                                services or any portion thereof at any time, without notice. To the maximum extent permitted by
                                law, this agreement is governed by the laws of the State of Texas and you hereby consent to the
                                exclusive jurisdiction and venue of courts in Texas in all disputes arising out of or relating to the use
                                of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all
                                provisions of these Terms, including, without limitation, this section.
                            </Text>
                            <Text marginTop={1}>
                                You agree that no joint venture, partnership, employment, or agency relationship exists between
                                you and Journey as a result of this agreement or use of the Site. Journey's performance of this
                                agreement is subject to existing laws and legal process, and nothing contained in this agreement is
                                in derogation of Journey's right to comply with governmental, court and law enforcement requests
                                or requirements relating to your use of the Site or information provided to or gathered by Journey
                                with respect to such use. If any part of this agreement is determined to be invalid or unenforceable
                                pursuant to applicable law including, but not limited to, the warranty disclaimers and liability
                                limitations set forth above, then the invalid or unenforceable provision will be deemed superseded
                                by a valid, enforceable provision that most closely matches the intent of the original provision and
                                the remainder of the agreement shall continue in effect.
                            </Text>
                            <Text marginTop={1}>
                                Unless otherwise specified herein, this agreement constitutes the entire agreement between the user
                                and Journey with respect to the Site and it supersedes all prior or contemporaneous
                                communications and proposals, whether electronic, oral or written, between the user and Journey
                                with respect to the Site. A printed version of this agreement and of any notice given in electronic
                                form shall be admissible in judicial or administrative proceedings based upon or relating to this
                                agreement to the same extent and subject to the same conditions as other business documents and
                                records originally generated and maintained in printed form. It is the express wish to the parties
                                that this agreement and all related documents be written in English.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> Changes to Terms</Text>

                            <Text marginTop={1}>
                                Journey reserves the right, in its sole discretion, to change the Terms under which
                                www.journeytogethertravel.com is offered. The most current version of the Terms will supersede
                                all previous versions. Journey encourages you to periodically review the Terms to stay informed of
                                our updates.
                            </Text>

                            <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}>Contact Us </Text>

                            <Text marginTop={1}>
                                Journey welcomes your questions or comments regarding the Terms:
                            </Text>

                            <Text marginTop={1}>
                                Journey Together Travel<br />
                                6434 Orchid Lane<br />
                                Dallas, Texas 75230
                            </Text>

                            <Text marginTop={1}>
                                Email Address:<br />
                                _________________
                            </Text>
                            <Text marginTop={1}>
                                Telephone number:<br />
                                214-499-8777
                            </Text>
                            <Text marginTop={1}>
                                Effective as of May 31, 2023
                            </Text>
                        </>
                    }

                    {/* 
                    <Text variant={"h6"} marginTop={1} style={{ fontWaight: 'bold' }}> </Text>

                    <Text marginTop={1}>

                    </Text> 
                    */}

                </Stack>
            </Stack>
        </Stack >
    );
}

