import { Divider, InputLabel, Stack, Dialog, DialogActions, DialogContent, Avatar, List } from "@mui/material";
import { useState, useEffect } from "react";
import MainContainer from "../../components/MainContainer";
import Text from "../../components/Text";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../util/colors";
import InputField from "../../components/InputField";
import CustomButton from "../../components/CustomButton";
import TermsAndPrivecy from "../../components/TermsAndPrivecy";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { feedBack, showError, logout, deletAccount, getFeedbackWithResp } from '../../store/actions/action';
import CircularProgress from '@mui/material/CircularProgress';
import ReactGA from "react-ga4";

// import {
//   Avatar, AvatarGroup, Button, IconButton, InputAdornment, useMediaQuery,
//   InputLabel, Stack, Dialog, DialogActions, DialogContent, MenuItem, useTheme
// } from "@mui/material";

export default function Settings() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let feedBackResp = useSelector((state) => state.reducer.feedBack);

  useEffect(() => {
    dispatch(getFeedbackWithResp())
    ReactGA.event({
      category: "Settings_Category",
      action: "Settings_Action",
      label: "Settings_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }, [])

  const [menu, setMenu] = useState([
    {
      id: 1,
      title: "Privacy Policy",
      isSelected: false,
    },
    {
      id: 2,
      title: "Terms and Conditions",
      isSelected: false,
    },
    {
      id: 3,
      title: "Support/Send Feedback",
      isSelected: false,
    },
    {
      id: 4,
      title: "Feedback Response",
      isSelected: false,
    },
    // {
    //   id: 4,
    //   title: "Invite Friend",
    //   isSelected: false,
    // },
    {
      id: 5,
      title: "Log Out",
      isSelected: false,
    },
    {
      id: 6,
      title: "Delete Account",
      isSelected: false,
    },
  ]);
  const [showContainer, setShowContainer] = useState(true);
  const [index, setIndex] = useState(0);
  let currentUser = useSelector((state) => state.reducer.user);
  let isError = useSelector((state) => state.reducer.isError);
  let isLoader = useSelector((state) => state.reducer.isLoader);
  let terms = useSelector((state) => state.reducer.terms);
  let privacy = useSelector((state) => state.reducer.privacy);

  const [settingsMenu, setsettingsMenu] = useState([]);


  const [email, setemail] = useState('');
  const [subject, setsubject] = useState('');
  const [name, setname] = useState('');
  const [msg, setmsg] = useState('');



  useEffect(() => {
    setemail(currentUser.email)
  }, [currentUser]);


  // console.log(menu, index, showContainer, "menu")

  const handleClick = (id) => {
    const a = menu.map((item) => {
      item.id === id ? (item.isSelected = true) : (item.isSelected = false);
      return item;
    });
    setMenu(a);
    setIndex(id - 1);
    setShowContainer(false);
  };

  const handleBack = () => {
    const a = menu.map((item) => {
      item.isSelected = false;
      return item;
    });
    setMenu(a);
    setIndex(0);
    setShowContainer(true);
  };

  const submit = () => {
    if (email !== '' && name !== '' && msg !== '') {
      let credentials = {
        email: email,
        subject: subject,
        name: name,
        message: msg,
      }
      dispatch(feedBack(credentials, navigate))
      setemail('')
      setname('')
      setmsg('')
      setsubject('')
    }
    else {
      dispatch(showError())
    }
  }


  useEffect(() => {
    setsettingsMenu(
      [
        //Privacy policy
        {
          value: (
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="center"
              alignItems={{ xs: "center", md: "flex-start" }}
            >
              <TermsAndPrivecy text={'Privacy Policys'} data={privacy} isdieApp={true} />
            </Stack>
          )
        },

        // Terms and condition
        {
          value: (<Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="center"
            alignItems={{ xs: "center", md: "flex-start" }}
          >
            <TermsAndPrivecy text={'Terms and Conditions'} data={terms} isdieApp={true} />
          </Stack>)
        },

        {
          value: (
            <Stack>
              <Stack spacing={1} width={{ xs: "100%", md: "100%" }}>

                <InputLabel sx={{ fontSize: "13px" }} style={{ display: 'flex', flexDirection: 'row' }}>
                  Email Address
                  {
                    isError && email == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                  }
                </InputLabel>
                <InputField
                  size="small"
                  value={email}
                // onChange={(e) => setemail(e.target.value)}
                />
              </Stack>

              <Stack spacing={1} marginTop={1} width={{ xs: "100%", md: "100%" }}>
                <InputLabel sx={{ fontSize: "13px" }} style={{ display: 'flex', flexDirection: 'row' }}>
                  Subject
                  {
                    isError && subject == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                  }
                </InputLabel>
                <InputField
                  size="small"
                  value={subject}
                  onChange={(e) => setsubject(e.target.value)}
                />
              </Stack>
              <Stack spacing={1} marginTop={1} width={{ xs: "100%", md: "100%" }}>
                <InputLabel sx={{ fontSize: "13px" }} style={{ display: 'flex', flexDirection: 'row' }}>
                  Name
                  {
                    isError && name == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                  }
                </InputLabel>

                <InputField
                  size="small"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </Stack>
              <Stack mt={{ xs: 2, md: 3 }} spacing={1}>
                <InputLabel sx={{ fontSize: "13px" }} style={{ display: 'flex', flexDirection: 'row' }}>
                  Message
                  {
                    isError && name == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                  }
                </InputLabel>
                <InputField
                  value={msg}
                  onChange={(e) => setmsg(e.target.value)}
                  multiline
                  rows={3}
                  maxRows={5}
                />
              </Stack>
              <Stack mt={8} alignItems={"center"}>
                <CustomButton
                  sx={{
                    color: "white",
                    width: { xs: "80%", md: 300 },
                    height: 45,
                    fontSize: "12px",
                  }}
                  onClick={() => { submit() }}
                >
                  {
                    isLoader && <CircularProgress style={{ 'color': 'white' }} size="1rem" />
                  }
                  {
                    !isLoader && <Text color="white" fontWeight="bold" fontSize="20px">
                      Submit
                    </Text>
                  }

                </CustomButton>
              </Stack>
            </Stack>
          ),
        },
        {
          value:
            <>

              {
                feedBackResp != 0 && feedBackResp.map((key, index) => {
                  console.log(key, "InsideMAP")
                  return (
                    <>
                      <Stack mt={-6}
                        sx={{
                          "::-webkit-scrollbar": { display: "none" },
                        }}
                        md={{
                          "::-webkit-scrollbar": { display: "none" },
                        }}
                        style={{ overflow: 'scroll', }}
                      >
                        <List
                          style={{
                            marginTop: 50,
                            height: 60,
                            cursor: "pointer",
                          }}
                        >
                          <Stack direction={'row'} paddingX={2} justifyContent={'flex-start'}>
                            <Avatar
                              alt="Img"
                              src={currentUser?.profile_picture}
                              sx={{
                                height: { xs: 40, md: 40 },
                                width: { xs: 40, md: 40 },
                              }}
                            />
                            <Stack >
                              <Text style={{ marginLeft: 10 }}>{currentUser.name}</Text>
                              <Text style={{ marginLeft: 10, fontSize: 12 }}>{currentUser.email}</Text>
                            </Stack>
                          </Stack>
                        </List>
                        <Text style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 20 }}>{key.subject}</Text>
                        <Text style={{ marginLeft: 10, fontSize: 12 }}>{key.message}</Text>
                      </Stack>

                      <Stack mt={-3}>
                        <List
                          style={{
                            marginTop: 50,
                            height: 60,
                            cursor: "pointer",
                          }}
                        >
                          <Stack direction={'row'} paddingX={2} justifyContent={'flex-start'}>
                            <Avatar
                              alt="Img"
                              src={null}
                              sx={{
                                height: { xs: 40, md: 40 },
                                width: { xs: 40, md: 40 },
                              }}
                            />
                            <Stack mt={1}>
                              <Text style={{ marginLeft: 10 }}>{"Support"}</Text>
                              {/* <Text style={{ marginLeft: 10, fontSize: 12 }}>{currentUser.email}</Text> */}
                            </Stack>
                          </Stack>
                        </List>
                        <Text style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 20 }}>{key.response_subject}</Text>
                        <Text style={{ marginLeft: 10, fontSize: 12 }}>{key.response_message}</Text>
                      </Stack>
                      <hr />
                    </>
                  )
                })
              }

            </>
        },
        {
          value: (
            <Stack
              justifyContent="center"
              alignItems='center'
            >
              <InputLabel sx={{ fontSize: "20px" }} style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
                Are you sure you want to<br />
                logout?
              </InputLabel>

              <Stack marginTop={5}>
                <CustomButton
                  sx={{
                    color: "white",
                    width: { xs: "80%", md: 300 },
                    height: 45,
                    fontSize: "12px",

                  }}
                  onClick={() => { dispatch(logout(navigate)) }}
                >

                  {
                    isLoader && <CircularProgress style={{ 'color': 'white' }} size="1rem" />
                  }
                  {
                    !isLoader && <Text color="white" fontWeight="bold" fontSize="15px">
                      Yes
                    </Text>
                  }
                </CustomButton>
              </Stack>

              <Stack marginTop={2}>
                <CustomButton
                  border="1px solid black"
                  backColor='white'
                  sx={{
                    color: "white",
                    width: { xs: "80%", md: 300 },
                    height: 45,
                    fontSize: "12px",

                  }}
                  onClick={() => { setIndex(0) }}
                >
                  <Text color="black" fontWeight="bold" fontSize="15px">
                    No
                  </Text>
                </CustomButton>
              </Stack>
            </Stack>

          )
        },
        {
          value: (
            <Stack
              justifyContent="center"
              alignItems='center'
            >
              <InputLabel sx={{ fontSize: "20px" }} style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
                Are you sure you want to<br />
                delete your account?
              </InputLabel>

              <Stack marginTop={5}>
                <CustomButton
                  sx={{
                    color: "white",
                    width: { xs: "80%", md: 300 },
                    height: 45,
                    fontSize: "12px",

                  }}
                  onClick={() => { dispatch(deletAccount(navigate)) }}
                >

                  {
                    isLoader && <CircularProgress style={{ 'color': 'white' }} size="1rem" />
                  }
                  {
                    !isLoader && <Text color="white" fontWeight="bold" fontSize="15px">
                      Yes
                    </Text>
                  }
                </CustomButton>
              </Stack>

              <Stack marginTop={2}>
                <CustomButton
                  border="1px solid black"
                  backColor='white'
                  sx={{
                    color: "white",
                    width: { xs: "80%", md: 300 },
                    height: 45,
                    fontSize: "12px",

                  }}
                  onClick={() => { setIndex(0) }}
                >
                  <Text color="black" fontWeight="bold" fontSize="15px">
                    No
                  </Text>
                </CustomButton>
              </Stack>
            </Stack>
          )

        },
      ]
    )
  }, [feedBackResp, email, subject, name, msg]);


  return (
    <MainContainer
      // style={{ paddingTop: 10 }}
      handleBack={handleBack}
      variant={2}
      showBoxTwo={true}
      showContainer={showContainer}

      Box1={
        <Stack marginTop={5}>
          {menu.map((item, index) => (
            <Stack
              key={index}
              sx={{
                cursor: "pointer",
                backgroundColor: item.isSelected && colors.background_color,
              }}
            >
              {index === 0 ? null : (
                <Divider
                  sx={{
                    width: { xs: "85%", sm: "90%", md: "85%" },
                    marginLeft: 3.5,
                  }}
                />
              )}

              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                width={{ xs: "85%", sm: "90%", md: "85%" }}
                marginY={2}
                ml={3.5}
                onClick={() => handleClick(item.id)}
              >
                <Text fontWeight="bold" fontSize="15px">
                  {item.title}
                </Text>
                <ArrowForwardIosIcon fontSize="small" />
              </Stack>
            </Stack>
          ))}
        </Stack>
      }
      Box2={
        <Stack marginX={{ xs: 2, md: 8 }} marginTop={{ xs: 0, md: 5 }}>
          {settingsMenu.length && settingsMenu[index].value}
        </Stack>
      }
    />
  );
}
