import { Stack, useTheme, } from "@mui/material";
import { useState } from "react";
import CustomCarousel from "../../components/CarouselComponent";
import CustomButton from "../../components/CustomButton";
import Text from "../../components/Text";
import Image from "mui-image";
import { Logo } from "../../assets";
import { useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { useDispatch, useSelector } from 'react-redux';
import { verifyCode, showError } from '../../store/actions/action';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

export const SixDigit = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    let isError = useSelector((state) => state.reducer.isError);
    let isLoader = useSelector((state) => state.reducer.isLoader);
    const [sixDigit, setsixDigit] = useState('');

    const submit = () => {
        let credentials = {
            token: sixDigit,
        }
        sixDigit.length >= 6 && dispatch(verifyCode(credentials, navigate))
        if (sixDigit.length < 6) {
            toast.error('Code should be minimum 6 character');
        }
    }

    return (
        <Stack
            direction={{ xs: "column", md: "row" }}
            width="100vw"
            alignItems={"center"}
            justifyContent="center"
            height={"100vh"}
        >
            <Stack
                justifyContent="center"
                display={{ xs: "none", md: "flex" }}
                position="relative"
                width={{ xs: "100vw", md: "65vw" }}
            >
                <CustomCarousel />
                <Stack position="absolute" zIndex={10} alignSelf="center" top={"15%"}>
                    <Image src={Logo} fit="contain" />
                </Stack>
                {/* <Text
                    position="absolute"
                    zIndex={10}
                    top={"50%"}
                    textAlign="center"
                    fontSize="20px"
                    width={400}
                    sx={{ alignSelf: "center" }}
                >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque,
                    officiis.
                </Text> */}
            </Stack>

            <Stack
                sx={{
                    width: {
                        xs: "80vw",
                        md: "35vw",
                    },
                    paddingLeft: { xs: 0, md: 8 },
                }}
                marginRight={{ xs: 0, md: 14 }}
                marginTop={{ xs: 0, md: 8 }}
            >
                <Stack
                    display={{ xs: "flex", md: "none" }}
                    marginBottom={7}
                    marginTop={-7}
                >
                    <Image src={Logo} style={{ width: "50vw" }} />
                </Stack>

                <Stack width="100%" style={{ paddingBottom: 100 }}>
                    <Text fontWeight={"bold"} variant={"h6"}>Password Reset</Text>
                    <Text variant={"h6"}>Enter the token to reset your password!</Text>
                    <Stack mt={{ xs: 4, md: 7 }} spacing={{ xs: 2, md: 1 }}>
                        <Stack spacing={1}>
                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                                <VerificationInput onChange={(e) => { setsixDigit(e) }} />
                            </div>
                        </Stack>
                    </Stack>
                    <Stack>
                        <CustomButton
                            variant="contained"
                            sx={{
                                height: { xs: "40px", md: "60px" },
                                marginTop: {
                                    xs: 2,
                                    sm: 3,
                                },
                                marginTop: 10
                            }}

                            onClick={() => submit()}
                        >
                            {
                                isLoader && <CircularProgress style={{ 'color': 'white' }} size="1rem" />
                            }
                            {
                                !isLoader &&
                                <Text color="white" fontWeight="bold" fontSize="20px">
                                    Reset Password
                                </Text>
                            }
                        </CustomButton>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
