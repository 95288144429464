import { Card, Stack, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState, useRef } from "react"
import { Loc1, Loc2, Loc3, Loc4 } from "../../assets"
import CalculatorEvent from "../../components/CalculatorEvent"
import MainContainer from "../../components/MainContainer"
import { colors } from "../../util/colors"
import CalculatorTab from "../../components/CalculatorTab"
import Text from "../../components/Text"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { useDispatch, useSelector } from "react-redux"
import ReactGA from "react-ga4"
import AdSense from "react-adsense"

export default function Calculator() {
  let eventsGets = useSelector(state => state.reducer.events)

  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("sm"))
  const med = useMediaQuery(theme.breakpoints.down("md"))
  const [activeIndex, setActiveIndex] = useState(0)
  const [index, setIndex] = useState(0)
  const [showContainer, setShowContainer] = useState(true)

  const [events, setevents] = useState([])
  const [selectedEv, setselectedEv] = useState([])
  const [menu, setmenu] = useState([])

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    ReactGA.event({
      category: "Calculator_Category",
      action: "Calculator_Action",
      label: "Calculator_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  }, [])

  useEffect(() => {
    let propertyAddInEv = []
    let arrMenu = []
    for (let index = 0; index < eventsGets.length; index++) {
      const element = eventsGets[index]
      element.selected = false
      element.showId = index
      propertyAddInEv.push(element)
      let obj = {
        value: (
          <Stack marginX={6}>
            <CalculatorTab mobile={med ? true : false} selEve={element} />
          </Stack>
        )
      }
      arrMenu.push(obj)
    }
    setmenu(arrMenu)
    setevents(propertyAddInEv)
    setselectedEv([])
  }, [eventsGets])

  const handleClick = (id, showId, indexArr) => {
    let updated = []
    let selectedEv = []
    for (let index = 0; index < events.length; index++) {
      const element = events[index]
      if (indexArr === index) {
        element.selected = true
        selectedEv.push(element)
      } else {
        element.selected = false
      }
      updated.push(element)
    }
    setselectedEv(selectedEv)
    setevents(updated)
    setIndex(showId)
    small ? setShowContainer(true) : setShowContainer(false)
  }

  const handleBack = () => {
    const a = events?.map(item => {
      item.selected = false
      return item
    })
    setevents(a)
    setIndex(0)
    small ? setShowContainer(false) : setShowContainer(true)
  }

  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    if (med) {
      setShowContainer(true)
    }
  }, [med])

  return (
    <Stack sx={{ background: colors.background_color }} height="100vh">
      <MainContainer
        showBoxTwo={true}
        handleBack={handleBack}
        variant={6}
        showContainer={showContainer}
        Box1={
          <Stack mt={5} spacing={5}>
            <Text fontSize="15px" fontWeight="bold" marginLeft={4}>
              Select Event
            </Text>

            <Stack>
              {events?.map((item, count) => {
                // console.log(item, "INSIDE_MAP")
                return (
                  <Card
                    sx={{
                      paddingX: { xs: 2, sm: 4 },
                      marginX: { xs: 2, sm: 4 },
                      marginBottom: { xs: showMenu ? -2 : 0, md: 0 },
                      cursor: "pointer",
                      backgroundColor: {
                        sm: item.selected && colors.background_color
                      },
                      boxShadow: { md: "none" }
                    }}
                    key={count}
                  >
                    <Stack
                      display={{
                        xs: count === 0 && showMenu ? "flex" : "none",
                        md: "none"
                      }}
                      marginX={{ xs: -2, sm: -4 }}
                      height={50}
                    >
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingX: 2
                        }}
                      >
                        <Text fontWeight="bold" fontSize="13px">
                          Select Event
                        </Text>
                        <ArrowDropDownIcon />
                      </Card>
                    </Stack>
                    <Stack
                      paddingY={{ xs: showMenu ? 1 : 0, md: 0 }}
                      marginLeft={{ xs: showMenu ? -1 : 0, md: 0 }}
                      marginBottom={{ xs: showMenu ? 2 : 1, md: 1 }}
                      marginTop={{ xs: showMenu ? 0 : 1, md: 1 }}
                      display={{
                        xs: showMenu
                          ? "flex"
                          : activeIndex === count
                          ? "flex"
                          : "none",
                        md: "flex"
                      }}
                    >
                      <Stack
                        onClick={() => {
                          if (med) {
                            setShowMenu(!showMenu)
                            setActiveIndex(count)
                            handleClick(item.id, item.showId, count)
                          } else {
                            handleClick(item.id, item.showId, count)
                          }
                        }}
                      >
                        <CalculatorEvent
                          check={item.selected}
                          onChange={() => setActiveIndex(count)}
                          showMenu={showMenu}
                          setShowMenu={setShowMenu}
                          img={item.pictures[0]?.picture}
                          title={item.title}
                          location={item.location}
                          date={item.start}
                          tag="Creator"
                        />
                      </Stack>
                    </Stack>
                  </Card>
                )
              })}
              <Stack
                display={{ xs: "flex", md: "none" }}
                marginTop={showMenu ? 3 : 0}
                marginX={6}
              >
                <CalculatorTab
                  mobile={med ? true : false}
                  selEve={selectedEv[0]}
                />
              </Stack>
            </Stack>
          </Stack>
        }
        Box2={
          <Stack
            display={{ xs: "none", sm: "flex" }}
            marginX={{ xs: 2, md: 0 }}
            marginTop={{ xs: 2, md: 5 }}
          >
            {!!menu.length && menu[index].value}
          </Stack>
        }
      />
      <AdSense.Google
        client="ca-pub-6951250899483400"
        slot="1797613779"
        style={{ display: "block" }}
        format="auto"
        responsive="true"
        layoutKey="-gw-1+2a-9x+5c"
      />
    </Stack>
  )
}
