import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from "react-router-dom";
import {
    CardMedia,
    IconButton,
    InputLabel,
    MenuItem,
    Stack,
    useMediaQuery,
    useTheme,
    Dialog, DialogActions, DialogContent,
} from "@mui/material";
import MainContainer from "../../components/MainContainer";
import Lock1 from "../../assets/profileBackground.png";
import noPhoto from "../../assets/noPhoto.jpg";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CloseIcon from '@mui/icons-material/Close';
import Text from "../../components/Text";
import { Loc1 } from "../../assets";
import InputField from "../../components/InputField";
import { allLetter } from '../../services/validationRegex';
import { updateProfile, getChats, getPvtChats, setChannelForSingleChat } from '../../store/actions/action'
import moment from "moment";
import CustomButton from "../../components/CustomButton";
import Image from "mui-image";
import { Chat1 } from "../../assets/sidebarIcons";
import ShareIcon from '@mui/icons-material/Share';
import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    TwitterShareButton,
    TwitterIcon,
    EmailIcon,
    WhatsappIcon,
    FacebookIcon,

} from 'react-share';
const NoImage = require('./../../assets/NoImage1.png')

export default function ProfileSingleChat() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { state } = useLocation();
    // console.log(state?.participants, state?.channel, 'participants')
    const [showContainer, setShowContainer] = useState(true);
    const [showProfile, setShowProfile] = useState(false);
    const [backURL, setBackURL] = useState(Lock1);

    let currentUser = useSelector((state) => state.reducer.user);
    const [userName, setuserName] = useState('');
    const [bio, setbio] = useState('');
    const [likes, setlikes] = useState('');
    const [dislikes, setdislikes] = useState('');
    const [favourites, setfavourites] = useState('');
    const [dob, setdob] = useState('');
    const [gender, setgender] = useState('Female');
    const [phone, setphone] = useState('');
    const [address, setaddress] = useState('');
    const [country, setcountry] = useState('');
    const [profileImg, setprofileImg] = useState(noPhoto);
    const [images, setImages] = useState(null);
    const [showModal, setshowModal] = useState(false);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (state?.participants) {
            setuserName(state?.participants.name)
            setbio(state?.participants.bio)
            setlikes(state?.participants.likes)
            setdislikes(state?.participants.dislikes)
            setfavourites(state?.participants.favourites)
            setdob(state?.participants.birthdate)
            setgender(state?.participants.gender)
            setphone(state?.participants.zip_code)
            setaddress(state?.participants.address)
            setcountry(state?.participants.country)
            setprofileImg(state?.participants?.profile_picture)
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    const genders = [
        {
            value: "Male",
            label: "Male",
        },
        {
            value: "Female",
            label: "Female",
        },
    ];

    const space = 1;

    function onImageChange(e) {
        setprofileImg(URL.createObjectURL(e.target.files[0]));
        setImages(e.target.files[0]);
    }

    function onBackChange(e) {
        setBackURL(URL.createObjectURL(e.target.files[0]));
    }

    function handleBack() {
        setShowContainer(true);
    }

    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));

    const submit = () => {
        let form_data = new FormData();
        form_data.append('name', userName);
        form_data.append('bio', bio);
        form_data.append('likes', likes);
        form_data.append('dislikes', dislikes);
        form_data.append('favourites', favourites);
        form_data.append('birthdate', moment(dob).format('YYYY-MM-DD'));
        form_data.append('gender', gender);
        form_data.append('address', address);
        form_data.append('zip_code', phone);
        form_data.append('country', country);
        images != null && form_data.append('profile_picture', images);
        dispatch(updateProfile(form_data, navigate))
        setShowProfile(false)
    }

    const xs = useMediaQuery(theme.breakpoints.down("md"));

    const startSingleChat = () => {
        console.log(state, "startSingleChat")
        dispatch(setChannelForSingleChat(state?.channel))
        dispatch(getPvtChats(state?.participants.email))
        navigate("/chat")
    }
    // const shareUrl = 'https://www.crowdbotics.com/';
    const shareUrl = 'https://annhurlay-demo.web.app/';

    return (
        <Stack>
            <MainContainer
                showBoxTwo={true}
                handleBack={handleBack}
                showContainer={showContainer}
                variant={4}
                Box1={
                    <Stack
                        sx={{
                            backgroundImage: `url(${backURL})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                        width="100%"
                        height="100%"
                        position={"relative"}
                    >
                        <Stack position={"absolute"} right={7} top={10}>

                            <Stack spacing={1}>
                                <Dialog
                                    open={showModal}
                                    onClose={() => setshowModal(false)}
                                    fullWidth
                                    maxWidth="sm"
                                >
                                    <DialogContent>
                                        <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                                            <FacebookShareButton
                                                url={shareUrl}
                                            // quote={'Title or jo bhi aapko likhna ho'}
                                            // hashtag={'#portfolio...'}
                                            >
                                                <FacebookIcon size={40} round={true} />
                                            </FacebookShareButton>

                                            <WhatsappShareButton
                                                url={shareUrl}
                                            // quote={'Title or jo bhi aapko likhna ho'}
                                            // hashtag={'#portfolio...'}
                                            >
                                                <WhatsappIcon size={40} round={true} />
                                            </WhatsappShareButton>
                                            <EmailShareButton
                                                url={shareUrl}
                                            // quote={'Title or jo bhi aapko likhna ho'}
                                            // hashtag={'#portfolio...'}
                                            >
                                                <EmailIcon size={40} round={true} />
                                            </EmailShareButton>

                                            <TwitterShareButton
                                                url={shareUrl}
                                            // quote={'Title or jo bhi aapko likhna ho'}
                                            // hashtag={'#portfolio...'}
                                            >
                                                <TwitterIcon size={40} round={true} />
                                            </TwitterShareButton>
                                        </Stack>
                                    </DialogContent>

                                    <DialogActions>
                                        <CustomButton
                                            sx={{ color: "white" }}
                                            onClick={() => { setshowModal(false) }}
                                        >
                                            OK
                                        </CustomButton>

                                    </DialogActions>
                                </Dialog>
                            </Stack>


                            <IconButton
                                onClick={() => {
                                    // setShowProfile(!showProfile);
                                    setShowContainer(false);
                                }}
                            >

                                <Stack
                                    sx={{
                                        // background: "white",
                                        borderRadius: 2,
                                        width: xs ? 30 : 40,
                                        height: xs ? 30 : 40,
                                        cursor: "pointer",
                                    }}
                                    alignItems="center"
                                    justifyContent={"center"}
                                    direction={"row"}
                                    // onClick={() => { navigate("/chat", { state: { channel: state?.channel, title: state?.participants.name || state?.participants.email, email: state?.participants.email, private: true } }) }}
                                    onClick={() => { startSingleChat() }}
                                >
                                    <Image
                                        src={Chat1}
                                        sx={{

                                            // filter: "grayscale(100%) brightness(0%)",
                                            imageRendering: "crisp-edges",
                                        }}
                                        width={20}
                                        height={20}
                                    />
                                </Stack>


                                <ShareIcon onClick={() => { setshowModal(true) }}
                                    fontSize={md ? "medium" : "medium"}
                                    sx={{ color: "white" }}
                                />


                                {/* {
                                    !showProfile && <DriveFileRenameOutlineOutlinedIcon
                                        fontSize={md ? "large" : "medium"}
                                        sx={{ color: "white" }}
                                    />
                                }
                                {
                                    showProfile && <CloseIcon
                                        fontSize={md ? "large" : "medium"}
                                        sx={{ color: "white" }}
                                    />
                                } */}

                            </IconButton>
                        </Stack>
                        <Stack
                            width={"70%"}
                            marginTop={"50%"}
                            alignSelf="center"
                            marginBottom={8}
                        >
                            <Stack
                                sx={{
                                    backgroundColor: "black",
                                    color: "white",
                                    height: { xs: 470, md: 420 },
                                    width: "100%",
                                    borderTopLeftRadius: 50,
                                    borderTopRightRadius: 50,
                                }}
                            >
                                <Stack marginX={3}>
                                    <Stack
                                        marginTop={-2}
                                        direction={{ xs: "column", md: "row" }}
                                        alignItems={"center"}
                                        spacing={2}
                                    >
                                        <Stack
                                            sx={{
                                                border: "8px solid white",
                                                borderRadius: 20,
                                            }}
                                            alignSelf={{ xs: "center", md: "start" }}
                                        >
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    width: 60,
                                                    height: 60,
                                                    borderRadius: 16,
                                                }}
                                                image={profileImg ? profileImg : NoImage}
                                                alt="Profile"
                                            />
                                        </Stack>
                                        <Text style={{ marginTop: "5px" }}>{userName}</Text>
                                    </Stack>

                                    <Stack marginTop={5} spacing={1} style={{ 'white-space': 'pre-wrap', 'overflow-wrap': 'break-word' }}>
                                        <Text fontSize="13px">About Me</Text>
                                        <Text fontSize="11px" color="silver">
                                            {bio}
                                        </Text>
                                    </Stack>

                                    <Stack marginTop={2}>
                                        <Stack direction={"row"} paddingTop={1}>
                                            <Stack flexGrow={1}>
                                                <Text fontSize="13px">Likes</Text>
                                            </Stack>
                                            <Stack width="45%" alignItems={"flex-start"}>
                                                <Text fontSize="10px" color="silver">
                                                    {likes}
                                                </Text>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"row"} paddingTop={1}>
                                            <Stack flexGrow={1}>
                                                <Text fontSize="13px">Dislikes</Text>
                                            </Stack>
                                            <Stack width="45%" alignItems={"flex-start"}>
                                                <Text fontSize="10px" color="silver">
                                                    {dislikes}
                                                </Text>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"row"} paddingTop={1}>
                                            <Stack flexGrow={1}>
                                                <Text fontSize="13px">Favorite Phrase</Text>
                                            </Stack>
                                            <Stack width="45%" alignItems={"flex-start"}>
                                                <Text fontSize="10px" color="silver">
                                                    {favourites}
                                                </Text>
                                            </Stack>
                                        </Stack>
                                        <Stack direction={"row"} paddingTop={1}>
                                            <Stack flexGrow={1}>
                                                <Text fontSize="13px">Date of Birth</Text>
                                            </Stack>
                                            <Stack width="45%" alignItems={"flex-start"}>
                                                <Text fontSize="10px" color="silver">
                                                    {dob}
                                                </Text>
                                            </Stack>
                                        </Stack>

                                        <Stack direction={"row"} paddingTop={1}>
                                            <Stack flexGrow={1}>
                                                <Text fontSize="13px">Gender</Text>
                                            </Stack>
                                            <Stack width="45%" alignItems={"flex-start"}>
                                                <Text fontSize="10px" color="silver">
                                                    {gender}
                                                </Text>
                                            </Stack>
                                        </Stack>

                                        <Stack direction={"row"} paddingTop={1}>
                                            <Stack flexGrow={1}>
                                                <Text fontSize="13px">Address</Text>
                                            </Stack>
                                            <Stack width="45%" alignItems={"flex-start"}>
                                                <Text fontSize="10px" color="silver">
                                                    {address}
                                                </Text>
                                            </Stack>
                                        </Stack>

                                        <Stack direction={"row"} paddingTop={1}>
                                            <Stack flexGrow={1}>
                                                <Text fontSize="13px">Country</Text>
                                            </Stack>
                                            <Stack width="45%" alignItems={"flex-start"}>
                                                <Text fontSize="10px" color="silver">
                                                    {country}
                                                </Text>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                }
                Box2={
                    <Stack
                        width={{ xs: "80%", sm: "70%", md: "65%" }}
                        display={showProfile ? "flex" : "none"}
                        marginLeft={{ xs: 5, sm: 10 }}
                        marginTop={5}
                        paddingBottom={5}
                    >
                        <Stack marginBottom={4}>
                            <Text fontSize="20px" fontWeight="bold">
                                Edit Profile
                            </Text>
                        </Stack>
                        <Stack
                            direction={"row"}
                            // justifyContent={"space-between"}
                            justifyContent={"center"}
                            sx={{ color: "grey" }}
                            spacing={{ xs: 2, md: 0 }}
                        >
                            <Stack alignItems={"center"} spacing={2}>
                                <Text>Photo</Text>
                                <Stack position={"relative"}>
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: 70,
                                            height: 70,
                                            borderRadius: 16,
                                        }}
                                        image={profileImg}
                                        alt="Paella dish"
                                    />
                                    <label
                                        style={{
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: 3,
                                            right: 2,
                                            width: 25,
                                            height: 25,
                                            backgroundColor: "white",
                                            borderRadius: 16,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
                                        <input
                                            hidden
                                            type="file"
                                            accept="image/*"
                                            onChange={onImageChange}
                                        />
                                    </label>
                                </Stack>
                            </Stack>
                            {/* <Stack spacing={2} alignItems={{ xs: "center", md: "flex-end" }}>
                <Text>Background Photo</Text>
                <Stack position={"relative"} width={70} height={70}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 16,
                    }}
                    image={backURL}
                    alt="Paella dish"
                  />
                  <label
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: 3,
                      right: 2,
                      width: 25,
                      height: 25,
                      backgroundColor: "white",
                      borderRadius: 16,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={onBackChange}
                    />
                  </label>
                </Stack>
              </Stack> */}
                        </Stack>

                        <Stack marginTop={4} spacing={2}>
                            <Stack spacing={space}>
                                <InputLabel>Full Name</InputLabel>
                                <InputField
                                    value={userName}
                                    onChange={(e) => { allLetter(e.target.value) && e.target.value.length < 20 && setuserName(e.target.value) }}
                                    placeholder="Full Name"
                                    size="small"
                                />
                            </Stack>
                            <Stack spacing={space}>
                                <InputLabel>Likes</InputLabel>
                                <InputField
                                    value={likes}
                                    onChange={(e) => { e.target.value.length < 25 && setlikes(e.target.value) }}
                                    placeholder="Likes"
                                    size="small"
                                />
                            </Stack>
                            <Stack spacing={space}>
                                <InputLabel>Dislikes</InputLabel>
                                <InputField
                                    value={dislikes}
                                    onChange={(e) => e.target.value.length < 25 && setdislikes(e.target.value)}
                                    placeholder="Dislikes"
                                    size="small"
                                />
                            </Stack>
                            <Stack spacing={space}>
                                <InputLabel>Favorite Phrase</InputLabel>
                                <InputField
                                    value={favourites}
                                    onChange={(e) => e.target.value.length < 25 && setfavourites(e.target.value)}
                                    placeholder="Favorite Phrase"
                                    size="small"
                                />
                            </Stack>

                            <Stack spacing={space}>
                                <InputLabel>Gender</InputLabel>
                                <InputField
                                    value={gender}
                                    onChange={(e) => setgender(e.target.value)}
                                    size="small"
                                    select
                                    defaultValue={"Female"}
                                >
                                    {genders.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </InputField>
                            </Stack>
                            <Stack spacing={space}>
                                <InputLabel>Phone Number</InputLabel>
                                <InputField
                                    type="number"
                                    value={phone}
                                    onChange={(e) => { e.target.value.length < 10 && setphone(e.target.value) }}
                                    size="small"
                                    placeholder={"Phone Number"}
                                />
                            </Stack>
                            <Stack spacing={space}>
                                <InputLabel>Date of Birth</InputLabel>
                                <InputField
                                    value={dob}
                                    onChange={(e) => setdob(e.target.value)}
                                    inputProps={{ max: moment(new Date()).format('YYYY-MM-DD') }}
                                    type="date"
                                    size="small"
                                />
                            </Stack>
                            <Stack spacing={space}>
                                <InputLabel>Address</InputLabel>
                                <InputField
                                    value={address}
                                    onChange={(e) => { e.target.value.length < 20 && setaddress(e.target.value) }}
                                    size="small"
                                    placeholder={"Address"}
                                />
                            </Stack>
                            <Stack spacing={space}>
                                <InputLabel>Country</InputLabel>
                                <InputField
                                    value={country}
                                    onChange={(e) => { e.target.value.length < 20 && setcountry(e.target.value) }}
                                    size="small"
                                    placeholder={"USA"}
                                />
                            </Stack>
                            <Stack spacing={space}>
                                <InputLabel>About me</InputLabel>
                                <InputField
                                    value={bio}
                                    onChange={(e) => { allLetter(e.target.value) && e.target.value.length < 135 && setbio(e.target.value) }}
                                    multiline
                                    placeholder={'About me'}
                                    rows={4}
                                    max={5}
                                />
                            </Stack>

                            <Stack paddingTop={1}>
                                <CustomButton
                                    variant="contained"
                                    sx={{
                                        height: "60px",
                                        width: { xs: "100%", sm: "70%", md: "100%" },
                                        alignSelf: "center",
                                    }}
                                    onClick={() => submit()}
                                >
                                    <Text color="white" fontWeight="bold" fontSize="15px">
                                        Save
                                    </Text>
                                </CustomButton>
                            </Stack>
                        </Stack>
                    </Stack>
                }
            />
        </Stack>
    );
}
