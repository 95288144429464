import { Avatar, Stack } from "@mui/material";
import React from "react";
import CustomCheckbox from "./CustomCheckbox";
import Text from "./Text";

export default function MemberList({ check, onChange, index, name, image }) {
  return (
    <Stack direction={"row"} alignItems="center">
      <CustomCheckbox checked={check} onChange={onChange} />
      <Avatar
        src={image ? image : null}
        sx={{
          height: { xs: 30, md: 40 },
          width: { xs: 30, md: 40 },
          marginRight: 2,
          marginLeft: 2
        }}
      />
      <Text>{name}</Text>
    </Stack>
  );
}
