import {
  Stack,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  TextField,
  InputLabel,
  IconButton,
  AvatarGroup,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import MainContainer from "../../components/MainContainer";
import Text from "../../components/Text";
import SearchIcon from "@mui/icons-material/Search";
import { colors } from "../../util/colors";
import { useNavigate, useLocation } from "react-router-dom";
import { getFeedback, deleteFeedback, replyFeedback, showError } from '../../store/actions/action'
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import Image from "mui-image";
import { Edit, Delete, DeleteIcon, ReplyIcon } from "../../assets";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import ReplyIcon from '@mui/icons-material/Reply';
import InputField from "../../components/InputField";
import CustomButton from "../../components/CustomButton";
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';
import ReactGA from "react-ga4";

export default function FeedBack() {
  const [showContainer, setShowContainer] = useState(true);
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch()
  const bottomRef = useRef(null);
  let currentUser = useSelector((state) => state.reducer.user);
  let isError = useSelector((state) => state.reducer.isError);
  let isLoader = useSelector((state) => state.reducer.isLoader);
  const [menu, setmenu] = useState([]);

  let feedBack = useSelector((state) => state.reducer.feedBack);
  const [inboxFeedBack, setinboxFeedBack] = useState([]);

  useEffect(() => {
    dispatch(getFeedback())
  }, [])

  useEffect(() => {
    let propertyAddIn1to1C = []
    for (let index = 0; index < feedBack.length; index++) {
      const element = feedBack[index];
      element.selected = false
      element.showId = index
      propertyAddIn1to1C.push(element)
    }
    setinboxFeedBack(propertyAddIn1to1C)
  }, [feedBack])

  const submit = (msg, img) => {
    // console.log(img, 'msgmsgmsg')
    // let form_data = new FormData();
    // let data = {
    //   content: msg,
    //   channel: selChannelId ? selChannelId : pvtChannelId
    // }
    // form_data.append('content', msg);
    // form_data.append('channel', selChannelId ? selChannelId : pvtChannelId);
    // img && form_data.append('image', img);
    // dispatch(sendMsg(form_data, data.channel,))
  }

  const delFeedback = (key) => {
    dispatch(deleteFeedback(key.id))
    dispatch(getFeedback())
  }

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [menu]);

  useEffect(() => {
    ReactGA.event({
      category: "Admin_Feedback_Category",
      action: "Admin_Feedback_Action",
      label: "Admin_Feedback_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }, []);


  const [reply, setreply] = useState(false);
  const [subject, setsubject] = useState('');
  const [msg, setmsg] = useState('');



  const sendReply = (key) => {
    let data = {
      response_subject: subject,
      response_message: msg,
    }
    if (subject == '') {
      toast.error('Please type subject ');
    }
    if (msg == '') {
      toast.error("Please type message");
    }
    if (subject.length != 0 && subject != '' && msg.length != 0 && msg != '') {
      dispatch(replyFeedback(key.id, data))
      setsubject('')
      setmsg('')
      setreply(false)
    }
    dispatch(showError())
  }


  useEffect(() => {
    let arrMenu = []
    for (let index = 0; index < inboxFeedBack.length; index++) {
      const element = inboxFeedBack[index];
      let obj = {
        value:
          <Grid
            item
            xs={10}
            paddingTop={{ xs: 1, sm: 0 }}
            marginTop={{ xs: 5, md: 5 }}
          >
            <Grid marginX={4} style={{}} >

              <List
                style={{
                  cursor: "pointer",
                  borderBottom: `2px solid #EBEBEB`,
                }}
              >
                <Stack direction={'row'} paddingX={2} justifyContent={'space-between'}>
                  <Text>{element.timestamp ? moment(element.timestamp).calendar() : ''}</Text>
                  <Stack direction={'row'} spacing={1} >
                    {/* <ReplyIcon style={{ color: '#92929D', transform: [{ rotate: '90deg' }], }} onClick={() => { setreply(true) }} /> */}
                    {/* <DeleteOutlineIcon style={{ color: '#92929D' }} onClick={() => { delFeedback(element) }} /> */}
                    <Image
                      onClick={() => { setreply(true) }}
                      src={ReplyIcon}
                      width={20}
                      height={20}
                    />
                    <Image
                      onClick={() => { delFeedback(element) }}
                      src={DeleteIcon}
                      width={20}
                      height={20}
                    />
                  </Stack>
                </Stack>
              </List>


              <List
                style={{
                  marginTop: 10,
                  height: 60,
                  cursor: "pointer",
                }}
              >
                <Stack direction={'row'} paddingX={2} justifyContent={'flex-start'}>
                  <Avatar
                    alt="Img"
                    src={null}
                    sx={{
                      height: { xs: 40, md: 40 },
                      width: { xs: 40, md: 40 },
                    }}
                  />
                  <Stack >
                    <Text style={{ marginLeft: 10 }}>{element.name}</Text>
                    <Text style={{ marginLeft: 10, fontSize: 12 }}>{element.email}</Text>
                  </Stack>
                </Stack>
              </List>

              <Stack>
                <Stack mt={2}>
                  <Text style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 20 }}>{element.subject}</Text>
                  <Text mt={2} style={{ marginLeft: 10, fontSize: 12 }}>{element.message}</Text>

                  {
                    element.response_message != '' &&
                    <>
                      <List
                        style={{
                          marginTop: 50,
                          height: 60,
                          cursor: "pointer",
                        }}
                      >
                        <Stack direction={'row'} paddingX={2} justifyContent={'flex-start'}>
                          <Avatar
                            alt="Img"
                            src={currentUser?.profile_picture}
                            sx={{
                              height: { xs: 40, md: 40 },
                              width: { xs: 40, md: 40 },
                            }}
                          />
                          <Stack >
                            <Text style={{ marginLeft: 10 }}>{currentUser.name}</Text>
                            <Text style={{ marginLeft: 10, fontSize: 12 }}>{currentUser.email}</Text>
                          </Stack>
                        </Stack>
                      </List>
                      <Text style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 20 }}>{element.response_subject}</Text>
                      <Text mt={2} style={{ marginLeft: 10, fontSize: 12 }}>{element.response_message}</Text>
                    </>
                  }

                  {
                    reply &&
                    <Stack>
                      <Stack mt={{ xs: 2, md: 30 }} >
                        <Grid
                          container
                          style={{
                            borderTop: "0.5px solid lightgrey",
                            flexDirection: 'row'
                          }}
                          alignItems="center"
                          xs={12}
                        >
                          <Stack>
                            {
                              isError && subject == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                            }
                          </Stack>
                          <TextField
                            style={{ width: '95%' }}
                            value={subject}
                            onChange={(e) => { setsubject(e.target.value) }}
                            // onChange={(e) => { console.log(e.target.value) }}
                            placeholder="Subject"
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },
                                "&:hover fieldset": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Stack>

                      <Stack>
                        <Grid
                          container
                          style={{
                            borderBottom: "0.5px solid lightgrey",
                            borderTop: "0.5px solid lightgrey",
                            flexDirection: 'row'
                          }}
                          alignItems="center"
                          xs={12}

                        >
                          <Stack>
                            {
                              isError && msg == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                            }
                          </Stack>
                          <TextField
                            style={{ width: '95%' }}
                            value={msg}
                            onChange={(e) => { setmsg(e.target.value) }}
                            placeholder="Message"
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "none",
                                },
                                "&:hover fieldset": {
                                  border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Stack>
                      <Stack paddingTop={2} paddingBottom={4}>
                        <CustomButton
                          variant="contained"
                          sx={{
                            height: "60px",
                            width: { xs: "100%", sm: "70%", md: "50%" },
                            alignSelf: "center",
                          }}
                          onClick={() => { sendReply(element) }}
                        >
                          {
                            isLoader && <CircularProgress style={{ 'color': 'white' }} size="1rem" />
                          }
                          {
                            !isLoader && <Text color="white" fontWeight="bold" fontSize="15px">Send</Text>
                          }
                        </CustomButton>
                      </Stack>
                    </Stack>
                  }
                </Stack>
              </Stack>
            </Grid >
          </Grid >
      }
      arrMenu.push(obj)
    }
    setmenu(arrMenu)
  }, [feedBack, reply, subject, msg, isError])

  console.log(inboxFeedBack, currentUser, 'menu_console')

  const handleClick = (id, showId, indexArr, item) => {
    console.log()
    setShowContainer(true);
    let updated = []
    for (let index = 0; index < inboxFeedBack.length; index++) {
      const element = inboxFeedBack[index];
      if (indexArr === index) {
        element.selected = true
      }
      else {
        element.selected = false;
      }
      updated.push(element)
    }
    setinboxFeedBack(updated)
    setIndex(indexArr);
  };

  const handleBack = () => {
    setIndex(0);
    setShowContainer(true);
  };


  // const searchFunction = (search) => {
  //   let filteredItems = [];
  //   if (inboxFeedBack.length && tabs === 0) {
  //     if (search && search.length) {
  //       const searchPattern = new RegExp(search.map(term => `(?=.*${term})`).join(''), 'i');
  //       filteredItems = inboxFeedBack.filter(e => {
  //         return e.users[1]?.email.match(searchPattern)
  //       });
  //     } else {
  //       filteredItems = inboxFeedBack;
  //     }
  //     if (search[0] == '') {
  //       dispatch(getInboxItems())
  //     }
  //     else {
  //       setinboxFeedBack(filteredItems)
  //     }

  //   } else {
  //     if (search && search.length) {
  //       const searchPattern = new RegExp(search.map(term => `(?=.*${term})`).join(''), 'i');
  //       filteredItems = inboxItemsGroup.filter(e => {
  //         return e.event_title.match(searchPattern)
  //       });
  //     } else {
  //       filteredItems = inboxItemsGroup;
  //     }
  //     if (search[0] == '') {
  //       dispatch(getInboxItems())
  //     }
  //     else {
  //       setinboxChatGroup(filteredItems)
  //     }
  //   }
  // }

  return (
    <Stack>
      <MainContainer
        submit={submit}
        handleBack={handleBack}
        variant={2}
        showBoxTwo={true}
        showContainer={showContainer}
        Box1={
          <Stack width="90%" marginTop={4} alignSelf={"center"}>
            <Grid>
              <Stack
                maxHeight={`calc(78vh - 100px)`}
                overflow="scroll"
                sx={{
                  "::-webkit-scrollbar": { display: "none" },
                }}
              >
                <List sx={{ marginX: { xs: 0, lg: -1 } }}>

                  {
                    inboxFeedBack.map((item, index) => {
                      return (
                        <Stack key={index}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: {
                              sm: item.selected && colors.background_color,
                            },
                            boxShadow: { md: "none" },

                          }}
                        // style={{ background: 'red' }}
                        >
                          {
                            <ListItem
                              style={{ height: 80, }}
                              onClick={() => handleClick(item.id, item.showId, index, item)}
                            >
                              <ListItemIcon>
                                <Stack position={"relative"}>
                                  <Avatar
                                    alt="Img"
                                    src={null}
                                    sx={{
                                      height: { xs: 40, md: 60 },
                                      width: { xs: 40, md: 60 },
                                      marginRight: 2,
                                    }}
                                  />
                                </Stack>
                              </ListItemIcon>
                              <Stack
                                direction={"row"}
                                width="inherit"
                                position={"relative"}
                              >
                                <Stack marginTop={{ xs: -2.2, md: -2 }}>
                                  <ListItemText>
                                    <Text
                                      fontWeight="bold"
                                      fontSize={{
                                        xs: "12px",
                                        md: "18px",
                                        lg: "15px",
                                        xl: "18px",
                                      }}
                                    >
                                      {item.name}
                                    </Text>
                                  </ListItemText>
                                  <ListItemText
                                    style={{
                                      marginTop: -4,
                                      color: "grey",
                                      position: "relative",
                                      width: "inherit",
                                    }}
                                  >
                                    <Text
                                      width="100%"
                                      position="absolute"
                                      // overflow="hidden"
                                      textWrap="nowrap"
                                      whiteSpace="nowrap"
                                      fontSize={{ xs: "12px", md: "14px" }}
                                    >
                                      {item.message.substring(0, 30) + '...'}
                                    </Text>
                                  </ListItemText>
                                </Stack>
                                <Stack
                                  position={"absolute"}
                                  right={{ xs: index === 0 ? -13 : -27, md: 0 }}
                                  marginTop={-1.7}
                                >
                                  <ListItemText
                                    style={{
                                      color: "grey",
                                      alignSelf: "end",
                                    }}
                                  >
                                    <Text fontSize={{ xs: "10px", md: "14px" }}>
                                      {item.timestamp ? moment(item.timestamp).format('LT') : ''}
                                    </Text>
                                  </ListItemText>
                                </Stack>
                              </Stack>
                            </ListItem>
                          }
                        </Stack>
                      )
                    })
                  }
                </List>
              </Stack>
            </Grid>
          </Stack>
        }
        Box2={
          <Stack style={{}}>
            {menu && menu.length != 0 && menu[index]?.value}
            {/* < div ref={bottomRef} /> */}
          </Stack >
        }
      />
    </Stack >
  );
}
