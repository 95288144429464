import { InputLabel, Stack, useMediaQuery, useTheme, } from "@mui/material";
import { useState } from "react";
import CustomCarousel from "../../components/CarouselComponent";
import InputField from "../../components/InputField";
import CustomButton from "../../components/CustomButton";
import Text from "../../components/Text";
import Image from "mui-image";
import { Logo } from "../../assets";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword, showError } from '../../store/actions/action';
import toast from 'react-hot-toast';
import CircularProgress from '@mui/material/CircularProgress';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let isError = useSelector((state) => state.reducer.isError);
  let isLoader = useSelector((state) => state.reducer.isLoader);
  const { state } = useLocation();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  const size = {
    fontSize: { xs: "10.5px", sm: "15px" },
    color: "black",
  };

  const input = {
    height: small && "10px",
    fontSize: small && "11px",
  };

  const submit = () => {
    let credentials = {
      password: password,
      token: state.token,
    }
    password === confirmPassword && password.length >= 8 && dispatch(resetPassword(credentials, navigate))

    if (password.length < 8) {
      toast.error('Password should be minimum 8 character');
    }
    else if (password != confirmPassword) {
      toast.error("Password doesn't matched");
    }
    dispatch(showError())
  }

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      width="100vw"
      alignItems={"center"}
      justifyContent="center"
      height={"100vh"}
    >
      <Stack
        justifyContent="center"
        display={{ xs: "none", md: "flex" }}
        position="relative"
        width={{ xs: "100vw", md: "65vw" }}
      >
        <CustomCarousel />
        <Stack position="absolute" zIndex={10} alignSelf="center" top={"15%"}>
          <Image src={Logo} fit="contain" />
        </Stack>
        {/* <Text
          position="absolute"
          zIndex={10}
          top={"50%"}
          textAlign="center"
          fontSize="20px"
          width={400}
          sx={{ alignSelf: "center" }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque,
          officiis.
        </Text> */}
      </Stack>

      <Stack
        sx={{
          width: {
            xs: "80vw",
            md: "35vw",
          },
          paddingLeft: { xs: 0, md: 8 },
        }}
        marginRight={{ xs: 0, md: 14 }}
        marginTop={{ xs: 0, md: 8 }}
      >
        <Stack
          display={{ xs: "flex", md: "none" }}
          marginBottom={7}
          marginTop={-7}
        >
          <Image src={Logo} style={{ width: "50vw" }} />
        </Stack>

        <Stack width="100%" style={{ paddingBottom: 100 }}>
          <Text fontWeight={"bold"} variant={"h6"}>Reset Password</Text>
          <Text variant={"h6"}>Create the new password</Text>
          <Stack mt={{ xs: 4, md: 7 }} spacing={{ xs: 2, md: 1 }}>
            <Stack>
              <InputLabel style={{ display: 'flex', flexDirection: 'row' }}>
                <Text fontSize={{ xs: "12px", md: "16px" }}>New Password</Text>
                {
                  isError && password == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                }
              </InputLabel>
              <InputField
                required
                error={isError && password == ''}
                type={"password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="small"
                placeholder="Password"
                inputProps={{
                  style: {
                    ...input,
                  },
                }}
              />
            </Stack>
            <Stack>
              <InputLabel style={{ display: 'flex', flexDirection: 'row' }}>
                <Text fontSize={{ xs: "12px", md: "16px" }}>
                  Confirm Password
                </Text>
                {
                  isError && confirmPassword == '' && <Text style={{ top: 3, color: "red" }}>*</Text>
                }
              </InputLabel>
              <InputField
                placeholder="Confirm Password"
                required
                error={isError && confirmPassword == ''}
                type={"password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                size="small"
                inputProps={{
                  style: {
                    ...input,
                  },
                }}
              />
            </Stack>
          </Stack>
          <Stack>
            <CustomButton
              variant="contained"
              sx={{
                height: { xs: "40px", md: "60px" },
                marginTop: {
                  xs: 2,
                  sm: 3,
                },
                marginTop: 10
              }}
              // onClick={() => navigate("/event")}
              onClick={() => submit()}
            >
              {
                isLoader && <CircularProgress style={{ 'color': 'white' }} size="1rem" />
              }
              {
                !isLoader &&
                <Text color="white" fontWeight="bold" fontSize="20px">
                  Reset Password
                </Text>
              }
            </CustomButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
