import {
  Avatar,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  useMediaQuery,
  useTheme,
  Box
} from "@mui/material"
import LinearProgress from "@mui/joy/LinearProgress"
import Typography from "@mui/joy/Typography"
import React, { useEffect, useState } from "react"
import CustomButton from "../../components/CustomButton"
import MainContainer from "../../components/MainContainer"
import Text from "../../components/Text"
import CustomCheckbox from "../../components/CustomCheckbox"
import { colors } from "../../util/colors"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import Image from "mui-image"
import InputField from "../../components/InputField"
import { BarIcon } from "../../assets"
import MemberList from "../../components/MemberList"
import { makeStyles } from "@mui/styles"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import momentTimezone from "moment-timezone"
import {
  updateEvStatus,
  addMemberInEv,
  deleteEvent,
  uploadImage,
  surveyVotes,
  updateVote,
  showError
} from "../../store/actions/action"
import moment from "moment"
import { _weekGet } from "../../services/dateFunctions"
import { red } from "@mui/material/colors"
import ReactGA from "react-ga4"
import { loginGoogle } from "../authentication/NewSI"

const useStyles = makeStyles({
  icon: {
    fill: "white"
  },
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#EBEBEB",
        borderRadius: 5
      },
      "&:hover fieldset": {
        borderColor: "#EBEBEB",
        borderRadius: 5
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
        borderRadius: 5
      }
    }
  }
})

const NoImage = require("../../assets/NoImage1.png")

const config = {
  clientId:
    "632076568651-7osjao5dflhur6m9agck7jmtvpgsj0og.apps.googleusercontent.com",
  apiKey: "AIzaSyCYqcFaLiZcBhDQQ7TbATAyg_C16JJ5Qgg",
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

export default function Location() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let isError = useSelector(state => state.reducer.isError)
  let isLoader = useSelector(state => state.reducer.isLoader)
  const { state } = useLocation()
  console.log(state.event, "getEv")
  let wgapi = window.gapi
  let currentUser = useSelector(state => state.reducer.user)
  let invites = useSelector(state => state.reducer.invites)
  let data = useSelector(state => state.reducer.allUsers)
  let week = _weekGet(new Date(state.event.start))
  const [ddValue, setddValue] = useState("")
  const [poll, setpoll] = useState([])
  const [question, setquestion] = useState("")
  const [answear, setAnswear] = useState([])
  const [showSurveyModal, setshowSurveyModal] = useState(false)
  const [googleAuth, setGoogleAuth] = useState(null)
  const [update, setupdate] = useState(false)
  const [isFlag, setisFlag] = useState(false)
  const [googleEvents, setGoogleEvents] = useState([])
  let surveyPolls = useSelector(state => state.reducer.surveyPolls)
  let surveyChoices = useSelector(state => state.reducer.surveyChoices)

  useEffect(() => {
    initClient()
  }, [])

  async function initClient(callback) {
    wgapi.load("client:auth2", () => {
      try {
        wgapi.client
          .init({
            apiKey: config.apiKey,
            clientId: config.clientId,
            discoveryDocs: config.discoveryDocs,
            scope: config.scope
          })
          .then(
            function () {
              const res = wgapi.auth2.getAuthInstance().isSignedIn.get()
              console.log("res", res)
            },
            async function (error) {
              const res = wgapi?.auth?.getToken()
              if (res?.access_token) {
                setGoogleAuth(res?.access_token)
                getEventOnGoogleCalendar(res?.access_token)
                console.log("res", res?.access_token, wgapi)
                console.log("errorerror", error)
              } else {
                const res = await loginGoogle()
                console.log("sign-in", res?.access_token)
                getEventOnGoogleCalendar(res?.access_token)
                setGoogleAuth(res?.access_token)
              }
            }
          )
      } catch (error) {
        console.log("catch", error)
      }
    })
  }

  const deleteGoogleEvents = async () => {
    for (let i = 0; i < googleEvents.length; i++) {
      const element = googleEvents[i]
      if (element?.id) {
        await fetch(
          `https://www.googleapis.com/calendar/v3/calendars/primary/events/${element?.id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + googleAuth // Access token for google
            }
          }
        )
          .then(data => {
            console.log("Event deleted!")
          })
          .catch(err => {
            alert(JSON.stringify(err))
          })
      }
    }
  }

  console.log(googleEvents, "googleEvents")

  const getEventOnGoogleCalendar = async googleToken => {
    await fetch(
      "https://www.googleapis.com/calendar/v3/calendars/primary/events",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + googleToken // Access token for google
        }
      }
    )
      .then(data => {
        return data.json()
      })
      .then(data => {
        console.log("res0", JSON.stringify(data))
        if (data?.error?.status === "UNAUTHENTICATED") {
          initClient()
          return
        }
        if (data?.items?.length > 0) {
          const listEvent = []
          for (let i = 0; i < data?.items?.length; i++) {
            const element = data?.items[i]
            if (
              element?.summary === state?.event.title &&
              moment(element?.start?.dateTime).format("DD") ===
                moment(state?.event.start).format("DD") &&
              Array.isArray(state?.event.itinerary_items) &&
              state?.event.itinerary_items.length != 0
            ) {
              console.log(
                "element?.summary",
                moment(element?.start?.dateTime).format("DD"),
                moment(state?.event.start).format("DD"),
                moment(element?.start?.dateTime).format("LT"),
                momentTimezone(state?.event.itinerary_items[0].start)
                .utc()
                .format("LT"),
                moment(element?.end?.dateTime).format("LT"),
                momentTimezone(state?.event.itinerary_items[0].end)
                  .utc()
                  .format("LT"),
                state?.event.itinerary_items[0]
              )
              for (let j = 0; j < state?.event.itinerary_items.length; j++) {
                const element1 = state?.event.itinerary_items[j]
                if (
                  moment(element1.start).utc().format("LT") ===
                    moment(element?.start?.dateTime).format("LT") &&
                  moment(element1.end).utc().format("LT") ===
                    moment(element?.end?.dateTime).format("LT")
                ) {
                  listEvent.push(element)
                }
              }
            }
          }
          setGoogleEvents(listEvent)
        }
        console.log("Event listed!")
      })
      .catch(err => {
        alert(JSON.stringify(err))
      })
  }

  useEffect(() => {
    let ddValue = state.event.participants.filter(key => {
      return key.participant == currentUser.email
    })
    setddValue(ddValue[0]?.status)
    let filteredPolls = surveyPolls.filter(key => {
      return key.event == state.event.id
    })
    let filteredAns = surveyChoices.filter(key => {
      return key.poll == filteredPolls[0]?.id
    })
    setpoll(filteredPolls)
    setquestion(filteredPolls[0]?.text)
    setAnswear(filteredAns)
    setisFlag(!isFlag)
  }, [surveyChoices, surveyPolls])

  const space = { xs: 1, md: 2 }
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("md"))
  const size = {
    fontSize: { xs: "10.5px", sm: "15px" },
    color: "black"
  }
  const classes = useStyles()

  const drop = [
    {
      value: "Pending",
      label: "Pending"
    },
    {
      value: "Going",
      label: "Going"
    },
    {
      value: "Not Going",
      label: "Not Going"
    },
    {
      value: "Maybe",
      label: "Maybe"
    }
  ]

  const [imageURLS, setImageURLs] = useState([])

  const [allUsers, setallUsers] = useState([])

  useEffect(() => {
    ReactGA.event({
      category: "Event_View_Category",
      action: "Event_View_Action",
      label: "Event_View_Lable", // optional
      // value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr" // optional, beacon/xhr/image
    })
  }, [])

  useEffect(() => {
    makeOptions(data)
  }, [data])

  const makeOptions = data => {
    let K_OPTIONS = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index]
      if (currentUser.email != element.email) {
        element.item = element.name != null ? element.name : element.email
        element.id = element.email
        K_OPTIONS.push(element)
      }
    }
    setallUsers(K_OPTIONS)
  }

  const addMember = participants => {
    let selectedParticipants = []
    for (let index = 0; index < participants.length; index++) {
      const element = participants[index]
      element.isSelected &&
        selectedParticipants.push({ participant: element.email })
    }
    let data = {
      accommodation: state.event.accommodation,
      budget: state.event.budget,
      end: state.event.end,
      location: state.event.location,
      start: state.event.start,
      title: state.event.title,
      participants: selectedParticipants
    }
    dispatch(addMemberInEv(data, navigate, state.event.id))
  }

  useEffect(() => {
    if (state.event.pictures.length < 1) return
    const newImageUrls = []
    for (let index = 0; index < state.event.pictures.length; index++) {
      const element = state.event.pictures[index]
      newImageUrls.push(element.picture)
    }
    setImageURLs(newImageUrls)
  }, [state.event.pictures])

  function onImageChange(e) {
    addImages([...e.target.files])
  }

  const addImages = images => {
    let form_data = new FormData()
    form_data.append("title", state.event.title)
    form_data.append("location", state.event.location)
    form_data.append(
      "start",
      moment(state.event.start).format("YYYY-MM-DDThh:mm")
    )
    form_data.append(
      "end",
      moment(state.event.start).format("YYYY-MM-DDThh:mm")
    )
    form_data.append("accommodation", state.event.accommodation)
    form_data.append("budget", state.event.budget)
    if (images.length) {
      for (let index = 0; index < images.length; index++) {
        const element = images[index]
        form_data.append(`new_pictures`, element)
      }
    }
    dispatch(uploadImage(form_data, navigate, state.event.id))
  }

  const [showMemberModal, setShowMemberModal] = useState(false)

  const dropDownHandler = item => {
    let inviteFilter = invites.filter(key => {
      return key.event == state.event.id
    })
    let status = {
      status: item
    }
    dispatch(updateEvStatus(status, navigate, inviteFilter[0].id))
  }

  const handlerCheckBox = (key, selindex) => {
    let updtedArr = []
    for (let index = 0; index < answear.length; index++) {
      const element = answear[index]
      console.log(element, "element")
      if (index === selindex) {
        element.selected = true
      } else {
        element.selected = false
      }
      updtedArr.push(element)
    }
    setAnswear(updtedArr)
  }

  const handler = () => {
    let filteredAns = answear.filter(key => {
      return key.selected == true
    })
    if (filteredAns.length != 0) {
      // !update && dispatch(surveyVotes(filteredAns[0].id, navigate,))
      // update && dispatch(updateVote(filteredAns[0].id, filteredAns[0].poll, navigate,))
      dispatch(surveyVotes(filteredAns[0].id, navigate))
    } else {
      dispatch(showError())
    }
  }

  return (
    <MainContainer
      showBoxTwo={true}
      Box1={
        <Stack>
          <Stack
            id="back"
            position={"relative"}
            height={350}
            width="100%"
            sx={{
              backgroundImage: `url(${
                state.event.pictures[0]
                  ? state.event.pictures[0].picture
                  : NoImage
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              color: "white"
            }}
          >
            <Text
              fontSize="25px"
              fontWeight="bold"
              marginLeft={4}
              marginTop={4}
            >
              {state.event.title}
            </Text>
          </Stack>

          <Stack marginTop={3} paddingLeft={4}>
            <Text fontWeight="bold" fontSize="17px">
              Event Details
            </Text>
            <Text>{state.event.additional_info}</Text>
          </Stack>

          <Stack marginLeft={{ xs: 2, sm: 6 }} width={{ xs: "90%", sm: "65%" }}>
            <Stack>
              <FormControl
                sx={{
                  m: 1,
                  width: 130,
                  alignSelf: "start"
                }}
                size="small"
              >
                <Select
                  onChange={e => {
                    dropDownHandler(e.target.value)
                  }}
                  sx={{
                    borderRadius: 16,
                    background: colors.green,
                    color: "white",
                    paddingLeft: 0.2,
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: 0
                    }
                  }}
                  inputProps={{
                    classes: {
                      icon: classes.icon
                    }
                  }}
                  value={ddValue}
                >
                  {drop.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Text fontSize="18px" fontWeight="bold">
                Members
              </Text>
              <Stack
                direction={"row"}
                alignItems="center"
                spacing={1}
                sx={{
                  color: colors.green,
                  cursor: "pointer"
                }}
                onClick={() => setShowMemberModal(true)}
              >
                <AddCircleOutlineIcon fontSize="small" />
                <Text fontSize="14px" fontWeight="bold">
                  Add Member
                </Text>
              </Stack>
            </Stack>

            <Dialog
              open={showMemberModal}
              onClose={() => setShowMemberModal(false)}
              // maxWidth={{ xs: "20vw", sm: "30vw" }}
              fullWidth
              maxWidth="sm"
            >
              <DialogContent>
                <Stack spacing={2}>
                  {allUsers.map((key, index) => {
                    return (
                      <MemberList
                        check={key?.isSelected}
                        onChange={e => {
                          const tempArray = [...allUsers]
                          tempArray[index] = {
                            ...tempArray[index],
                            isSelected: e.target.checked
                          }
                          setallUsers(tempArray)
                        }}
                        index={index}
                        name={key.item}
                        image={key.profile_picture}
                      />
                    )
                  })}
                </Stack>
              </DialogContent>

              <DialogActions>
                <CustomButton
                  sx={{ color: "white" }}
                  onClick={() => {
                    setShowMemberModal(false)
                  }}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  sx={{ color: "white" }}
                  onClick={() => {
                    setShowMemberModal(false)
                    addMember(allUsers)
                  }}
                >
                  Save
                </CustomButton>
              </DialogActions>
            </Dialog>

            <Stack marginTop={2}>
              <List sx={{ marginLeft: -2, marginRight: -2.1 }}>
                {state.event.participants.length != 0 &&
                  state.event.participants.map((key, index) => {
                    return (
                      <ListItem
                      // onChange={() => { navigation.navigate('ProfileFromEvent', { participants: key, channel: event.channel }) }}
                      >
                        <ListItemIcon>
                          <Avatar
                            alt="Remy Sharp"
                            src={
                              key.participant_info?.profile_picture != null
                                ? key.participant_info?.profile_picture
                                : null
                            }
                            sx={{
                              height: { xs: 30, md: 40 },
                              width: { xs: 30, md: 40 },
                              marginRight: 2
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText sx={{ flexGrow: 1 }}>
                          <Text fontWeight="bold">
                            {key.participant_info.name != null
                              ? key.participant_info.name
                              : key.participant}
                          </Text>
                        </ListItemText>
                        {key.participant == currentUser.email && (
                          <ListItemText>
                            <Stack alignItems={"end"}>
                              <Text
                                textAlign="center"
                                backgroundColor={colors.green}
                                borderRadius={1}
                                fontSize="13px"
                                color={colors.white}
                                paddingX={1}
                              >
                                Creator
                              </Text>
                            </Stack>
                          </ListItemText>
                        )}
                      </ListItem>
                    )
                  })}
              </List>

              <Stack
                sx={{ color: "red", cursor: "pointer" }}
                direction="row"
                marginY={3}
                alignItems="center"
                spacing={2}
                onClick={() => {
                  dispatch(
                    deleteEvent(navigate, state.event.id, deleteGoogleEvents)
                  )
                }}
              >
                <BackspaceOutlinedIcon fontSize="small" />
                <Text fontSize="14px" fontWeight="800">
                  Leave Group
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      }
      Box2={
        <Stack marginX={{ xs: 0, sm: 5 }} paddingBottom={5}>
          <Stack marginX={2} marginTop={2} spacing={2}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent="space-between"
            >
              <InputLabel
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "15px", md: "20px" },
                  color: "black",
                  marginX: 2
                }}
              >
                Photos
              </InputLabel>
              <Button
                sx={{
                  color: colors.green,
                  textTransform: "none"
                }}
                component="label"
              >
                <AddCircleOutlineOutlinedIcon
                  fontSize="small"
                  sx={{ marginRight: 1 }}
                />
                <Text fontWeight="bold" fontSize="18px">
                  Photo
                </Text>
                <input
                  hidden
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={onImageChange}
                />
              </Button>
            </Stack>

            {imageURLS.length > 0 ? (
              <Stack direction={"row"} flexWrap="wrap">
                {imageURLS.map((url, index) => (
                  <Stack
                    width={{ xs: 70, sm: 80, md: 160 }}
                    height={{ xs: 70, sm: 80, md: 160 }}
                    key={index}
                    position="relative"
                    marginRight={{ xs: 2, sm: 1.8 }}
                    marginBottom={2}
                    display={index > 3 ? "none" : "flex"}
                  >
                    <Image
                      width={"100%"}
                      height={"100%"}
                      style={{ borderRadius: 8 }}
                      src={url}
                    />
                    {/* <Stack
                        sx={{
                          background: "white",
                          borderRadius: 16,
                          cursor: "pointer",
                        }}
                        display={index === 3 ? "none" : "flex"}
                        position={"absolute"}
                        right={0}
                        onClick={() => deleteUrl(url)}
                        justifyContent="center"
                        alignItems="center"
                        width={20}
                        height={20}
                      >
                        ✖
                      </Stack> */}
                  </Stack>
                ))}
              </Stack>
            ) : null}
          </Stack>
          <Stack marginTop={4} marginLeft={2}>
            <Text marginBottom={2} fontSize="20px" fontWeight="bold">
              Itinerary
            </Text>
            <Card
              elevation={5}
              sx={{
                height: { xs: 50, sm: 60 },
                width: { xs: "90%", sm: "75%" },
                marginLeft: 1,
                display: "flex",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <Stack
                direction={"row"}
                alignItems={{ sm: "center" }}
                justifyContent={"space-evenly"}
                width="100%"
                height={{ xs: "90%", sm: "92%" }}
                marginTop={{ xs: 0.3, sm: 0 }}
              >
                {week.length != 0 &&
                  week.map((key, index) => {
                    return (
                      <Stack
                        height={"100%"}
                        width={{ xs: "45px", sm: "55px" }}
                        alignItems="center"
                        sx={
                          moment(key).format("DD") ===
                          moment(state.event.start).format("DD")
                            ? { background: "#24A59E" }
                            : { background: "#FFFFFF" }
                        }
                      >
                        <Text
                          fontSize={{ xs: "16px", sm: "20px" }}
                          fontWeight="bold"
                          style={{
                            color:
                              moment(key).format("DD") ===
                              moment(state.event.start).format("DD")
                                ? "#FFFFFF"
                                : "#000000"
                          }}
                        >
                          {moment(key).format("dd")}
                        </Text>
                        <Text
                          fontSize={{ xs: "14px", sm: "18px" }}
                          style={{
                            color:
                              moment(key).format("DD") ===
                              moment(state.event.start).format("DD")
                                ? "#FFFFFF"
                                : "#000000"
                          }}
                        >
                          {moment(key).format("DD")}
                        </Text>
                      </Stack>
                    )
                  })}
              </Stack>
            </Card>

            {state.event.itinerary_items.length != 0 &&
              state.event.itinerary_items.map((key, index) => {
                return (
                  <Stack direction={"row"} marginTop={2} height={90}>
                    <Image
                      src={BarIcon}
                      fit="contain"
                      width={"30px"}
                      height={"100%"}
                    />
                    <Stack height={90}>
                      <Stack
                        key={index}
                        alignSelf={"start"}
                        position="relative"
                        height={90}
                      >
                        <ListItem sx={{ height: "100%" }}>
                          <Stack>
                            <ListItemText>
                              <Text fontSize="20px" fontWeight="bold">
                                {moment(key.start).utc().format("LT")}
                                {" TO "}
                                {moment(key.end).utc().format("LT")}
                              </Text>
                            </ListItemText>
                            <ListItemText>
                              <Text>{key.location}</Text>
                            </ListItemText>
                          </Stack>
                        </ListItem>
                      </Stack>
                    </Stack>
                  </Stack>
                )
              })}

            <Stack width={{ xs: "95%", md: "70%" }}>
              <Stack spacing={3} marginTop={2}>
                <Text fontSize="20px" fontWeight="bold">
                  Location
                </Text>
                <div
                  style={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    border: `2px solid #EBEBEB`,
                    borderRadius: 5
                  }}
                >
                  <Text fontSize="20px" color={"gray"} marginLeft={2}>
                    {state.event.location}
                  </Text>
                </div>
              </Stack>
              <Stack spacing={3} marginTop={2}>
                <Text fontSize="20px" fontWeight="bold">
                  Budget
                </Text>
                <div
                  style={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    border: `2px solid #EBEBEB`,
                    borderRadius: 5
                  }}
                >
                  <Text fontSize="20px" color={"gray"} marginLeft={2}>
                    ${Math.round(state.event.budget)}
                  </Text>
                </div>
              </Stack>
              <Stack spacing={3} marginTop={2}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Text fontSize="20px" fontWeight="bold">
                    Accomodation
                  </Text>
                  <Button
                    sx={{
                      color: colors.green,
                      textTransform: "none"
                    }}
                    component="label"
                  >
                    <Stack
                      direction={"row"}
                      onClick={() => {
                        ReactGA.event({
                          category: "Airbnb_Category",
                          action: "Airbnb_Action",
                          label: "Airbnb_Lable", // optional
                          // value: 99, // optional, must be a number
                          nonInteraction: true, // optional, true/false
                          transport: "xhr" // optional, beacon/xhr/image
                        })
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon
                        sx={{ fontSize: "15px", marginRight: 1 }}
                      />
                      <a href="https://www.airbnb.com/" target="_blank">
                        <Text
                          fontWeight="bold"
                          fontSize="12px"
                          style={{ color: colors.green }}
                        >
                          Airbnb
                        </Text>
                      </a>
                    </Stack>
                  </Button>
                </Stack>
                <div
                  style={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    border: `2px solid #EBEBEB`,
                    borderRadius: 5
                  }}
                >
                  <Text fontSize="20px" color={"gray"} marginLeft={2}>
                    {state.event.accommodation}
                  </Text>
                </div>
              </Stack>

              <Stack marginTop={2}>
                <Dialog
                  open={showSurveyModal}
                  onClose={() => setshowSurveyModal(false)}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogContent>
                    <Stack spacing={2}>
                      <Stack spacing={1}>
                        <Text fontSize="20px" fontWeight="bold">
                          Question
                        </Text>
                        <div
                          style={{
                            height: 50,
                            display: "flex",
                            alignItems: "center",
                            border: `2px solid #EBEBEB`,
                            borderRadius: 5
                          }}
                        >
                          <Text fontSize="20px" color={"gray"} marginLeft={2}>
                            {question}
                          </Text>
                        </div>
                      </Stack>

                      <Stack marginTop={1}>
                        <Text fontSize="20px" fontWeight="bold">
                          Answer
                        </Text>
                        {answear.map((key, index) => {
                          return (
                            <Stack direction={"row"} alignItems={"center"}>
                              <CustomCheckbox
                                size={!small ? "medium" : "small"}
                                checked={key.selected ? key.selected : false}
                                onChange={() => {
                                  handlerCheckBox(key, index)
                                }}
                              />
                              <InputLabel sx={size}>
                                {key.choice_text}
                              </InputLabel>
                            </Stack>
                          )
                        })}
                      </Stack>
                    </Stack>
                  </DialogContent>

                  <DialogActions>
                    <CustomButton
                      sx={{ color: "white" }}
                      onClick={() => {
                        setshowSurveyModal(false)
                        setupdate(false)
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      sx={{ color: "white" }}
                      onClick={() => {
                        handler()
                        setupdate(false)
                      }}
                    >
                      Save
                    </CustomButton>
                  </DialogActions>
                </Dialog>
              </Stack>

              <Stack marginTop={2}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Text fontSize="20px" fontWeight="bold">
                    Survey
                  </Text>
                  <Button
                    sx={{
                      color: colors.green,
                      textTransform: "none"
                    }}
                    component="label"
                  >
                    <Stack
                      direction={"row"}
                      onClick={() => {
                        setshowSurveyModal(true)
                        setupdate(false)
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon
                        sx={{ fontSize: "15px", marginRight: 1 }}
                      />
                      <Text fontWeight="bold" fontSize="12px">
                        Take Suvey
                      </Text>
                    </Stack>
                  </Button>
                </Stack>
                <div
                  style={{
                    "white-space": "pre-wrap",
                    "overflow-wrap": "break-word"
                    // height: 50,
                    // display: "flex",
                    // alignItems: "center",
                  }}
                  // style={{ 'white-space': 'pre-wrap', 'overflow-wrap': 'break-word' }}
                >
                  <Text
                    fontSize="20px"
                    color={"#24A59E"}
                    fontWeight="bold"
                    marginLeft={2}
                  >
                    {poll[0]?.text}
                  </Text>
                </div>
              </Stack>

              {answear.length != 0 &&
                answear.map((key, index) => {
                  return (
                    <Stack key={index} marginTop={1} style={{}}>
                      <Box
                        sx={{
                          width: "100%",
                          color: "#E5E2E2",
                          border: `2px solid #EBEBEB`,
                          borderRadius: 2
                        }}
                      >
                        <LinearProgress
                          determinate
                          variant="outlined"
                          color="inherit"
                          size="sm"
                          thickness={32}
                          value={key.percentage_of_votes}
                          sx={{
                            "--LinearProgress-radius": "5px",
                            "--LinearProgress-progressThickness": "100%",
                            borderColor: "danger.plainColor"
                          }}
                        >
                          <div
                            style={{
                              width: "80%",
                              zIndex: 1,
                              justifyContent: "center"
                            }}
                          >
                            <Text
                              fontSize="20px"
                              color={"#000000"}
                              fontWeight="bold"
                              marginLeft={2}
                              style={{
                                "white-space": "pre-wrap",
                                "overflow-wrap": "break-word"
                              }}
                            >
                              {key.choice_text}
                            </Text>
                          </div>
                          <div
                            style={{
                              width: "20%",
                              zIndex: 1,
                              justifyContent: "center"
                            }}
                          >
                            <Text
                              fontSize="20px"
                              color={"#000000"}
                              fontWeight="bold"
                            >
                              {key.percentage_of_votes + "%"}
                            </Text>
                          </div>
                        </LinearProgress>
                      </Box>
                    </Stack>
                  )
                })}

              {/* <Stack marginTop={2}>
                <div
                  style={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    cursor: 'pointer'
                  }}
                  onClick={() => { setshowSurveyModal(true); setupdate(true) }}
                >
                  <Text fontSize="20px" color={"#24A59E"} fontWeight='bold' marginLeft={2}>
                    Change my answer
                  </Text>
                </div>
              </Stack> */}
            </Stack>
          </Stack>
        </Stack>
      }
    />
  )
}
