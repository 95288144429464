import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { termsAndCondition, privacyPolicy } from '../../store/actions/action';
import { Stack } from "@mui/material";
import Text from "../../components/Text";
import Image from "mui-image";
import CustomCarousel from "../../components/CarouselComponent";
import TermsAndPrivecy from "../../components/TermsAndPrivecy";
import { Logo } from "../../assets";

export const TermsAndCondition = () => {
    const dispatch = useDispatch()
    const { state } = useLocation();
    let terms = useSelector((state) => state.reducer.terms);
    let privacy = useSelector((state) => state.reducer.privacy);

    useEffect(() => {
        dispatch(termsAndCondition())
        dispatch(privacyPolicy())
    }, [])

    return (
        <Stack
            direction={{ xs: "column", md: "row" }}
            width="100vw"
            justifyContent="center"
            alignItems={{ xs: "center", md: "flex-start" }}
            height={"100vh"}
        >
            <Stack
                justifyContent="center"
                display={{ xs: "none", md: "flex" }}
                position="relative"
                width={{ xs: "100vw", md: "65vw" }}
            >
                <CustomCarousel />
                <Stack position="absolute" zIndex={10} alignSelf="center" top={"15%"}>
                    <Image src={Logo} fit="contain" />
                </Stack>
                {/* <Text
                    position="absolute"
                    zIndex={10}
                    top={"50%"}
                    textAlign="center"
                    fontSize="20px"
                    width={400}
                    sx={{ alignSelf: "center" }}
                >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque,
                    officiis.
                </Text> */}
            </Stack>
            <TermsAndPrivecy text={state} data={state === 'Terms and Conditions' ? terms : privacy} isdieApp={false} />
        </Stack>
    );
};
