import Image from "mui-image";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { Wall1, Wall2, Wall3, Wall4 } from "../assets/carosalImages/index";

const CustomCarousel = () => {
  var images = [Wall1, Wall2, Wall3, Wall4];

  return (
    <Carousel
      height={"100vh"}
      indicators={false}
      navButtonsAlwaysVisible={true}
    >
      {images.map((image, i) => (
        <Image
          key={i}
          style={{
            borderTopRightRadius: "100px",
            borderBottomRightRadius: "100px",
          }}
          width="100%"
          alt="Carousel Image"
          height="100%"
          src={image}
          fit="cover"
        />
      ))}
    </Carousel>
  );
};
export default CustomCarousel;
