import { Avatar, AvatarGroup, Box, Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import CustomText from "./Text";
import { colors } from "../util/colors";
import { styled } from "@mui/system";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useNavigate } from "react-router-dom";
const NoImage = require('./../assets/NoImage.png')

const Text = styled(CustomText)({
  color: colors.white,
});

export default function EventCard({
  img,
  date,
  title,
  location,
  avatar,
  user,
  tag,
  users,
  event
}) {
  const navigate = useNavigate();
  return (
    <Stack margin={2} overflow={"hidden"} borderRadius={"3px"} style={{ cursor: 'pointer' }}
    >
      <Stack
        onClick={() => navigate("/location", { state: { event: event } })}
        justifyContent={"space-between"}
        sx={{ height: "276px", width: "220px", backgroundImage: `url(${img})` }}
      >
        <Stack
          alignItems={"center"}
          paddingY={0.7}
          paddingX={"13px"}
          alignSelf={"flex-end"}
          sx={{ backgroundColor: colors.green }}
        >
          <Text fontWeight={"300"} fontSize={"12px"}>
            {moment(date).format("DD")}
          </Text>
          <Text fontWeight={"500"}>{moment(date).format("MMM")}</Text>
        </Stack>

        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          paddingY={1.1}
          paddingLeft={1.4}
          paddingRight={1}
          sx={{ backgroundColor: colors.lightBlack }}
        >
          <Box>
            <Text color={colors.white} fontSize={"13px"} fontWeight={"500"}>
              {title}
            </Text>
            <Stack spacing={"3px"} direction={"row"} alignItems="center">
              <FmdGoodIcon sx={{ color: colors.green }} fontSize="9px" />
              <Text fontSize={"9px"} color={colors.white}>
                {location}
              </Text>
            </Stack>
          </Box>
          <Stack alignItems={"center"} flexDirection="row">
            <Avatar
              sx={{ width: 26, height: 26, marginRight: 1 }}
              src={avatar}
            />
            <Stack>
              <Text fontSize={"9px"} color={colors.white} fontWeight={"500"}>
                {user}
              </Text>
              <Text
                marginTop={"3px"}
                alignSelf="flex-start"
                paddingX={"5px"}
                textAlign="center"
                backgroundColor={colors.green}
                borderRadius={1}
                fontSize="9px"
                color={colors.white}
                fontWeight={"500"}
              >
                {tag}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <AvatarGroup
        sx={{
          "& .MuiAvatar-root": { width: 26, height: 26, fontSize: 15 },
          alignSelf: "flex-start",
          marginTop: "5px",
        }}
        total={0}
      >
        {/* {users.map((item, index) => (
          <Avatar key={index} alt="Remy Sharp" src={item.uri} />
        ))} */}

        {
          users.map((key, index) => {
            // console.log(key, 'users')
            return (
              index < 5 &&
              <Avatar key={index} alt="Remy Sharp" src={key.participant_info?.profile_picture} onClick={() => { navigate("/profileSingleChat", { state: { participants: key.participant_info, channel: event.channel } }) }} />
            )
          })
        }


      </AvatarGroup>
    </Stack>
  );
}
