import {
  Grid, IconButton, TextField, Button,

} from "@mui/material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { colors } from "../util/colors";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Text from "../components/Text";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

export const ChatBox = ({ submit }) => {
  const [msg, setmsg] = useState('');

  const uploadPhoto = async (e) => {
    submit(msg, e.target.files[0])
  }

  return (
    <Grid
      container
      style={{
        paddingTop: "5px",
        borderTop: "2px solid lightgrey",
        height: 30
      }}
      alignItems="center"
      xs={12}
    >
      <Grid item xs={1} />
      <Grid item xs={7}>
        <TextField
          value={msg}
          onChange={(e) => { setmsg(e.target.value) }}
          placeholder="Write your message here"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />
      </Grid>

      <Grid item xs={2} align="right" style={{}}>
        <Button
          sx={{
            color: 'gray',
            textTransform: "none",
          }}
          component="label"
        >
          <CameraAltIcon fontSize="large" />

          <input
            hidden
            type="file"
            multiple
            accept="image/*"
            onChange={uploadPhoto}
          />
        </Button>
      </Grid>

      <Grid xs={1} align="right" style={{}}>
        <SendIcon fontSize="large" sx={{ color: colors.green }} onClick={() => { submit(msg); setmsg('') }} />
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};
