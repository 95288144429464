import { GoogleLoginButton } from "react-social-login-buttons";
import * as GoogleInit from "../../services/googleOauth";
import { Component } from "react";

export class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: "",
            password: "",
        };

        this.loginGoogle = this.loginGoogle.bind(this);

        this.GoogleSignInResponse = this.GoogleSignInResponse.bind(this);
    }

    render() {
        return (
            <div>
                {" "}
                <GoogleLoginButton
                    align={"center"}
                    onClick={(e) => this.loginGoogle(e)}
                >
                    <span>Sign in with Google</span>
                </GoogleLoginButton>
            </div>
        );
    }

    async loginGoogle(e) {
        e.preventDefault();
        // console.log("loginGoogle");
        try {
            //our helper
            let data = await GoogleInit.loginUser2();

            // console.log("signInGoogle.signInGoogle",data);
            this.GoogleSignInResponse(data);
        } catch (error) {
            console.error(error);
        }
    }

    GoogleSignInResponse(value) {
        console.log("GoogleSignInResponse", value);
        //send to backend (redux used here)
        // this.props.dispatch(
        //     UserActions.googlelogin(value, (response) => {
        //         console.log("Response from DB", response);
        //         if (response.data.dob == "1000-12-01") {
        //             this.props.history.push("/birthday-wall");
        //             return;
        //         }
        //         if (response.status) {
        //             this.props.history.push("/");
        //         } else {
        //             let error = response.data.message
        //                 ? response.data.message
        //                 : "Something went wrong, try again later!";
        //             alert(error);
        //         }
        //     })
        // );
    }



}



export async function loginGoogle() {
    // e.preventDefault();
    // console.log("loginGoogle");
    return new Promise(async (resolve, reject)=>{
        try {
            //our helper
            let data = await GoogleInit.loginUser();
            // console.log("signInGoogle.signInGoogle",data);
            // console.log("GoogleSignInResponse", data);
            resolve(data);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    })



 
}
